import mixpanel from 'mixpanel-browser';
import React, { Component } from 'react';
import ClevertapReact from 'clevertap-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import Clarity from '@microsoft/clarity';

import { appInit, closeSnackbar, fetchCachedAPIList } from './actions';

import { asyncComponent } from './components';
import SnackbarComponent from './components/common/Snackbar';
import { CLEVERTAP_ID, MIXPANEL_ID, USER_TOKEN } from './constants';
import { history } from './store';

const AsyncLogin = asyncComponent(() => import('./pages/Login'));
const AsyncHomePage = asyncComponent(() => import('./pages/Dashboard'));
const AsyncRemoveUserViaPasscode = asyncComponent(() => import('./pages/RemoveUserViaPasscode'));
const AsyncRetrieveUserViaPasscode = asyncComponent(() =>
  import('./pages/RetrieveUserViaPasscode')
);
const AsyncDetailsPage = asyncComponent(() => import('./pages/CompanyDetails'));
const AsyncSettingsPage = asyncComponent(() => import('./pages/CompanySettings'));
const AsyncSharedItem = asyncComponent(() => import('./pages/SharedItem/Container/CatelogSharing'));
const AsyncEshop = asyncComponent(() => import('./pages/SharedItem/Container/Eshop'));
const AsyncViewVoucher = asyncComponent(() => import('./containers/ViewVoucher'));
const AsyncCompaniesPage = asyncComponent(() => import('./pages/CompaniesDashboard'));
const AsyncAppDownloadPage = asyncComponent(() => import('./pages/AppDownloadPage'));

function PrivateRoute({ component: Component, ...rest }) {
  const userToken = localStorage.getItem(USER_TOKEN);

  return (
    <Route
      {...rest}
      render={props =>
        userToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
class App extends Component {
  constructor() {
    super();
    mixpanel.init(MIXPANEL_ID);
    ClevertapReact.initialize(CLEVERTAP_ID);
  }

  componentDidMount() {
    this.props.appInit();
    // this.props.fetchCachedAPIList();
  }

  componentDidUpdate() {
    const { user, currentCompany } = this.props;

    if (user) {
      Clarity.identify(user?.id, '', '', user?.id);
      Clarity.setTag('user_name', user?.name);
      Clarity.setTag('user_email', user?.iUser?.email);
      Clarity.setTag('user_id', user?.id);
    }
    if (currentCompany) {
      Clarity.setTag('company_name', currentCompany?.name);
      Clarity.setTag('company_id', currentCompany?.id);
    }
  }

  render() {
    return (
      <div>
        <SnackbarComponent {...this.props} />
        {/* <Router history={history}> */}
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={AsyncLogin} />
            <Route exact path="/login" component={AsyncLogin} />
            <Route exact path="/view/shared-voucher/:shareId" component={AsyncViewVoucher} />
            <Route exact path="/view/shared-conversation/:shareId" component={AsyncSharedItem} />
            <Route exact path="/view/ishop/:eshopId" component={AsyncEshop} />
            <Route exact path="/view/download" component={AsyncAppDownloadPage} />

            <PrivateRoute path="/companies" component={AsyncCompaniesPage} />
            <Route path="/:id/remove" component={AsyncRemoveUserViaPasscode} />
            <Route path="/:id/restore" component={AsyncRetrieveUserViaPasscode} />

            <Route
              exact
              path={['/:id/details', '/:id/details/:sectionId']}
              component={AsyncDetailsPage}
            />
            <Route exact path="/:id/settings" component={AsyncSettingsPage} />

            {/* Item page related */}
            {/* <Route exact path="/:id/home/:page" component={AsyncHomePage} /> */}
            {/* <Route exact path="/:id/home/:page/item-groups/:fk" component={AsyncHomePage} />
            <Route exact path="/:id/home/:page/:fk" component={AsyncHomePage} /> */}

            {/* Report related */}
            <Route exact path="/:id/home/:page/:type" component={AsyncHomePage} />
            {/* <Route
              exact
              path="/:id/home/:page/:type/account/:accountId"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/account/:accountId/date/:monthStart/:monthEnd"
              component={AsyncHomePage}
            />
            <Route exact path="/:id/home/:page/:type/path" component={AsyncHomePage} />
            <Route
              exact
              path="/:id/home/:page/:type/path/account/:accountId"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/path/account/:accountId/date/:monthStart/:monthEnd"
              component={AsyncHomePage}
            />
            <Route exact path="/:id/home/:page/:type/path/path2" component={AsyncHomePage} />
            <Route
              exact
              path="/:id/home/:page/:type/path/path2/account/:accountId"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/path/path2/account/:accountId/date/:monthStart/:monthEnd"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/item-group/:itemGroupId"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/item-group/:itemGroupId/item/:itemId"
              component={AsyncHomePage}
            />
            <Route
              exact
              path="/:id/home/:page/:type/item-group/:itemGroupId/item/:itemId/monthly"
              component={AsyncHomePage}
            /> */}
            {/* Report end */}

            <Route path="/:id/home/:page" component={AsyncHomePage} />
          </Switch>
        </ConnectedRouter>
        {/* </Router> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { snackbar, user = {}, currentCompany } = state;
  return {
    snackbar,
    user,
    currentCompany
  };
};

export default connect(mapStateToProps, { appInit, closeSnackbar, fetchCachedAPIList })(App);
