import axios from 'axios';
import { userAuthFetch, companyAuthFetch, extractData, fetch } from './server';

//marketing
export const fetchConversationChannels = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/conversation-channels`).then(extractData);

export const fetchConversation = (companyId, chatId, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/conversation-channels/${chatId}?page=${page}`)
    .then(extractData);

export const fetchSharedConversation = (chatId, page = 1, query) =>
  companyAuthFetch
    .get(`/share-statuses/conversations/:chatId/grouped/page/:page:query`, {
      params: { chatId, page, query }
    })
    .then(extractData);

export const fetchItemGroupsFilterList = (chatId, page = 1, query) =>
  companyAuthFetch
    .get(`/share-statuses/conversations/${chatId}/item-groups/page/${page}?${query}`)
    .then(extractData);

export const fetchItemAttributeList = (chatId, page = 1, query) =>
  companyAuthFetch
    .get(`/share-statuses/conversations/:chatId/item-attribute-list/page/:page?:query`, {
      params: { chatId, page, query }
    })
    .then(extractData);

export const fetchItemAttributeValue = (chatId, itemAttributeId, page = 1, query, isCancelAPI) =>
  companyAuthFetch
    .get(
      `/share-statuses/conversations/:chatId/item-attribute-value/:itemAttributeId/page/:page?:query`,
      {
        params: { chatId, page, query, itemAttributeId, isCancelAPI }
      }
    )
    .then(extractData);

export const fetchCatelogTagList = (chatId, page = 1, query) =>
  companyAuthFetch
    .get(`/share-statuses/conversations/${chatId}/item-tags/page/${page}?${query}`)
    .then(extractData);

export const fetchEshopItem = (id, page = 1, query) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/${id}/grouped-items/page/${page}?${query}`)
    .then(extractData);

export const updateSharedCatalogueViewCount = id =>
  companyAuthFetch.put(`/share-statuses/${id}/view-count`).then(extractData);

export const updateEShopViewCount = eShopPathName =>
  companyAuthFetch.put(`/share-statuses/e-shop/${eShopPathName}/view-count`).then(extractData);

export const fetchEshopCompany = eShopId =>
  companyAuthFetch.get(`/share-statuses/e-shop/${eShopId}/i-company/details`).then(extractData);

export const fetchEshopTagList = (eShopId, page, query) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/:eShopId/item-tags/page/:page?:query`, {
      params: {
        eShopId: eShopId,
        page: page,
        query: query
      }
    })
    .then(extractData);

export const fetchEshopItemGroup = (eShopId, page, query) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/:eShopId/item-groups/page/:page?:query`, {
      params: {
        eShopId: eShopId,
        page: page,
        query: query
      }
    })
    .then(extractData);

export const fetchEshopItemAttributeList = (eShopId, page, query) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/:eShopId/item-attribute-list/page/:page?:query`, {
      params: {
        eShopId: eShopId,
        page: page,
        query: query
      }
    })
    .then(extractData);

export const fetchEshopItemAttribute = (eShopId, page, itemAttributeId, query) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/:eShopId/item-attribute/:itemAttributeId/page/:page?:query`, {
      params: {
        eShopId,
        page,
        query,
        itemAttributeId
      }
    })
    .then(extractData);

export const createEshopOrder = (eShopId, query, payload) =>
  companyAuthFetch
    .post(`/share-statuses/conversations/${eShopId}/e-shop${query}`, payload)
    .then(extractData);

export const fetchConversationCompany = chatId =>
  companyAuthFetch
    .get(`/share-statuses/conversations/${chatId}/i-company-basic-and-shared-contact`)
    .then(extractData);

export const fetchConverstationItemStock = (chatId, itemId) =>
  companyAuthFetch
    .get(`/share-statuses/conversations/${chatId}/item/quantity?itemId=${itemId}`)
    .then(extractData);

export const fetchIShopItemStock = (ishopName, itemId) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/${ishopName}/item/quantity?itemId=${itemId}`)
    .then(extractData);

export const fetchPublicItemShareLink = (companyId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/conversation-channels/public`, payload)
    .then(extractData);

export const createDuplicateCatalogue = (companyId, branchId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/catalogue-clone/${branchId}`, payload)
    .then(extractData);

export const createCatelogOrder = (chatId, payload, query) =>
  companyAuthFetch
    .post(`/share-statuses/conversations/${chatId}/order${query}`, payload)
    .then(extractData);

//

export const fetchCompanies = userId =>
  userAuthFetch.get(`i-users/${userId}/companies`).then(extractData);

export const companyLogin = (userId, companyId, payload) =>
  userAuthFetch.post(`/i-users/${userId}/companies/${companyId}/login`, payload).then(extractData);

export const fetchTemplates = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/pdf-templates`).then(extractData);

export const createCompanyPdfTemplates = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/pdf-templates`, payload).then(extractData);

export const editCompanyPdfTemplates = (companyId, payload, id) =>
  companyAuthFetch.put(`/i-companies/${companyId}/pdf-templates/${id}`, payload).then(extractData);

export const fetchSkubarCodeTemplates = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/sku-templates`).then(extractData);

export const fetchPartyTemplates = companyId =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/statement/templates?type=reconcilation`)
    .then(extractData);

export const fetchSkuV3Templates = companyId =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/statement/templates?type=skuBarCode&subType=version3`)
    .then(extractData);

export const fetchCompanyItems = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/items/include-group`).then(extractData);

export const fetchCompanyBranch = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/i-branches`).then(extractData);

export const fetchCompanyMembers = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/team/names`).then(extractData);

export const deleteCompanyTeamMember = (companyId, userId, query, data) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/i-users/${userId}${query}`, data)
    .then(extractData);

export const addItem = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/items`, payload).then(extractData);

export const getGSTMaster = () =>
  userAuthFetch.get(`/configurations/gst-business-types`).then(extractData);

export const getCachedAPIList = () =>
  userAuthFetch.get(`/configurations/api-updates-config`).then(extractData);

export const createCompany = (userId, payload) =>
  userAuthFetch.post(`/i-users/${userId}/companies/register`, payload).then(extractData);

export const requestCompanyAccess = (userId, companyId) =>
  userAuthFetch.post(`/i-users/${userId}/companies/${companyId}/join-request`).then(extractData);

export const createItemGroup = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/item-groups`, payload).then(extractData);

export const deleteItemGroup = (companyId, groupId) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/item-group/${groupId}`).then(extractData);

export const getItemGroup = (iCompanyId, query) =>
  companyAuthFetch.get(`/i-companies/${iCompanyId}/item-groups?${query}`).then(extractData);

export const getItemWithAttributeDetails = (iCompanyId, itemId) =>
  companyAuthFetch.get(`/i-companies/${iCompanyId}/items/${itemId}/details`).then(extractData);

export const createItem = (iCompanyId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${iCompanyId}/items`, payload)
    .then(extractData)
    .catch(error => error.response.data);

// export const getItemCollections = iCompanyId =>
//   companyAuthFetch.get(`/i-companies/${iCompanyId}/item-collections`).then(extractData);

// export const getItemBaseMaterials = iCompanyId =>
//   companyAuthFetch.get(`/i-companies/${iCompanyId}/item-base-materials`).then(extractData);

// export const getItemColors = iCompanyId =>
//   companyAuthFetch.get(`/i-companies/${iCompanyId}/item-colors`).then(extractData);

// export const createItemCollectionType = (iCompanyId, payload) =>
//   companyAuthFetch.post(`/i-companies/${iCompanyId}/item-collections`, payload).then(extractData);

// export const createItemBaseMaterial = (iCompanyId, payload) =>
//   companyAuthFetch
//     .post(`/i-companies/${iCompanyId}/item-base-materials`, payload)
//     .then(extractData);

// export const createItemColor = (iCompanyId, payload) =>
//   companyAuthFetch.post(`/i-companies/${iCompanyId}/item-colors`, payload).then(extractData);

export const exportAllItems = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/items/csv-export`).then(extractData);

export const exportAllItemsMasterOpeningStock = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/items/opening-stock/export`).then(extractData);

export const exportItems = (id, query) =>
  companyAuthFetch.get(`/i-companies/${id}/items/export?${query}`).then(extractData);

export const exportAllItemMaster = (id, query) =>
  companyAuthFetch.get(`/i-companies/${id}/items/all/export?${query}`).then(extractData);

export const fetchItemsMaster = (id, page, query) =>
  companyAuthFetch.get(`/i-companies/${id}/items/page/${page}?${query}`).then(extractData);

export const fetchGroupedItems = (id, page, query) =>
  companyAuthFetch.get(`/i-companies/${id}/items/grouped/page/${page}?${query}`).then(extractData);

export const fetchGroupedItemsVariants = (id, pageNo, itemAttributeValueId, query) =>
  companyAuthFetch
    .get(`/i-companies/${id}/items/${itemAttributeValueId}/variants/page/${pageNo}?${query}`)
    .then(extractData);

export const fetchItemGroupById = (id, fk) =>
  companyAuthFetch.get(`/i-companies/${id}/item-groups/${fk}`).then(extractData);

export const updateItemGroup = (id, fk, payload) =>
  companyAuthFetch.put(`/i-companies/${id}/item-groups/${fk}`, payload).then(extractData);

export const updateItem = (id, fk, payload) =>
  companyAuthFetch.put(`/i-companies/${id}/items/${fk}`, payload).then(extractData);

export const addItemAttribute = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/item-attributes`, payload).then(extractData);

export const updatedItemAttribute = (companyId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/item-attributes/${payload.id}`, payload)
    .then(extractData);

export const deleteItemAttribute = (companyId, attributeId) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/item-attributes/${attributeId}/delete/soft`)
    .then(extractData);

export const getItemAttributes = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/item-attributes`).then(extractData);

// export const updateItemConfig = (companyId, payload) =>
//   companyAuthFetch
//     .post(`/i-companies/${companyId}/item-attribute-config`, payload)
//     .then(extractData);

export const getItemConfig = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/item-attribute-config?${query}`)
    .then(extractData);

export const getItemAttributeValues = (iCompanyId, itemAttributeId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${iCompanyId}/item-attributes/${itemAttributeId}/item-attribute-values/${page}?${query}`
    )
    .then(extractData);

export const getAllItemAttributeValues = (iCompanyId, itemAttributeId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${iCompanyId}/item-attributes/${itemAttributeId}/item-attribute-values?${query}`
    )
    .then(extractData);

export const createItemAttributeValue = (iCompanyId, itemAttributeId, payload) =>
  companyAuthFetch
    .post(
      `/i-companies/${iCompanyId}/item-attributes/${itemAttributeId}/item-attribute-values`,
      payload
    )
    .then(extractData);
export const updateItemAttributeValue = (iCompanyId, itemAttributeId, payload) =>
  companyAuthFetch
    .put(
      `/i-companies/${iCompanyId}/item-attributes/${itemAttributeId}/item-attribute-values/${payload.id}`,
      payload
    )
    .then(extractData);

export const addCompanyBranch = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/i-branch`, payload).then(extractData);

export const editCompanyBranch = (companyId, branchId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/i-branches/${branchId}`, payload)
    .then(extractData);

// export const deleteCompanyBranch = (companyId, branchId) =>
//   companyAuthFetch.delete(`/i-companies/${companyId}/i-branch/${branchId}`).then(extractData);

// export const fetchCompanyBankAccounts = (id, fk) =>
//   companyAuthFetch.get(`/i-companies/${id}/accounts/bank-accounts`).then(extractData);

export const addCompanyBankAccount = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/i-bank`, payload).then(extractData);

export const editCompanyBankAccount = (companyId, bankAccId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/i-banks/${bankAccId}`, payload).then(extractData);

export const deleteCompanyBankAccount = (companyId, bankAccId) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/i-banks/${bankAccId}`).then(extractData);

export const fetchCompanyBankAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/i-banks`).then(extractData);

export const changeCompanyPassword = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/reset-password`, payload).then(extractData);

export const fetchRoleConfig = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/i-role-config`).then(extractData);

export const addMemberRole = (companyId, branchId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/i-branch/${branchId}/roles/assign`, payload)
    .then(extractData);

export const addMemberContact = (companyId, branchId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/i-branches/${branchId}/contacts/assign`, payload)
    .then(extractData);

// export const removeMemberRole = (companyId, branchId, payload) =>
//     companyAuthFetch
//       .post(`/i-companies/${companyId}/i-branch/${branchId}/roles/assign`, payload)
//       .then(extractData);

export const removeMemberRole = (companyId, branchId, payload) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/i-branch/${branchId}/roles/remove`, { data: payload })
    .then(extractData);

export const companyRolesRemoveMulti = (companyId, branchId, payload) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/i-branch/${branchId}/roles/remove-multi`, { data: payload })
    .then(extractData);

export const fetchTeamRoles = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/team-roles`).then(extractData);

export const addTeamMember = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/i-users`, payload).then(extractData);

export const updateCompanyDetails = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}`, payload).then(extractData);

export const updateBranchSettings = (companyId, branchId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/i-branch/${branchId}/settings`, payload)
    .then(extractData);

export const deleteCompanyBranch = (companyId, branchId) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/i-branches/${branchId}/soft`)
    .then(extractData);

export const fetchTeam = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/team`).then(extractData);

export const fetchAccountGroups = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/account-groups`).then(extractData);

export const fetchCompanyAccountById = (companyId, id) =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/${id}`).then(extractData);

export const addCompanyAccount = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/accounts`, payload).then(extractData);

export const updateCompanyAccount = (companyId, payload, id) =>
  companyAuthFetch.put(`/i-companies/${companyId}/accounts/${id}`, payload).then(extractData);

export const getUploadSignedUrl = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/aws/signed-url`, payload).then(extractData);

export const deleteImage = (companyId, id) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/images/${id}`).then(extractData);

export const updateImage = (companyId, id, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/images/${id}`, payload).then(extractData);

export const setPartyProfileImage = (companyId, id, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/parties/${id}/image`, payload).then(extractData);

export const uploadFile = (url, body) =>
  axios.put(url, body, {
    headers: {
      'Content-Type': body.type
    }
  });

export const getTaxesMaster = () => userAuthFetch.get(`/configurations/taxes`).then(extractData);

// export const fetchAllAccounts = companyID =>
//   companyAuthFetch.get(`/i-companies/${companyID}/accounts`).then(extractData);

export const fetchIndirectExpenseAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/indirect-expenses`).then(extractData);

export const fetchIndirectIncomeAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/indirect-income`).then(extractData);

export const fetchDirectIncomeAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/direct-income`).then(extractData);

export const fetchDirectExpensesAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/direct-expenses`).then(extractData);

export const fetchFixedAssetAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/fixed-assets`).then(extractData);

export const fetchDutiesAndTaxesAccounts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/accounts/duties-and-taxes`).then(extractData);

export const fetchItemUnits = () => {
  return fetch.get(`/configurations/items/units`).then(extractData);
};

export const fetchGroupedItemUnits = () => {
  return fetch.get(`/configurations/items/grouped-units`).then(extractData);
};

export const updateCompanyPassword = (userId, companyId, data) => {
  return userAuthFetch
    .put(`/i-users/${userId}/companies/${companyId}/password`, data)
    .then(extractData);
};

export const bulkUpdateItems = (companyId, payload) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/items`, payload).then(extractData);
};
export const getStatesMaster = () => userAuthFetch.get(`/configurations/states`).then(extractData);
export const getCountriesMaster = () =>
  userAuthFetch.get(`/configurations/countries`).then(extractData);

export const getIndustryTypesMaster = () =>
  userAuthFetch.get(`/configurations/industry-types`).then(extractData);

export const accountSearch = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/page/${pageNo}?${query}`)
    .then(extractData);

export const getCities = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/parties/cities/page/${pageNo}?${query}`)
    .then(extractData);

export const getPartiesStates = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/parties/states?${query}`).then(extractData);

export const getPartyAccountTypes = () =>
  userAuthFetch.get(`/configurations/party-account-types`).then(extractData);

export const deleteItems = (companyId, ids) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/items/soft?itemIds=${ids}`);

export const getItemGoupByHsn = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/hsn/item-group?${query}`).then(extractData);

export const getActiveSessions = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/access-tokens`).then(extractData);

export const deleteAccessToken = (companyId, token) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/access-tokens/${token}`).then(extractData);

export const uploadTallyData = (companyId, payload) =>
  companyAuthFetch
    .post(
      `/i-companies/${companyId}/import-party?url=${payload.url}&sheet=${payload.sheet}&source=${payload.source}`
    )
    .then(extractData);

export const fetchProgressTrackerStatus = (companyId, requestId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/progress-trackers/${requestId}`)
    .then(extractData);

export const fetchProgressTracker = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/progress-trackers`).then(extractData);

export const fetchAcknowledgeStatus = (companyId, trackerId) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/progress-tracker/${trackerId}`, {})
    .then(extractData);

export const resolveTask = (companyId, trackerId, itemId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/progress-trackers/${trackerId}/resolve?itemId=${itemId}`)
    .then(extractData);

export const fetchUserCompanySettings = (companyId, userId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/i-users/${userId}/user-company-settings`)
    .then(extractData);

export const fetchCompanySettings = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/company-settings`).then(extractData);

export const deleteAccount = (companyId, accId) => {
  return companyAuthFetch.delete(`/i-companies/${companyId}/accounts/${accId}/soft`);
};

export const updateUserCompanySettings = (companyId, userId, userSettingId, payload) =>
  companyAuthFetch
    .put(
      `/i-companies/${companyId}/i-users/${userId}/user-company-settings/${userSettingId}`,
      payload
    )
    .then(extractData);

export const updateUserItemFilterSettings = (companyId, userId, userSettingId, payload) =>
  companyAuthFetch
    .put(
      `/i-companies/${companyId}/i-users/${userId}/user-company-settings/${userSettingId}/item-filter-settings`,
      payload
    )
    .then(extractData);

export const updateUserCompanyBulkItemSettings = (companyId, userId, userSettingId, payload) =>
  companyAuthFetch
    .put(
      `/i-companies/${companyId}/i-users/${userId}/user-company-settings/${userSettingId}/bulk-item-settings`,
      payload
    )
    .then(extractData);

export const updateUserCompanyVoucherSettings = (companyId, userId, userSettingId, payload) =>
  companyAuthFetch
    .put(
      `/i-companies/${companyId}/i-users/${userId}/user-company-settings/${userSettingId}/voucher-settings`,
      payload
    )
    .then(extractData);

export const handleHideItem = (companyId, ids) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/items/hide?ids=${JSON.stringify(ids)}`);

export const handleItemIShopVisibilityToggle = (companyId, flag, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/items/visibility/${flag}`, payload);

export const handleUnhideItem = (companyId, ids) =>
  companyAuthFetch.patch(`/i-companies/${companyId}/items/unhide?ids=${JSON.stringify(ids)}`);

export const fetchHideItems = (companyId, page, itemGroupId, query) => {
  let path = `/i-companies/${companyId}/items/hidden/page/${page}?${query}`;
  if (itemGroupId) path += `&itemGroupId=${itemGroupId}`;
  return companyAuthFetch.get(path);
};
export const getItemTags = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/item-tags`).then(extractData);

export const postItemTag = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/item-tags`, payload).then(extractData);

export const updateItemTag = (companyId, tagId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/item-tags/${tagId}`, payload).then(extractData);

export const deleteItemTag = (companyId, tagId, payload) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/item-tags/${tagId}`).then(extractData);

export const addTagsToItems = (companyId, itemIds, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/items/tags?itemIds=${itemIds}`, payload)
    .then(extractData);

export const removeTagsFromItems = (companyId, query) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/items/tags${query}`).then(extractData);

export const getItemCataloguePublicShareStatus = (companyId, page, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/items/share-status/public/page/${page}?${query}`)
    .then(extractData);

// // DEPRECATED
// export const fetchAvailableQty = (companyId, itemId, query) =>
//   companyAuthFetch
//     .get(`/i-companies/${companyId}/items/${itemId}/quantity?${query}`)
//     .then(extractData);

export const getAvailableQty = (companyId, itemId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/items/${itemId}/qty?${query}`).then(extractData);

export const fetchCatalogueItems = (companyId, key, page = 1, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/share-statuses/${key}/model/page/${page}?${query}`)
    .then(extractData);

export const addItemsToCatalogue = (companyId, sharedListId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/shared-items/${sharedListId}/add`, payload)
    .then(extractData);

export const removeItemsFromCatalogue = (companyId, sharedListId, payload) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/shared-items/${sharedListId}/remove`, { data: payload })
    .then(extractData);

export const renameCatalogue = (companyId, sharedListId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/share-statuses/${sharedListId}/rename`, payload)
    .then(extractData);

export const deleteCatalogue = (companyId, sharedListId, payload) =>
  companyAuthFetch
    .delete(`/i-companies/${companyId}/share-statuses/${sharedListId}`, payload)
    .then(extractData);

export const handleShareAccessToggle = (companyId, shareId, flag) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/status/${shareId}/access`, {
      accessEnabled: flag
    })
    .then(extractData);

export const toggleEShopStatus = (companyId, status) =>
  companyAuthFetch.put(`/i-companies/${companyId}/e-shop/${status}`).then(extractData);

export const updateEShop = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/update-e-shop`, payload).then(extractData);

export const fetchEShopShareMessage = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/share-message/e-shop`).then(extractData);
export const fetchDirectContact = (companyId, branchId, purposeId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/i-branches/${branchId}/contacts/${purposeId}`)
    .then(extractData);

export const clearContact = (companyId, branchId, purposeId) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/i-branch/${branchId}/contacts/clear/${purposeId}`, {})
    .then(extractData);
export const fetchItemVariant = api => companyAuthFetch.get(api).then(extractData);

export const fetchAttributeList = api => companyAuthFetch.get(api).then(extractData);

export const fetchAttributeValues = (eshopId, attributeId) =>
  companyAuthFetch
    .get(`/share-statuses/e-shop/${eshopId}/item-attributes/${attributeId}/item-attribute-values`)
    .then(extractData);

export const handleCatalogueSettingUpdate = (companyId, shareId, data) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/conversation-channels/${shareId}`, {
      settings: data
    })
    .then(extractData);

export const updateItemPosition = (companyId, catalogueId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/share-status/${catalogueId}/items/arrange`, payload)
    .then(extractData);
// mr upload file
export const uploadMRStockAppFile = (companyId, url) =>
  companyAuthFetch.post(`/i-companies/${companyId}/stock-app/upload?url=${url}`).then(extractData);

// upload current user profile picture
export const setUserProfilePicture = (companyId, userId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/user/${userId}/images`, payload)
    .then(extractData);

export const updateUserProfile = (userId, id, payload) =>
  userAuthFetch.put(`/i-users/${userId}/profile-image/${id}`, payload).then(extractData);

export const generateUserBusinessCard = companyId =>
  companyAuthFetch.post(`/i-companies/${companyId}/user/business-card`).then(extractData);

export const getUserBusinessCard = companyId =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/share-status/i-user/business-card/public/page/1`)
    .then(extractData);
export const fetchPOSBillingParty = companyId =>
  companyAuthFetch.post(`/i-companies/${companyId}/pos-party`);

export const itemSearch = (companyId, query) =>
  companyAuthFetch
    .get(`/${companyId}/item-search?:query`, {
      params: {
        query
      }
    })
    .then(extractData);
