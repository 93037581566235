import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import {
  formatDate,
  getFormattedDate3,
  getTaxPercentageAndHsn,
  dateDiffInDays,
  purchaseTypeFlag,
  salesTypeFlag,
  getPartyGstBusinessTypeRef,
  getUniqArray,
  getEndTimeOfDay,
  getStartTimeOfDay,
  getPageFromPath,
  getVoucherTransactionIsDebit,
  getStartOfFinancialYear,
  getEndOfFinancialYear,
  getFormattedDateIso,
  getFormattedDateWithTime,
  getfloatingValue,
  getUnitPriceForPayload
} from '../utils/helperFunctions';
import {
  handleItemCalculations,
  initVoucherCalc,
  resetVoucherCalc,
  getOtherChargesAfterTax
} from '../utils/voucherCalculationFn';
import VoucherCalModule from 'voucher-calculation';

import {
  LAST_VISITED_VOUCHER,
  UPDATE_VOUCHERS,
  VOUCHER_DURATION_TYPE,
  UPDATE_VOUCHER_TYPES,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_CHALLAN,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_CREDIT_NOTE,
  VOUCHER_TYPE_DEBIT_NOTE,
  VOUCHER_TYPE_JOURNAL,
  VOUCHER_TYPE_CONTRA,
  VOUCHER_TYPE_PAYMENT,
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_LINKED_SCHALLAN,
  VOUCHER_TYPE_LINKED_PCHALLAN,
  VOUCHER_TYPE_LINKED_PESTIMATION,
  VOUCHER_TYPE_LINKED_SESTIMATION,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_PESTIMATION,
  VOUCHER_TYPE_EXPENSE,
  ERROR,
  PARTY,
  EDIT,
  EDIT_LINE,
  PARTY_SELECT,
  DELETE_LINE,
  NEW_LINE,
  SET_ADDITIONAL_DISCOUNT,
  RECALCULATE,
  UPDATE,
  ADD,
  DELETE,
  EDIT_OTHER_CHARGES_AFTER_TAX,
  MERGE_ESTIMATION,
  SET_ROUNDOFF,
  SET_EXCLUSIVE_DISCOUNT,
  EDIT_OTHER_CHARGES,
  SET_COUPON_DISCOUNT,
  SET_BRANCH,
  NEW,
  PARTY_STATEMENT,
  AGAINST_VOUCHER,
  UNRECONCILED_VOUCHER_STATEMENT,
  VOUCHER_TYPE_STOCK_TRANSFER,
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  INVENTORY_ITEM_STATEMENT_PATH,
  CREATE,
  FILTERS_KEY,
  EXCLUDE_DUE_DATE,
  SUCCESS,
  RUPEES,
  ADD_METHOD,
  VOUCHER_TYPE_STOCK_TYPE,
  ITEM_NAME_SEPERATOR,
  ITEM_CODE_SEPERATOR,
  SKU_PRINT_VIEW_TABLE,
  ADJUSTMENT_ON_ITEM_PRICE,
  PERCENT,
  INPROGRESS,
  SALES,
  PURCHASE
} from '../constants';

import {
  VOUCHER_DATE_RANGE,
  FETCH_VOUCHERS_DATA,
  ON_HIDE_SHOW_LINK,
  SET_SELECTED_VOUCHER_ID,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_FOR_SELECTED_VOUCHER,
  UPDATE_BUSINESS_CONTACT,
  POPULATE_ADDTIONAL_DISCOUNT,
  TOGGLE_SHIPPING_DETAILS_LINK,
  FETCH_UNPAID_VOUCHERS_SUCCESS,
  FETCH_UNPAID_VOUCHERS_FAILURE,
  SET_ROUND_OFF_VALUE,
  ADD_LINE_ITEM_CRDR,
  RESET_SHIPPING_DETAILS,
  HANDLE_VOUCHER_DETAILS_DONE,
  RESET_TRANSPORT_DETAILS,
  RESET_VOUCHER_DETAILS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILED,
  UPDATE_VOUCHERS_DETAILS_ACCOUNTS,
  ON_CLOSE_VOUCHER_PICKER,
  UPDATE_PARTY_NAME,
  UPDATE_DATE,
  UPDATE_ACCOUNT,
  UPDATE_SELECTED_VOUCHER,
  FETCH_VOUCHER_BY_NUMBER_SUCCESS,
  FETCH_VOUCHER_BY_NUMBER_FAILURE,
  UPDATE_AMOUNT,
  FETCH_VOUCHER_SUCCESSED,
  FETCH_VOUCHER_FAILED,
  SHOW_LINE_ITEM_PICKER,
  CURRENT_CHARGE_INDEX_FOCUS,
  CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS,
  ON_HIDE_OTHER_CHARGES,
  ON_HIDE_OTHER_CHARGES_AFTER_TAX,
  ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE,
  DELETE_LINE_ITEM,
  VOUCHERS_LIST_REQUESTED,
  GET_VOUCHER_REQUESTED,
  VOUCHERS_LIST_SUCCESS,
  VOUCHERS_LIST_FAILED,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAILED,
  INCREMENT_VOUCHER_REQUESTED,
  INCREMENT_VOUCHER_SUCCESS,
  INCREMENT_VOUCHER_FAILED,
  ADD_LINE_ITEM,
  LINE_ITEM_INDEX,
  UPDATE_TRANSPORT_DETAILS,
  ADD_OTHER_CHARGES_NEW_LINE,
  VIEW_TAX_ANALYSIS,
  DELETE_CHRAGES,
  DELETE_OTHER_CHRAGES_AFTER_TAX,
  RESET_ADD_DISCOUNT,
  GENERATE_VOUCHER_PDF_REQUESTED,
  GENERATE_VOUCHER_PDF_SUCCESS,
  GENERATE_VOUCHER_PDF_FAILED,
  SET_VOUCHER_TYPE,
  UPDATE_FILTERED_NAMES,
  UPDATE_CHARGES,
  TOGGLE_PURCHASE_SALES_DRAWER,
  TOGGLE_ADD_EDIT_DRAWER,
  SET_DISCOUNT_UNITS,
  SET_DISCOUNT_VALUE,
  UPDATE_LINE_ITEM_FIELD,
  DISCOUNT_CHANGE,
  // HANDLE_ITEM_CALCULATIONS,
  UPDATE_TAX_BRACKUP,
  BRANCH_SEARCH_TEXT,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  HANDLE_TRANSPORT_DONE,
  SET_SHIPPING_ADDRESS,
  SAVED_VOUCHER_SUCCESS,
  POPULATE_OTHER_CHARGES,
  HIDE_SHIPPING_DETAILS,
  RESET_VOUCHER_LINE_ITEMS,
  CLOSE_BUSINESS_CARD,
  RESET_VOUCHER_FORM,
  TOGGLE_VOUCHER_DETAILS_LINK,
  TOGGLE_ALERT_DIALOG,
  UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER,
  POPULATE_OTHER_CHARGES_AFTER_TAX,
  POPULATE_ROUNDOFF_VALUE,
  SET_LINE_ITEM_SEARCH_TEXT,
  TOGGLE_TRANSPORT_LINK,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_FAILED,
  FETCH_BANK_LIST_SUCCESS,
  FETCH_BANK_LIST_FAILED,
  VOUCHER_BRANCH_POPULATE,
  VOUCHERS_DETAILS_ACCOUNTS_POPULATE,
  CLOSE_AND_RESET_VOUCHER_FORM_DONE,
  SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER,
  SET_SELECTED_VOUCHER_NO,
  ACTION_SAVE_BUTTON_DISABLE,
  SELECT_RESET,
  SET_SUPPLIER_INVOICE_NO,
  SET_SUPPLIER_INVOICE_DATE,
  SET_SELECTED_VOUCHER_BY_REF_NO,
  SET_VOUCHER_UPLOADED_DOCUMENTS,
  UPDATE_NARRATION,
  ADD_EXPENSE_LINE,
  SET_ROUND_OFF_OFFSET,
  POPULATE_ITEM_QTYS_TOTAL,
  ON_TOGGLE_EXCLUSIVE_DISCOUNT,
  TOGGLE_GROUPWISE_DISCOUNT_MODAL,
  FETCH_VOUCHER_TYPES_FAILED,
  FETCH_VOUCHER_TYPES_SUCCESS,
  SET_ESTIMATION_LINKED_ITEM_LIST,
  SET_PARTY_SALES_DISCOUNT,
  // COMPANY_FETCH_INDIRECT_EXPENSE_ACCOUNTS_SUCCESS,
  // COMPANY_FETCH_INDIRECT_EXPENSE_ACCOUNTS_FAILED,
  FETCH_SHARE_VOUCHER_DATA_SUCCESS,
  FETCH_SHARE_VOUCHER_DATA_FAILED,
  FETCH_VOUCHER_ITEMS_FAILED,
  FETCH_VOUCHER_ITEMS_SUCCESS,
  FETCH_VOUCHERS_DATA_REQUEST,
  FETCH_VOUCHERS_DATA_SUCCESS,
  FETCH_VOUCHERS_DATA_FAILED,
  UPDATE_LAST_LINE_ITEM_PRICE,
  RESET_VOUCHER_ITEMS,
  RESET_SORTING_COLUMN,
  POPULATE_COUPON_DISCOUNT,
  FETCH_ACCOUNTS_REQUESTED,
  TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL,
  FETCH_UNPAID_VOUCHERS_REQUESTED,
  UPDATE_SHIPPING_GEO_LOC,
  UPDATE_EXCLUSIVE_DISCOUNT_DATA,
  POPULATE_BILL_FINAL_AMOUNT,
  POPULATE_BILL_TAX_AMOUNT,
  SET_CALCULATION_STATUS,
  VOUCHER_CALC_TOGGLE,
  POPULATE_BILL_TAX,
  STORE_OLD_LINE_ITEM,
  GET_TAX_ANALYSIS,
  REFRESH_LINE_ITEM,
  CURRENT_VOUCHER_LIST_SUCCESS,
  RESET_OLD_LINE_ITEM,
  UPDATE_GROUP_WISE_ITEM_COUNT,
  POPULATE_LINE_AMOUNT_SUM,
  POPULATE_BILL_ITEMS_PRICE,
  POPULATE_BILL_DISCOUNT_AMOUNT,
  POPULATE_OTHER_CHARGES_TOTAL,
  POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL,
  TABLE_LOADING,
  SELECT_PREPAREDBY_FILTER,
  SELECT_PARTY_FILTER,
  SELECT_STATUS_FILTER,
  SELECT_DUE_AMOUNT_FILTER,
  SELECT_BRANCH_FILTER,
  SELECT_READ_LOCK_FILTER,
  SELECT_UPDATE_LOCK_FILTER,
  RESET_FILTERS,
  HIDDEN_VOUCHER_LIST_SUCCESS,
  SAVE_PARENT_VOUCHER_OTHERCHARGES,
  SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF,
  RESET_PARENT_CHILD_VOUCHER_DATA,
  POPULATE_ADDITIONAL_DISCOUNT_AMOUNT,
  SET_SELECTED_BRANCH,
  CLEAR_UNPAID_VOUCHERS,
  TOGGLE_CHILD_ERROR_FLAG,
  SET_VOUCHER_ITEM_LIST,
  VOUCHER_DATE,
  FETCH_VOUCHER_ITEMS_REQUESTED,
  FETCH_SHARE_VOUCHER_DATA_REQUESTED,
  SET_DRAWER_MODE,
  SHARE_VOUCHER_REQUESTED,
  SHARE_VOUCHER_SUCCESS,
  SHARE_VOUCHER_FAILED,
  UPDATED_VOUCHER_SUCCESS,
  SET_INWARD_OUTWARD_SUBTYPE,
  SET_SELECTED_DESTINATION_BRANCH,
  FETCHED_VOUCHER_TYPE,
  GET_CURRENT_BALANCE_REQUESTED,
  GET_CURRENT_BALANCE_SUCCESS,
  HIDDEN_VOUCHER_LIST_REQUESTED,
  HIDDEN_VOUCHER_LIST_ERROR,
  SELECT_ACCOUNT_FILTER,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED,
  TOGGLE_RECORD_RECEIPT_MODAL,
  TOGGLE_SALES_PERSON_LINK,
  UPDATE_SALES_PERSON_DETAILS,
  FETCH_ITEM_DETAILS_REQUESTED,
  FETCH_ITEM_DETAILS_SUCCESS,
  FETCH_ITEM_DETAILS_FAILED,
  DELETE_VOUCHER_REQUEST,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAILED,
  GET_AUTO_POPULATE_ROUNDOFF_DATA,
  POPULATE_DUE_AMOUNT,
  FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED,
  FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS,
  FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED,
  GET_CURRENT_BALANCE_FAILED,
  UPDATE_GROUP_TO_CODE_MAP,
  UPDATE_LINE_ITEM_SR_NOS,
  SET_SUBTYPE_FILTER,
  SET_DUE_DATE,
  FETCH_VOUCHER_DUE_AMOUNT_REQUESTED,
  FETCH_VOUCHER_DUE_AMOUNT_SUCCESS,
  FETCH_VOUCHER_DUE_AMOUNT_FAILED,
  TOGGLE_RECORD_RP_FROM_VOUCHER,
  SET_RECORD_RP_DATA_FROM_VOUCHER,
  TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL,
  RESET_VOUCHER_DIALOG_FILTERS,
  PRESERVE_VOUCHER_DATES,
  SET_VOUCHER_CREATION_MODE,
  POS_BILLING_PARTY,
  SET_NARRATION_FILTER,
  TOOGLE_PICKUP_LOCATION_LINK,
  UPDATE_PICKUP_LOCATION_DETAILS,
  RESET_SELECTED_VOUCHER,
  SET_SUB_TYPE,
  UPDATE_SHIPPING_ADDRESS_NAME,
  SET_EDIT_VOUCHER_ID,
  UPDATE_LINKED_ESTIMATION_STATUS,
  SET_PARTY_PURCHASE_DISCOUNT,
  SET_PRINTING_DIALOG_STATUS,
  SET_VOUCHER_FOR_PRINT,
  GET_OTHER_CHARGES_AFTER_TAX_TOTAL,
  VOUCHER_LIST_COUNT_REQUESTED,
  VOUCHER_LIST_COUNT_SUCCESS,
  VOUCHER_LIST_COUNT_FAILED
} from './types';

import { serverError } from '../api/server';
import * as api from '../api/vouchers';
import { openSnackbar } from './snackbar';
import { setItemProperty } from './items';
// import VoucherCalModule from 'voucher-calculation';

import { getBranchId, getValuesByKey } from '../utils/helperFunctions';
import { navigate } from './nav';
import { UNREGISTERED_BUSINESS } from '../constants';
import {
  handleStatementPaginate,
  fetchUnreconciledVouchers,
  fetchPartyProfile,
  fetchAccountReconciledVouchersGroupByDate
} from './contacts';
import { fetchCompanies, fetchCompanyBranch, getUserCompanySettings } from './companies';
import {
  addItem,
  getAvailableQty,
  getItemWithAttributeDetails,
  updateUserCompanyVoucherSettings
} from '../api/companies';
import { fetchPartyContacts } from '../actions/contacts';
import { handleItemStatement, showAccountStatementViewAction } from './reports';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import { fetchAccountsCurrentBalance, resetAttributesFilter, resetSortingColumn } from '.';
import { debounce } from 'lodash-es';
// import { stringify } from 'querystring';
// import { fetchPartySalesDiscount } from './contacts';
// import otherChargesAfterTax from '../components/Voucher/addSPCDComponent/otherChargesAfterTax';

const VoucherRoundOffFn = VoucherCalModule.roundOffMultiplier;
const VoucherRateCodeFn = VoucherCalModule.rateCodeFormula;

const VoucherOptimizePureCal = VoucherCalModule.optimizePureCal;

export const updateJournalPayload = (value, key) => {
  return {
    type: 'UPDATE_JOURNAL_PAYLOAD',
    payload: value,
    key
  };
};

export const setJournalPayload = journal => {
  return {
    type: 'SET_JOURNAL_PAYLOAD',
    payload: journal
  };
};

export const resetJournalPayload = () => {
  return {
    type: 'RESET_JOURNAL_PAYLOAD'
  };
};

export const setPayloadId = () => {
  return {
    type: 'UPDATE_PAYLOAD_ID'
  };
};

export const toggleChildErrorFlag = value => {
  return {
    type: TOGGLE_CHILD_ERROR_FLAG,
    payload: value
  };
};

export const toggleRecordReceiptModal = (flag = false, voucher = {}) => {
  return {
    type: TOGGLE_RECORD_RECEIPT_MODAL,
    flag,
    voucher
  };
};

export const toggleRecordRP = (recordRPFlag = false) => {
  return {
    type: TOGGLE_RECORD_RP_FROM_VOUCHER,
    recordRPFlag
  };
};

export const toggleRecordRpFromVoucherModal = (flag = false) => {
  return {
    type: TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL,
    flag
  };
};

const setRecordRPObjFromVoucherPayload = recordRpPayload => dispatch => {
  dispatch({ type: SET_RECORD_RP_DATA_FROM_VOUCHER, recordRpPayload });
};

export const selectPartyFilter = (value, storeKey = FILTERS_KEY) => {
  return {
    type: SELECT_PARTY_FILTER,
    payload: value,
    storeKey
  };
};

export const selectAccountFilter = value => {
  return {
    type: SELECT_ACCOUNT_FILTER,
    payload: value
  };
};

export const selectPreparedFilter = value => {
  return {
    type: SELECT_PREPAREDBY_FILTER,
    payload: value
  };
};

export const selectStatusFilter = value => {
  return {
    type: SELECT_STATUS_FILTER,
    payload: value
  };
};

export const toggleDueAmountFilter = value => {
  return {
    type: SELECT_DUE_AMOUNT_FILTER,
    payload: value
  };
};

export const selectBranchFilter = value => {
  return {
    type: SELECT_BRANCH_FILTER,
    payload: value
  };
};

export const resetFilters = () => {
  return {
    type: RESET_FILTERS
  };
};

export const resetVoucherDialogFilters = () => {
  return {
    type: RESET_VOUCHER_DIALOG_FILTERS
  };
};

export const selectReadLockFilter = value => {
  return {
    type: SELECT_READ_LOCK_FILTER,
    payload: value
  };
};

export const selectUpdateLockFilter = value => {
  return {
    type: SELECT_UPDATE_LOCK_FILTER,
    payload: value
  };
};

export const selectSubtypeFilter = value => {
  return {
    type: SET_SUBTYPE_FILTER,
    payload: value
  };
};

/**
 * This is basically for all combined Vouchers.
 * Child voucher = Sestimation, Pestimation.
 * Child State = [childOtherChargesDiff] & Parent State = [parentVoucherInfo] where we save the original parent data and child diff data
 * Action to Reset the parent and child voucher state.
 */

export const resetParentChildVoucherData = () => {
  return {
    type: RESET_PARENT_CHILD_VOUCHER_DATA
  };
};

/**
 * This is basically for all combined Vouchers.
 * Child voucher = Sestimation, Pestimation.
 * Child State = [childOtherChargesDiff]
 * Action to Set Child diff state
 */

export const setChildOtherChargesDiff = payload => {
  return {
    type: SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF,
    payload
  };
};

/**
 * This is basically for all combined Vouchers.
 * Child voucher = Sestimation, Pestimation.
 * Parent State = [parentVoucherInfo] where we save the original parent data.
 * Action to set the original parent data.
 */

export const saveParentVoucherOtherCharges = () => (dispatch, getState) => {
  const {
    vouchers: {
      _otherCharges,
      _otherChargesAfterTax,
      _voucherModuleResults: { billFinalAmount }
    }
  } = getState();
  dispatch({
    type: SAVE_PARENT_VOUCHER_OTHERCHARGES,
    payload: {
      otherCharges: _otherCharges,
      otherChargesAfterTax: _otherChargesAfterTax,
      billFinalAmount
    }
  });
};

export const getHideVoucher = (
  page,
  accId = [],
  branchId = [],
  startDateQuery = null,
  endDateQuery = null,
  callback
) => (dispatch, getState) => {
  let {
    currentCompany: { id },
    vouchers: {
      getDate: { startDate, endDate },
      type
    }
  } = getState();
  dispatch({ type: HIDDEN_VOUCHER_LIST_REQUESTED });

  if (type === VOUCHER_TYPE_CREDIT_NOTE) {
    type = 'credit';
  }
  if (type === VOUCHER_TYPE_DEBIT_NOTE) {
    type = 'debit';
  }
  if (type === VOUCHER_TYPE_SCHALLAN) {
    type = 'challan';
  }
  if (type === VOUCHER_TYPE_STOCK_TRANSFER) {
    type = VOUCHER_TYPE_STOCK_TRANSFER;
  }
  if (type === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    type = VOUCHER_TYPE_STOCK_TYPE;
  }
  return api
    .fetchHideVoucher(
      id,
      type,
      startDateQuery || startDate,
      endDateQuery || endDate,
      page,
      getValuesByKey(accId, 'refId'),
      getValuesByKey(branchId)
    )
    .then(res => {
      dispatch({
        type: HIDDEN_VOUCHER_LIST_SUCCESS,
        payload: res.data
      });
      callback && callback(res.data);
    })
    .catch(error => {
      dispatch({ type: HIDDEN_VOUCHER_LIST_ERROR });
      serverError(error);
    });
};

export const handleHideVoucher = (payload, type) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    vouchers: {
      getDate: { startDate }
    }
  } = getState();
  let endDate = formatDate(moment().endOf('day')._d);

  if (type === VOUCHER_TYPE_CREDIT_NOTE) {
    type = 'credit';
  }
  if (type === VOUCHER_TYPE_DEBIT_NOTE) {
    type = 'debit';
  }
  if (type === VOUCHER_TYPE_SCHALLAN) {
    type = 'challan';
  }
  if (type === VOUCHER_TYPE_STOCK_TRANSFER) {
    type = VOUCHER_TYPE_STOCK_TRANSFER;
  }
  if (type === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    type = VOUCHER_TYPE_STOCK_TYPE;
  }
  return api
    .handleHideVoucher(id, payload, type)
    .then(() => {
      dispatch(onSuccessLock(id, startDate, endDate));
      dispatch(openSnackbar('Voucher Hidden Successfully'));
      // dispatch(getHideVoucher(1));
    })
    .catch(error => serverError(error));
};

export const verifyMultipleVoucherPrint = (selectedVouchers, callback) => (dispatch, getState) => {
  let set1 = new Set();
  let set2 = new Set();

  if (selectedVouchers.length > 3) {
    dispatch(openSnackbar('Maximum 3 vouchers are allowed', ERROR));
    return false;
  }

  selectedVouchers.forEach(voucher => {
    set1.add(voucher.type);
    set2.add(voucher.subType);
  });

  if (set1.size !== 1 || set2.size !== 1) {
    dispatch(openSnackbar('Only same type of voucher is allowed', ERROR));
    return false;
  }

  callback && callback();
};

export const printMultipleVouchers = (voucherIds, templateId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  mixpanel.track('voucher-print');

  let query = `voucherIds=${voucherIds.toString()}`;

  if (templateId) {
    query += `&templateId=${templateId}`;
  }

  return api
    .handleMultiplePrintVoucher(companyId, query)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

export const handleUnhideVoucher = (payload, voucherType, filters, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    vouchers: {
      getDate: { startDate }
    }
  } = getState();
  const { selectedParty, selectedBranch } = filters;
  let endDate = formatDate(moment().endOf('day')._d);
  if (voucherType === VOUCHER_TYPE_CREDIT_NOTE) {
    voucherType = 'credit';
  }
  if (voucherType === VOUCHER_TYPE_DEBIT_NOTE) {
    voucherType = 'debit';
  }
  if (voucherType === VOUCHER_TYPE_SCHALLAN) {
    voucherType = 'challan';
  }
  if (voucherType === VOUCHER_TYPE_STOCK_TRANSFER) {
    voucherType = VOUCHER_TYPE_STOCK_TRANSFER;
  }
  if (voucherType === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    voucherType = VOUCHER_TYPE_STOCK_TYPE;
  }
  return api
    .handleUnhideVoucher(id, payload, voucherType)
    .then(() => {
      dispatch(onSuccessLock(id, startDate, endDate, true));
      dispatch(openSnackbar('Unhide Voucher Action Successfully'));
      dispatch(getHideVoucher(1, selectedParty, selectedBranch));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const linkAjParty = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .linkAjParty(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const linkTallyParty = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .linkTallyParty(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const delinkAjParty = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .delinkAjParty(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const delinkTallyParty = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .delinkTallyParty(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const delinkAjCompany = (id, payload) => dispatch => {
  return api
    .delinkAjCompany(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      dispatch(fetchCompanies());
    })
    .catch(error => serverError(error));
};

export const delinkTallyCompany = (id, payload) => dispatch => {
  return api
    .delinkTallyCompany(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      dispatch(fetchCompanies());
    })
    .catch(error => serverError(error));
};

export const linkAjCompany = (id, payload) => dispatch => {
  return api
    .linkAjCompany(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      dispatch(fetchCompanies());
    })
    .catch(error => serverError(error));
};

export const linkTallyCompany = (id, payload) => dispatch => {
  return api
    .linkTallyCompany(id, payload)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      dispatch(fetchCompanies());
    })
    .catch(error => serverError(error));
};

export const getMigratedVouchers = (vouchers, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  const voucherIds = vouchers.map(v => v._id.toString());
  return api
    .fetchMigratedVouchers(id, voucherIds.join(','))
    .then(res => {
      callback && callback(res);
    })
    .catch(error => serverError(error));
};

export const setCouponDiscount = (value, unit, callback, triggerCalculationFlag = true) => (
  dispatch,
  getState
) => {
  const couponDiscount = {
    value,
    unit
  };
  dispatch({
    type: POPULATE_COUPON_DISCOUNT,
    couponDiscount
  });
  triggerCalculationFlag && handleItemCalculations(dispatch, getState, SET_COUPON_DISCOUNT);
  callback && callback();
};

export const handleDeleteDocument = (docId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .deleteDocument(id, docId)
    .then(() => {
      callback && callback();
    })
    .catch(error => console.log(error));
};

export const fetchAccountSummaryFinalAmount = (vouchers, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  const ids = [];
  vouchers.forEach(v => {
    ids.push(v._id);
  });
  return api
    .getMigrateFinalAmounts(id, ids.toString())
    .then(res => {
      callback && callback(res.data);
    })
    .catch(error => serverError(error));
};

export const companyMigrate = callback => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .migrateCompany(id)
    .then(() => {
      dispatch(openSnackbar('Action successfull'));
      dispatch(fetchCompanies());
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const generateVoucherSummary = (payload, callback, failCallback) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    vouchers: { payloadId }
  } = getState();
  mixpanel.track('migrate-tally');
  return api
    .migrateTally(id, payload, payloadId)
    .then(() => {
      dispatch(openSnackbar('Voucher summary created successfully'));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
      failCallback && failCallback();
    });
};

export const generateAccountSummary = (payload, callback, failCallback) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    vouchers: { payloadId }
  } = getState();
  mixpanel.track('migrate-aj');
  return api
    .migrateAj(id, payload, payloadId)
    .then(() => {
      dispatch(openSnackbar('Account summary created successfully'));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
      failCallback && failCallback();
    });
};

export const generateAccVoucherSummary = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .migrate(id, payload)
    .then(() => {
      dispatch(openSnackbar('Account and Voucher summary created successfully'));
      callback && callback();
    })
    .catch(error => serverError(error));
};

export const fetchLineItemLastSPPrice = (item, replaceItemIndex) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      selectedPartyAccount: { id: businessId },
      type: voucherType,
      _lineItems
    }
  } = getState();

  //for cr/dr take corresponding sales/purchase type
  const queryVoucherType =
    voucherType === VOUCHER_TYPE_CREDIT_NOTE
      ? VOUCHER_TYPE_SALES
      : voucherType === VOUCHER_TYPE_DEBIT_NOTE
      ? VOUCHER_TYPE_PURCHASE
      : voucherType;
  let query = '';
  query = businessId ? `partyId=${businessId}` : '';

  let itemId = item.id ? item.id : item.itemId;

  api
    .getLastLineItemPrice(companyId, itemId, queryVoucherType, query)
    .then(response => {
      const fetchedItem = response.data;
      let checkItemIndex = _lineItems.findIndex(it => it.id === item.id);

      if (checkItemIndex > 0) {
        dispatch(lineItemIndex(checkItemIndex));
      } else {
        dispatch(lineItemIndex(_lineItems.length));
      }
      if (fetchedItem.unitPrice) {
        dispatch({
          type: UPDATE_LAST_LINE_ITEM_PRICE,
          payload: fetchedItem,
          key: !isNaN(replaceItemIndex) ? replaceItemIndex : _lineItems.length
        });
        handleItemCalculations(dispatch, getState, EDIT_LINE);
      }
    })
    .catch(error => serverError(error));
};

export const getAndSetVoucherFileUrl = (items, callback) => dispatch => {
  const documents = [];
  items.length > 0 &&
    items.forEach(item => {
      documents.push({
        ...item,
        url: item.url,
        name: item.name,
        fileType: item.type
      });
    });
  dispatch(setVoucherDocuments(documents));
  callback && callback();
};

export const setVoucherDocuments = documents => dispatch => {
  dispatch({
    type: SET_VOUCHER_UPLOADED_DOCUMENTS,
    item: documents
  });
};

const getRateCodeValue = (
  unitSellWholeSalePrice,
  unitSellRetailPrice,
  rateCodeConfig = {},
  callback
) => {
  const rateCodeTempConfig = rateCodeConfig;
  const rateCode = VoucherRateCodeFn.getRateCode(
    unitSellWholeSalePrice || 0,
    unitSellRetailPrice || 0,
    rateCodeTempConfig
  );
  callback && callback(rateCode);
  return rateCode;
};

const getRoundOffValue = (unitPrice, roundOffConfig = [], callback) => {
  const roundOffValue =
    VoucherRoundOffFn.getRoundoffMultiplierValue(
      unitPrice,
      //TODO Quick fix for demo, need to look into it , why going empty
      roundOffConfig || []
    ) || unitPrice; // default to value passed
  callback && callback(roundOffValue);
  return roundOffValue;
};

const setSupplierInvoiceNo = payload => {
  return {
    type: SET_SUPPLIER_INVOICE_NO,
    payload: payload
  };
};

const setSelectedVoucherByNo = payload => {
  return {
    type: SET_SELECTED_VOUCHER_BY_REF_NO,
    payload: payload
  };
};

const setSupplierInvoiceDate = date => {
  return {
    type: SET_SUPPLIER_INVOICE_DATE,
    payload: date
  };
};

const setDueDate = date => {
  return {
    type: SET_DUE_DATE,
    payload: date
  };
};

const setCRDRSubType = payload => dispatch => {
  dispatch({
    type: SET_SUB_TYPE,
    payload
  });
};

const setLastVisitedVoucher = payload => {
  return {
    type: LAST_VISITED_VOUCHER,
    payload: payload
  };
};

const setActionSaveButtonDisable = payload => {
  return {
    type: ACTION_SAVE_BUTTON_DISABLE,
    payload: payload
  };
};

const resetVoucherItem = () => {
  return {
    type: RESET_VOUCHER_ITEMS
  };
};

const fetchItemByVoucherId = voucherId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_VOUCHER_ITEMS_REQUESTED });
  api
    .getItemsByVoucherID(id, voucherId)
    .then(data => {
      dispatch({ type: FETCH_VOUCHER_ITEMS_SUCCESS, payload: data });
    })
    .catch(() => dispatch({ type: FETCH_VOUCHER_ITEMS_FAILED }));
};

const getChallanLinkedToSPList = (voucherType, page, callback) => (dispatch, getState) => {
  const {
    currentCompany: {
      id,
      selectedFinancialYear: { startDate, endDate }
    }
  } = getState();
  switch (voucherType) {
    case VOUCHER_TYPE_LINKED_SCHALLAN:
      return api
        .fetchChallanLinkedSales(id, startDate, endDate, page)
        .then(res => {
          callback(res);
        })
        .catch(error => {
          serverError(error);
          callback({});
        });
    case VOUCHER_TYPE_LINKED_PCHALLAN:
      return api
        .fetchPchallanLinkedPurchase(id, startDate, endDate, page)
        .then(res => {
          callback(res);
        })
        .catch(error => {
          serverError(error);
          callback({});
        });
    case VOUCHER_TYPE_LINKED_PESTIMATION:
      return api
        .fetchPestimationLinkedPurchase(id, startDate, endDate, page)
        .then(res => {
          callback(res);
        })
        .catch(error => {
          serverError(error);
          callback({});
        });
    case VOUCHER_TYPE_LINKED_SESTIMATION:
      return api
        .fetchSestimationLinkedSales(id, startDate, endDate, page)
        .then(res => {
          callback(res);
        })
        .catch(error => {
          serverError(error);
          callback({});
        });
    default:
      callback && callback({});
  }
  callback && callback({});
};

const setChallanListForSPVoucher = challanList => dispatch => {
  dispatch({
    type: SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER,
    payload: challanList
  });
  if (challanList.length > 0 && challanList[0].party) {
    dispatch(
      updateBusiness(
        {
          party: challanList[0].party,
          business: challanList[0].party
        },
        true
      )
    );
    dispatch(fetchAccountsCurrentBalance(challanList[0].party.refAccountId));
  }
};

const handleDeleteChallanFromList = (index, mergeEstimationFlag) => (dispatch, getState) => {
  const {
    vouchers: { challanListForSPVoucher, _selectedBranch }
  } = getState();
  const tempList = challanListForSPVoucher;
  const finalList = tempList.filter((i, k) => k !== index);
  dispatch(setChallanListForSPVoucher(finalList));
  if (mergeEstimationFlag) {
    const voucherIds = finalList.map(list => list.refVoucherId || list._id);
    dispatch(getMergeEstimation(voucherIds, DELETE, '', _selectedBranch.id));
  }
};

const getShareVoucherData = (refId, selectedVoucherId) => (dispatch, getState) => {
  // const companyId = getState().currentCompany.id;
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_SHARE_VOUCHER_DATA_REQUESTED });

  api
    .fetchShareVoucherData(id, refId, selectedVoucherId)
    .then(data => {
      console.log(data);

      dispatch({ type: FETCH_SHARE_VOUCHER_DATA_SUCCESS, payload: data });
    })
    .catch(() => dispatch({ type: FETCH_SHARE_VOUCHER_DATA_FAILED }));
};

const fetchShareVoucherPublicStatus = selectedVoucherId => (dispatch, getState) => {
  // const companyId = getState().currentCompany.id;
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED });

  api
    .fetchPublicShareVoucherData(id, selectedVoucherId)
    .then(response => {
      const data = response.data[response.data.length - 1]; //show last shared link
      dispatch({ type: FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS, payload: data });
    })
    .catch(() => dispatch({ type: FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED }));
};

const handleTransportDone = () => {
  return {
    type: HANDLE_TRANSPORT_DONE
  };
};

// cont...
const updateVoucherRefNumberCard = selectedVoucher => dispatch => {
  const { party } = selectedVoucher;
  dispatch(
    getSingleVoucher(selectedVoucher.id, '', res => {
      dispatch(setSelectedVoucherByNo(res));
      let tempParty = cloneDeep(party);
      tempParty.id = tempParty.refId;
      dispatch(
        updateBusiness({
          party: tempParty,
          business: tempParty
        })
      );
      dispatch(fetchAccountsCurrentBalance(party.refAccountId));
      dispatch(updateVoucherLineItems(selectedVoucher.id));
      // return {
      //   type: UPDATE_SELECTED_VOUCHER,
      //   selectedVoucher
      // };
      dispatch({
        type: 'VOUCHER_REF_NUMBER_UPDATED'
      });
    })
  );
};

const updateVoucherLineItems = voucherId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();

  api
    .fetchVoucherLineItems(id, voucherId)
    .then(response => {
      response &&
        dispatch({
          type: SET_VOUCHER_ITEM_LIST,
          response: response
        });
    })
    .catch(error => serverError(error));
};

const updateSelectedVoucher = input => {
  return {
    type: UPDATE_SELECTED_VOUCHER,
    input
  };
};

export const searchVoucher = (input, type, searchBy = 'voucher') => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (searchBy === 'description') {
    switch (type) {
      case VOUCHER_TYPE_SALES ||
        VOUCHER_TYPE_SCHALLAN ||
        VOUCHER_TYPE_SESTIMATION ||
        VOUCHER_TYPE_LINKED_SESTIMATION ||
        VOUCHER_TYPE_CREDIT_NOTE ||
        VOUCHER_TYPE_DEBIT_NOTE ||
        VOUCHER_TYPE_PURCHASE ||
        VOUCHER_TYPE_PCHALLAN ||
        VOUCHER_TYPE_PESTIMATION ||
        VOUCHER_TYPE_LINKED_PESTIMATION:
        // SPCD voucher contains description in itemList
        query = '&searchBy=itemList&searchByProperty=description';
        break;
      case VOUCHER_TYPE_EXPENSE:
        query = '&searchBy=expenseList&searchByProperty=description';
        break;
      default:
        // these voucher contain description in accountList
        query = '&searchBy=accountList&searchByProperty=description';
        break;
    }
  } else if (searchBy === 'narration') {
    query = '&searchBy=narration';
  }
  console.log(input, type, query);
  return api
    .fetchVouchersSuggestionByNo(id, input, type, query)
    .then(response => {
      return response;
    })
    .catch(error => console.log(error));
};

const updateBusiness = (payload, triggerCalc = true) => (dispatch, getState) => {
  const {
    vouchers: { _lineItems }
  } = getState();
  dispatch({
    type: UPDATE_BUSINESS_CONTACT,
    payload: payload
  });
  payload.party.defaultSalesDiscount &&
    _lineItems.length > 0 &&
    VoucherOptimizePureCal.addSalesDiscount(
      payload.party.salesDiscounts,
      _lineItems,
      payload.party.defaultSalesDiscountValue
    );
  triggerCalc && dispatch(recalculateVoucher(PARTY_SELECT));
  //below calls will be triggered on opening against-voucher modal
  // if (type === VOUCHER_TYPE_PAYMENT || type === VOUCHER_TYPE_RECEIPT) {
  //   //console.log('fetchunpaid');
  //   dispatch(fetchUnpaidVouchers());
  //   dispatch(fetchUnpaidVouchers(true));
  // }
};

/**
 * This function gets called only from view, for populating the feilds inorder to edit
 * the voucher.
 *
 * TODO: check for bill final amout and calculated bill final amount form calculate voucher module
 */
const resetAndSetSPCDVoucherDrawer = (selectedVoucher, id, voucherType) => (dispatch, getState) => {
  const {
    vouchers: { type },
    addEditDrawerFlags: { mode }
  } = getState();
  dispatch(resetVoucher());
  const voucherList = selectedVoucher.voucherIdsWithData || [];
  dispatch({
    type: SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER,
    payload: voucherList
  });
  dispatch(setSPCDDetailsOnCreationDrawer(selectedVoucher, id, mode));

  voucherType = voucherType || type;

  if (
    type === VOUCHER_TYPE_SALES ||
    type === VOUCHER_TYPE_PURCHASE ||
    type === VOUCHER_TYPE_SCHALLAN ||
    type === VOUCHER_TYPE_PCHALLAN ||
    type === VOUCHER_TYPE_SESTIMATION ||
    type === VOUCHER_TYPE_PESTIMATION ||
    type === VOUCHER_TYPE_CREDIT_NOTE ||
    type === VOUCHER_TYPE_DEBIT_NOTE
  ) {
    selectedVoucher.shippingAddress &&
      dispatch(updateShipppingDetails(selectedVoucher.shippingAddress));
    selectedVoucher.transportation &&
      dispatch(updateTransportDetailsForSelectedVoucher(selectedVoucher.transportation));

    selectedVoucher.pickUpLocation &&
      dispatch({
        type: UPDATE_PICKUP_LOCATION_DETAILS,
        payload: selectedVoucher.pickUpLocation
      });
  }

  if (
    voucherType === VOUCHER_TYPE_SALES ||
    voucherType === VOUCHER_TYPE_SCHALLAN ||
    voucherType === VOUCHER_TYPE_SESTIMATION ||
    voucherType === VOUCHER_TYPE_CREDIT_NOTE
  ) {
    selectedVoucher.salesPerson && dispatch(updateSalesPersonDetails(selectedVoucher.salesPerson));
  }
};

const createRPfromSPVoucher = voucher => (dispatch, getState) => {
  const {
    vouchers: { _issueDateObj }
  } = getState();
  const transaction = find(
    voucher.transactions,
    transaction => transaction.refAccountId === voucher.party.refAccountId
  );
  const accountList = [
    {
      refAccountId: voucher.party.refAccountId,
      name: voucher.party.name,
      refAccountName: voucher.party.name,
      creditAmount: transaction.creditAmount,
      debitAmount: transaction.debitAmount,
      amount: transaction.paidStatus.dueAmount,
      refPath: voucher.party.refPath,
      adjustmentMethod: AGAINST_VOUCHER,
      narration: ''
    }
  ];
  const voucherList = [
    {
      refVoucherId: voucher.id,
      refAccountId: voucher.party.refAccountId,
      voucherNo: voucher.voucherNo,
      refVoucherType: voucher.type,
      amount: transaction.paidStatus.dueAmount,
      date: voucher.issueDate,
      isDebit: getVoucherTransactionIsDebit(voucher.transactions, voucher.party.refAccountId)
    }
  ];

  let rpVoucher = {
    // selectedVouchers: tempSelections,
    billFinalAmount: transaction.paidStatus.dueAmount,
    iBranchId: voucher.iBranchId,
    accountList: accountList,
    voucherList: voucherList,
    // narration: voucher.narration,
    // paymentMethod: 'cash', // hard coded payment method for demo
    // adjustmentMethod: 'Against Voucher',
    internalNotes: voucher.internalNotes,
    issueDate: _issueDateObj,
    verifiedBy: '',
    voucherNo: '',
    transaction: {
      receivedAmount: 0,
      transactionType: '',
      transactionRefNo: '',
      date: getFormattedDate3(new Date()),
      receivedFrom: ''
    },
    onAccount: {
      type: 'string',
      narration: '',
      amount: ''
    }
  };
  dispatch(setRPVoucherDetailsOnCreationDrawer(rpVoucher, ''));
};

const setRPVoucherDetailsOnCreationDrawer = (selectedVoucher, id) => (dispatch, getState) => {
  const {
    vouchers: { _branches, type },
    currentCompany: { currencyDecimals, itemQtyDecimals },
    vendorAccountsDataList = []
  } = getState();
  const party = selectedVoucher.party
    ? vendorAccountsDataList.find(
        d => d.id === selectedVoucher.party.refId || d._id === selectedVoucher.party.refId
      )
    : {};
  const selectedBranch = _branches.find(branch => selectedVoucher.iBranchId === branch.id);
  const partyGstBusinessTypeRef = getPartyGstBusinessTypeRef(
    type,
    party,
    _branches,
    selectedBranch
  );
  dispatch(resetVoucher());
  let tempItemList = selectedVoucher.itemList;

  dispatch(updateSelectedVoucher(selectedVoucher));
  dispatch(updateDate(null, getFormattedDateIso(selectedVoucher.issueDate)));
  dispatch({ type: SET_SELECTED_VOUCHER_ID, id });
  dispatch({ type: UPDATE_NARRATION, payload: selectedVoucher.narration });
  dispatch({ type: SET_SELECTED_VOUCHER_NO, voucherNo: selectedVoucher.voucherNo });

  selectedVoucher.subType === PARTY &&
    dispatch(
      updateBusiness(
        {
          business: {
            ...party
          },
          party: party
        },
        false
      )
    );
  selectedVoucher.subType === PARTY &&
    dispatch(fetchAccountsCurrentBalance(selectedVoucher.party.refAccountId));
  dispatch(
    setSupplierInvoiceDate(selectedVoucher.supplierInvoiceDate || selectedVoucher.issueDate)
  );
  dispatch(setDueDate(getFormattedDateWithTime(selectedVoucher.dueDate)));
  dispatch(setSupplierInvoiceNo(selectedVoucher.supplierInvoiceNo));
  dispatch(
    fetchCompanyBranch('', { type: VOUCHER_BRANCH_POPULATE, branchId: selectedVoucher.iBranchId })
  );
  dispatch(updateVouchersDetailsAccount(selectedVoucher.refAccountName, selectedVoucher.type));
  dispatch(
    fetchReceiptPaymentAccounts({
      type: VOUCHERS_DETAILS_ACCOUNTS_POPULATE,
      accountId: selectedVoucher.refAccountId
    })
  );
  dispatch({
    type: SET_VOUCHER_UPLOADED_DOCUMENTS,
    item: selectedVoucher.documents
  });

  initVoucherCalc({
    voucherId: id,
    itemList: tempItemList,
    additionalDiscount: selectedVoucher.additionalDiscount,
    hasExclusiveDiscount: selectedVoucher.hasExclusiveDiscount,
    salesDiscount: selectedVoucher.salesDiscounts || [],
    couponDiscount: selectedVoucher.couponDiscount,
    otherCharges: selectedVoucher.otherCharges || [],
    partyId: party.id || party._id,
    partyGstBusinessTypeRef: partyGstBusinessTypeRef,
    otherChargesAfterTax: selectedVoucher.otherChargesAfterTax || [],
    adjustments: selectedVoucher.adjustments || 0,
    partyState: party.state,
    branchState: selectedBranch.state,
    currencyDecimals,
    itemQtyDecimals
  });
  dispatch(handleVoucherDetaislDone(true));
};

const setSPCDDetailsOnCreationDrawer = (selectedVoucher, id, mode = EDIT) => (
  dispatch,
  getState
) => {
  const {
    vouchers: {
      _branches,
      type: voucherType,
      selectedPartyAccount: {
        defaultSalesDiscount,
        defaultSalesDiscountValue,
        defaultPurchaseDiscount,
        defaultPurchaseDiscountValue
      }
    },
    currentCompany: { currencyDecimals, itemQtyDecimals },
    contacts: {
      voucherViewMode: { duplicateVoucherType }
    }
  } = getState();
  const {
    voucherNo,
    couponDiscount,
    party,
    iBranchId,
    refDestinationBranchId,
    itemList = [],
    lineAmountSum,
    billItemsPrice,
    discountBreakup = {},
    narration,
    salesDiscounts,
    supplierInvoiceDate,
    dueDate,
    supplierInvoiceNo,
    issueDate,
    refAccountName,
    additionalDiscount,
    otherCharges = [],
    otherChargesAfterTax = [],
    otherChargesTotal,
    otherChargesAfterTaxTotal,
    billFinalAmount,
    billTaxAmount,
    billDiscountAmount,
    dueAmount,
    tax,
    documents,
    adjustments,
    expenseList,
    hasExclusiveDiscount: exclusiveDiscountFlag,
    subType,
    billAmountBeforeTax,
    purchaseDiscounts
  } = selectedVoucher;
  let type = duplicateVoucherType ? duplicateVoucherType : voucherType;

  if (
    voucherType === VOUCHER_TYPE_PURCHASE &&
    (selectedVoucher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
      selectedVoucher.type === VOUCHER_TYPE_LINKED_PCHALLAN)
  ) {
    type = selectedVoucher.type;
  } else if (
    voucherType === VOUCHER_TYPE_SALES &&
    (selectedVoucher.type === VOUCHER_TYPE_LINKED_SCHALLAN ||
      selectedVoucher.type === VOUCHER_TYPE_LINKED_SESTIMATION)
  ) {
    type = selectedVoucher.type;
  }

  if ((!party || !party.refId) && !refDestinationBranchId && !subType)
    return dispatch(openSnackbar('Party not Found/ It might be deleted !!!!', 'error'));
  let hasExclusiveDiscount = exclusiveDiscountFlag;
  if (
    duplicateVoucherType &&
    ((purchaseTypeFlag(duplicateVoucherType) && salesTypeFlag(voucherType)) ||
      (purchaseTypeFlag(voucherType) && salesTypeFlag(duplicateVoucherType)))
  ) {
    // in case of duplicating voucher this will set to false when duplicate from and selected vouchertype is different
    hasExclusiveDiscount = false;
  }
  const triggerCalculationFlag = false;
  const selectedBranch = _branches.find(branch => iBranchId === branch.id) || {};
  const selectedDestinationBranch = _branches.find(branch => refDestinationBranchId === branch.id);
  const partyGstBusinessTypeRef = getPartyGstBusinessTypeRef(
    type,
    party,
    _branches,
    selectedBranch
  );
  let tempItemList = itemList;
  let tempLineAmountSum = 0;
  lineAmountSum //for old vouchers if lineAmountSum not avaliable
    ? (tempLineAmountSum = lineAmountSum)
    : (tempLineAmountSum = billItemsPrice - discountBreakup.lineDiscountSumAmount);
  // dispatch({
  //   type: FETCH_VOUCHERS_DATA_SUCCESS
  // });
  let partySalesDiscount = defaultSalesDiscount
    ? VoucherOptimizePureCal.addSalesDiscount(salesDiscounts, itemList, defaultSalesDiscountValue)
    : salesDiscounts
    ? [...salesDiscounts]
    : [];

  let partyPurchaseDiscount = defaultPurchaseDiscount
    ? VoucherOptimizePureCal.addPurchaseDiscount(
        purchaseDiscounts,
        itemList,
        defaultPurchaseDiscountValue
      )
    : purchaseDiscounts
    ? [...purchaseDiscounts]
    : [];

  let tempOtherCharge = otherCharges.map(charge => {
    return {
      ...charge,
      value: charge.value ? charge.value : charge.amount,
      unit: charge.unit ? charge.unit : RUPEES,
      method: charge.method ? charge.method : ADD_METHOD
    };
  });

  let tempOtherChargeAfterTax = otherChargesAfterTax.map(charge => {
    return {
      ...charge,
      value: charge.value ? charge.value : charge.amount,
      unit: charge.unit ? charge.unit : RUPEES,
      method: charge.method ? charge.method : ADD_METHOD
    };
  });

  dispatch({ type: ON_HIDE_SHOW_LINK });
  dispatch({ type: SET_SELECTED_VOUCHER_ID, id });
  dispatch({ type: UPDATE_NARRATION, payload: narration });
  dispatch({ type: SET_SELECTED_VOUCHER_NO, voucherNo: mode === EDIT ? voucherNo : '' });
  dispatch({
    type: UPDATE_BUSINESS_CONTACT,
    payload: {
      business: {
        ...party
      },
      party: party
    }
  });
  party && dispatch(fetchAccountsCurrentBalance(party.refAccountId));
  party && dispatch(fetchPartyProfile(party.refId, 'partyProfile'));
  // dispatch(getPartySalesDiscount(party.refId, selectedVoucher));
  couponDiscount &&
    dispatch(
      setCouponDiscount(couponDiscount.value, couponDiscount.unit, '', triggerCalculationFlag)
    );
  dispatch({ type: SET_PARTY_SALES_DISCOUNT, payload: partySalesDiscount || [] });
  dispatch({ type: SET_PARTY_PURCHASE_DISCOUNT, payload: partyPurchaseDiscount || [] });
  dispatch(updateDate(null, getFormattedDateIso(issueDate)));
  dispatch(setSupplierInvoiceDate(supplierInvoiceDate));
  dispatch(setDueDate(getFormattedDateIso(dueDate)));
  dispatch(setSupplierInvoiceNo(supplierInvoiceNo));
  dispatch(onUpdatebranchSearch(selectedBranch.name));
  dispatch(onUpdateDestinationBranch(selectedDestinationBranch));
  dispatch(onUpdateInwardOutwardSubtype(subType));
  subType === ADJUSTMENT_ON_ITEM_PRICE && dispatch(setCRDRSubType(subType));
  dispatch(updateVouchersDetailsAccount(refAccountName, type));
  dispatch(updateSelectedVoucher(selectedVoucher));

  dispatch(handleVoucherDetaislDone(true, triggerCalculationFlag));
  dispatch(resetAddDiscount()); // toggle addDiscount in 'edit mode'
  dispatch(populateAdditionalDiscount(additionalDiscount));
  dispatch(populateOtherChargesObject(tempOtherCharge));
  dispatch(populateOtherChargesAfterTax(tempOtherChargeAfterTax));
  dispatch(populateOtherChargesTotal(otherChargesTotal));
  dispatch(populateOtherChargesAfterTaxTotal(otherChargesAfterTaxTotal));
  dispatch(populateLineAmountSum(tempLineAmountSum));
  dispatch(populateRoundOffValue(billFinalAmount));
  dispatch(populateBillFinalAmount(billFinalAmount));
  dispatch(populateBillTaxAmount(billTaxAmount));
  dispatch(populateBillItemsPrice(billItemsPrice));
  dispatch(populateBillDiscountAmount(billDiscountAmount));
  dispatch(populateDueAmount(dueAmount));
  dispatch(populateAdditionalDiscountAmount(discountBreakup.additionalDiscountSumAmount));
  dispatch(populateBillTax(tax));
  dispatch({
    type: SET_VOUCHER_UPLOADED_DOCUMENTS,
    item: documents
  });
  dispatch(buildGrpCodeMapAndCount(tempItemList));
  dispatch({ type: SET_ROUND_OFF_OFFSET, value: adjustments || 0 });

  if (type === VOUCHER_TYPE_EXPENSE) {
    tempItemList = VoucherOptimizePureCal.mapExpenseListToLineList(expenseList);
  }

  dispatch({ type: ON_TOGGLE_EXCLUSIVE_DISCOUNT, flag: hasExclusiveDiscount });
  hasExclusiveDiscount && dispatch(setExclusiveDiscountData(selectedVoucher));

  const initParams = {
    voucherId: id,
    itemList: tempItemList,
    additionalDiscount: additionalDiscount,
    hasExclusiveDiscount: hasExclusiveDiscount,
    salesDiscount: salesDiscounts || [],
    couponDiscount: couponDiscount,
    otherCharges: otherCharges || [],
    partyId: party ? party.refId : refDestinationBranchId ? refDestinationBranchId : null,
    partyGstBusinessTypeRef: partyGstBusinessTypeRef,
    otherChargesAfterTax: otherChargesAfterTax || [],
    adjustments: adjustments || 0,
    partyState: party
      ? party.state
      : selectedDestinationBranch
      ? selectedDestinationBranch.state
      : null,
    branchState: selectedBranch.state,
    currencyDecimals,
    itemQtyDecimals
  };
  dispatch(populateLintItemForEditMode(tempItemList));
  duplicateVoucherType ? dispatch(recalculateVoucher(RECALCULATE)) : initVoucherCalc(initParams);

  //For credit and debit there are two voucher which we have to keep in mind
  //first ref voucher associated with the credit voucher saved in '_selectedVoucherByNo'
  //credit or debit voucher is saved in 'selectedVoucher'

  //calculate otherChargesAfterTaxTotal if not avaliable in payload
  !otherChargesAfterTaxTotal && getOtherChargesAfterTax(id, otherChargesAfterTax, dispatch);

  // if (type === VOUCHER_TYPE_CREDIT_NOTE || type === VOUCHER_TYPE_DEBIT_NOTE) {
  //   dispatch(updateVoucherRefNumberCard(_selectedVoucherByNo)); // cont...
  // }
  dispatch(populateRoundOffArray(billAmountBeforeTax, billTaxAmount, otherChargesAfterTaxTotal));
  // dispatch({
  //   type: FETCH_VOUCHERS_DATA_SUCCESS
  // });
  dispatch(populateItemQuantities(tempItemList));
};

const populateItemQuantities = itemList => dispatch => {
  const qtys = reduce(
    itemList,
    (prev, curr) => {
      if (prev[curr.itemId]) {
        prev[curr.itemId] += curr.qty;
      } else {
        prev[curr.itemId] = curr.qty;
      }
      return prev;
    },
    {}
  );
  dispatch({ type: POPULATE_ITEM_QTYS_TOTAL, qtys });
};

/**
 * ction to be fired from 'edit mode' to populate other charges
 * @param {otherChragesArray} otherChragesArray is array to be populated for other
 */

const populateRoundOffArray = (
  billAmountBeforeTax,
  billTaxAmount,
  otherChargesAfterTaxTotal
) => dispatch => {
  const billAmountBeforeRound =
    billAmountBeforeTax + billTaxAmount + (otherChargesAfterTaxTotal || 0);
  const roundOffArray = VoucherOptimizePureCal.getRoundOffAddjustmentValuesByTotal(
    billAmountBeforeRound
  );
  dispatch({ type: GET_AUTO_POPULATE_ROUNDOFF_DATA, payload: roundOffArray });
};
const populateOtherChargesObject = otherChragesArray => ({
  type: POPULATE_OTHER_CHARGES,
  otherChragesArray
});

const populateOtherChargesAfterTax = otherChargesAfterTax => ({
  type: POPULATE_OTHER_CHARGES_AFTER_TAX,
  otherChargesAfterTax
});

const populateOtherChargesTotal = otherChargesTotal => ({
  type: POPULATE_OTHER_CHARGES_TOTAL,
  otherChargesTotal
});

const populateOtherChargesAfterTaxTotal = otherChargesAfterTaxTotal => ({
  type: POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL,
  otherChargesAfterTaxTotal
});

const populateLineAmountSum = lineAmountSum => ({
  type: POPULATE_LINE_AMOUNT_SUM,
  lineAmountSum
});

const populateBillFinalAmount = billFinalAmount => dispatch => {
  dispatch({ type: POPULATE_BILL_FINAL_AMOUNT, billFinalAmount });
};
const populateBillTaxAmount = billTaxAmount => dispatch => {
  dispatch({ type: POPULATE_BILL_TAX_AMOUNT, billTaxAmount });
};
const populateBillItemsPrice = billItemsPrice => dispatch => {
  dispatch({ type: POPULATE_BILL_ITEMS_PRICE, billItemsPrice });
};
const populateBillDiscountAmount = billDiscountAmount => dispatch => {
  dispatch({ type: POPULATE_BILL_DISCOUNT_AMOUNT, billDiscountAmount });
};
const populateAdditionalDiscountAmount = additionalDiscountCurrencyAmount => dispatch => {
  dispatch({ type: POPULATE_ADDITIONAL_DISCOUNT_AMOUNT, additionalDiscountCurrencyAmount });
};
const populateDueAmount = dueAmount => dispatch => {
  dispatch({ type: POPULATE_DUE_AMOUNT, dueAmount });
};
const populateBillTax = tax => dispatch => {
  dispatch({ type: POPULATE_BILL_TAX, tax });
};

const buildGrpCodeMapAndCount = lineItems => dispatch => {
  let groupToCodeMap = {};
  let groupCount = {};
  for (const item of lineItems) {
    if (item.srNoByGroup) {
      const currentITemCode = item.srNoByGroup.split('-')[0];
      const currentCodeCount = parseInt(item.srNoByGroup.split('-')[1], 10);
      groupToCodeMap[item.itemGroupId] = currentITemCode;
      groupCount[currentITemCode] =
        !groupCount[currentITemCode] || currentCodeCount > groupCount[currentITemCode]
          ? currentCodeCount
          : groupCount[currentITemCode];
    }
  }

  dispatch({ type: UPDATE_GROUP_WISE_ITEM_COUNT, payload: groupCount || {} });
  dispatch({ type: UPDATE_GROUP_TO_CODE_MAP, groupToCodeMap });
};

const updateGrpCodeMapAndCount = grpMap => (dispatch, getState) => {
  const {
    vouchers: { _lineItems }
  } = getState();
  let groupCount = {};
  const updatedLineItems = map(_lineItems, item => {
    const currentItemSrCode = grpMap[item.itemGroupId];
    groupCount[currentItemSrCode] = groupCount[currentItemSrCode]
      ? groupCount[currentItemSrCode] + 1
      : 1;
    return {
      ...item,
      srNoByGroup: `${currentItemSrCode}-${groupCount[currentItemSrCode]}`
    };
  });
  dispatch({ type: UPDATE_GROUP_TO_CODE_MAP, groupToCodeMap: grpMap });
  dispatch({ type: UPDATE_GROUP_WISE_ITEM_COUNT, payload: groupCount });
  dispatch({ type: UPDATE_LINE_ITEM_SR_NOS, updatedLineItems });
};

const populateRoundOffValue = roundOffValue => ({
  type: POPULATE_ROUNDOFF_VALUE,
  roundOffValue
});

/**
 * action to be fired from 'edit mode' to populate additional discount
 * @param {additionalDiscount} additionalDiscount is the value to populate in addtional discount
 */
const populateAdditionalDiscount = additionalDiscount => ({
  type: POPULATE_ADDTIONAL_DISCOUNT,
  additionalDiscount
});

// action to be fired from 'edit mode'
export const populateLintItemForEditMode = lineItems => (dispatch, getState) => {
  const {
    settings: { voucherSettings }
  } = getState();
  dispatch({
    type: ADD_LINE_ITEM,
    edit: true,
    lineItems,
    voucherSettings
  });
  dispatch({ type: STORE_OLD_LINE_ITEM, index: 0 });
};
const updateShipppingDetails = address => {
  return {
    type: UPDATE_SHIPPING_FOR_SELECTED_VOUCHER,
    address
  };
};

const updateTransportDetailsForSelectedVoucher = address => {
  return {
    type: UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER,
    address
  };
};

const showShippingDetails = () => {
  return {
    type: TOGGLE_SHIPPING_DETAILS_LINK
  };
};
//logic for fetching salse and purchase voucher which will populate in receipt and payment vouchers
// type is defined here and not send from top cause actions are dispatched in a chain and this actions is being dispatched from another actions.
const fetchUnpaidVouchers = (
  accountId,
  searchText = '',
  getAllvouchers,
  pageNo = 1,
  endDueDate
) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  dispatch({
    type: FETCH_UNPAID_VOUCHERS_REQUESTED,
    key: getAllvouchers ? '_allVoucherList' : '_unpaidVoucherList'
  });
  let query = getAllvouchers ? 'status=all&hitsPerPage=5' : 'status=unsettled&hitsPerPage=5';
  if (searchText) {
    query += `&searchText=${searchText}`;
  }
  if (endDueDate) {
    query += `&endDueDate=${getFormattedDateIso(endDueDate)}`;
  }
  accountId &&
    api
      .fetchUnpaidVoucher(accountId, companyId, pageNo, query)
      .then(response => {
        if (getAllvouchers)
          dispatch({ type: FETCH_UNPAID_VOUCHERS_SUCCESS, response, key: '_allVoucherList' });
        else dispatch({ type: FETCH_UNPAID_VOUCHERS_SUCCESS, response, key: '_unpaidVoucherList' });
      })
      .catch(error => dispatch({ type: FETCH_UNPAID_VOUCHERS_FAILURE, error, key: 'error' }));
};

const clearUnpaidVouchers = () => {
  return { type: CLEAR_UNPAID_VOUCHERS, key: '_unpaidVoucherList' };
};
const updateVouchers = payload => {
  return {
    type: UPDATE_VOUCHERS,
    payload: payload
  };
};

const setVoucherDurationType = payload => {
  return {
    type: VOUCHER_DURATION_TYPE,
    payload: payload
  };
};

const updateVoucherTypes = payload => {
  return {
    type: UPDATE_VOUCHER_TYPES,
    payload: payload
  };
};

const setVoucherType = payload => {
  return {
    type: SET_VOUCHER_TYPE,
    payload: payload
  };
};

const setFilterDateRange = (startDate, endDate) => {
  return {
    type: VOUCHER_DATE_RANGE,
    date: {
      startDate: startDate,
      endDate: endDate
    }
  };
};

const setFilterDate = (date, key) => {
  return { type: VOUCHER_DATE, date, key };
};

const hideShippingDetailsLink = payload => ({ type: HIDE_SHIPPING_DETAILS, payload });
const resetAddDiscount = () => ({
  type: RESET_ADD_DISCOUNT
});

const updateTransportDetails = (field, value) => {
  return {
    type: UPDATE_TRANSPORT_DETAILS,

    details: {
      [field]: value
    }
  };
};

const updateSalesPersonDetails = payload => {
  return {
    type: UPDATE_SALES_PERSON_DETAILS,
    payload
  };
};

const updatePickUpLocationDetails = payload => {
  return {
    type: UPDATE_PICKUP_LOCATION_DETAILS,
    payload
  };
};

const updateDate = (event, date) => {
  // let tempDate = formatDate(date);
  return {
    type: UPDATE_DATE,
    payload: {
      date: date
      // tempDate: tempDate
    }
  };
};
const togglePurchaseSalesCreationDrawer = () => ({
  type: TOGGLE_PURCHASE_SALES_DRAWER
});

export const handleEditVoucherId = id => ({
  type: SET_EDIT_VOUCHER_ID,
  editVoucherId: id
});

const toggleAddEditDrawer = (key, action, mode = CREATE) => (dispatch, getState) => {
  const {
    currentCompany: { name, currencyDecimals, itemQtyDecimals },
    vouchers: {
      purchase: { additionalDiscount },
      type,
      _lineItems,
      _otherCharges,
      _branches,
      _selectedBranch,
      _otherChargesAfterTax,
      _roundOffOffset,
      selectedPartyAccount,
      selectedPartyAccount: { salesDiscounts, id: partyId, state: partyState },
      couponDiscount,
      app: { exclusiveDiscountFlag: hasExclusiveDiscount },
      lastSavedVoucherDates,
      posBilling,
      posBillingParty,
      lastSavedVoucherPickupLocation,
      lastSavedVoucherTransport
    },
    router: {
      location: { pathname }
    }
  } = getState();
  mixpanel.track(`save-edit-drawer-toggle-${key}`, {
    key: key,
    flag: action,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch({ type: SET_DRAWER_MODE, mode });

  if (action && mode !== EDIT) {
    //initialize voucher calc npm for new voucher
    //edit mode is initialised in setSPCDDetailsOnCreationDrawer
    const initParams = {
      voucherId: NEW,
      itemList: _lineItems || [],
      additionalDiscount: additionalDiscount || 0,
      hasExclusiveDiscount: hasExclusiveDiscount,
      salesDiscount: salesDiscounts || [],
      couponDiscount: couponDiscount || 0,
      otherCharges: _otherCharges,
      partyId: partyId || '',
      partyGstBusinessTypeRef:
        getPartyGstBusinessTypeRef(type, selectedPartyAccount, _branches, _selectedBranch) || '',
      otherChargesAfterTax: _otherChargesAfterTax,
      adjustments: _roundOffOffset || 0,
      partyState: partyState,
      branchState: _selectedBranch.state,
      currencyDecimals,
      itemQtyDecimals
    };
    initVoucherCalc(initParams);
    // if last saved voucher dates are present only for new vouchers
    lastSavedVoucherDates &&
      lastSavedVoucherDates.issueDate &&
      dispatch({
        type: UPDATE_DATE,
        payload: { date: getFormattedDateWithTime(lastSavedVoucherDates.issueDate) }
      });
    lastSavedVoucherDates &&
      lastSavedVoucherDates.supplierInvoiceDate &&
      dispatch({
        type: SET_SUPPLIER_INVOICE_DATE,
        payload: lastSavedVoucherDates.supplierInvoiceDate
      });

    if (lastSavedVoucherPickupLocation && lastSavedVoucherPickupLocation.state) {
      dispatch({
        type: UPDATE_PICKUP_LOCATION_DETAILS,
        payload: lastSavedVoucherPickupLocation
      });
    }

    if (lastSavedVoucherTransport && lastSavedVoucherTransport.transporterId) {
      dispatch(handleTransportDone());
      dispatch({
        type: UPDATE_TRANSPORT_DETAILS,
        details: lastSavedVoucherTransport
      });
      dispatch({ type: TOGGLE_TRANSPORT_LINK });
    }
  }
  dispatch({ type: TOGGLE_ADD_EDIT_DRAWER, key: key, value: action });
  posBilling && dispatch(handlePosBillingDrawer());
  !isEmpty(posBillingParty) && dispatch({ type: POS_BILLING_PARTY, payload: {} });
};

const toggleExclusiveDiscountModal = action => dispatch =>
  dispatch({ type: TOGGLE_GROUPWISE_DISCOUNT_MODAL, value: action });

const toggleEditExclusiveDiscountModal = action => dispatch =>
  dispatch({ type: TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL, value: action });

const deleteLineItem = key => (dispatch, getState) => {
  dispatch({ type: STORE_OLD_LINE_ITEM, index: key });

  dispatch({
    type: DELETE_LINE_ITEM,
    key
  });

  dispatch({
    type: UPDATE_TAX_BRACKUP,
    payload: {
      taxBreakup: [],
      totalTax: ''
    }
  });

  dispatch(setRoundOffOffset(0, true)); //send false to prevent calling handleItemCalculations second time
  handleItemCalculations(dispatch, getState, DELETE_LINE);
  // dispatch(openSnackbar('Item Removed Successfully'));
  // dispatch({ type: RESET_OLD_LINE_ITEM });
};

const replaceLineItem = (item, index) => (dispatch, getState) => {
  const {
    vouchers: { _lineItems },
    vouchers,
    currentCompany: { companyVoucherSettings }
  } = getState();
  const replacedItem = cloneDeep(_lineItems)[index];
  dispatch(deleteLineItem(index));
  let untPrice = getUnitPriceForPayload(vouchers, item, companyVoucherSettings);
  if (untPrice !== 0) {
    dispatch(
      updateLineItem(
        {
          ...item,
          qty: replacedItem.qty,
          unitPrice: untPrice
        },
        index,
        replacedItem
      )
    );
  } else {
    dispatch(
      updateLineItem(
        {
          ...item,
          qty: replacedItem.qty,
          unitPrice: replacedItem.unitPrice,
          discountValue: replacedItem.discountValue,
          discountUnit: replacedItem.discountUnit,
          unitPurchasePrice: replacedItem.unitPurchasePrice,
          unitSellWholeSalePrice: replacedItem.unitSellWholeSalePrice,
          unitSellRetailPrice: replacedItem.unitSellRetailPrice,
          taxPercentage: replacedItem.taxPercentage,
          wholesaleMarkup: replacedItem.wholesaleMarkup,
          retailMarkup: replacedItem.retailMarkup
        },
        index,
        replacedItem
      )
    );
  }
};

const fetchSalesAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchSalesAccounts(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchPurchasesAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchPurchasesAccounts(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchReceiptPaymentAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  Promise.all([api.fetchCashInHandAccounts(id), api.fetchBankAccounts(id)])
    .then(function([cashInHand, bankAccounts]) {
      let response = [];
      if (cashInHand != null) {
        response = cashInHand.concat(bankAccounts);
      }
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchPurchasesChallanAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchPurchasesChallan(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
      response.length > 0 && dispatch(updateVouchersDetailsAccount(response[0].name, voucherType));
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchPurchasesEstimationAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchPurchasesEstimation(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
      response.length > 0 && dispatch(updateVouchersDetailsAccount(response[0].name, voucherType));
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchSalesChallanAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchSalesChallan(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
      response.length > 0 && dispatch(updateVouchersDetailsAccount(response[0].name, voucherType));
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const fetchSalesEstimationAccounts = voucherType => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_ACCOUNTS_REQUESTED });
  api
    .fetchSalesEstimation(id)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, response, voucherType });
      response.length > 0 && dispatch(updateVouchersDetailsAccount(response[0].name, voucherType));
    })
    .catch(error => dispatch({ type: FETCH_ACCOUNTS_FAILED, error }));
};

const updateVouchersDetailsAccount = (input, voucherType) => {
  return {
    type: UPDATE_VOUCHERS_DETAILS_ACCOUNTS,
    input,
    voucherType
  };
};

const setShippingAddress = (address = {}) => ({
  type: SET_SHIPPING_ADDRESS,
  address
});

const closeBusinessContactCard = () => dispatch => {
  dispatch({ type: CLOSE_BUSINESS_CARD });
  dispatch({ type: SET_PARTY_SALES_DISCOUNT, payload: [] });
};

/**
 * this function resets all the add voucher state
 * this is being called from vouchers.js and and post success response form
 * the voucher.
 */
const resetVoucher = () => (dispatch, getState) => {
  const {
    vouchers: { _selectedVoucherId }
  } = getState();
  dispatch(resetVoucherLineItems());
  dispatch(closeBusinessContactCard());
  dispatch(resetShippingDetails());
  dispatch(resetVoucherDetails());
  dispatch(resetTransportDetails());
  dispatch(setActionSaveButtonDisable(false));
  console.log('here', _selectedVoucherId);
  resetVoucherCalc(_selectedVoucherId);
  dispatch({ type: RESET_VOUCHER_FORM });
  dispatch({ type: RESET_SORTING_COLUMN });
  // dispatch({ type: ON_CLOSE_VOUCHER_PICKER });
  dispatch(resetAttributesFilter());
};
//reset voucher actions in reducers
const resetVoucherLineItems = () => ({
  type: RESET_VOUCHER_LINE_ITEMS
});
const resetShippingDetails = () => {
  return {
    type: RESET_SHIPPING_DETAILS
  };
};
const resetVoucherDetails = () => {
  return {
    type: RESET_VOUCHER_DETAILS
  };
};

const resetTransportDetails = () => {
  return {
    type: RESET_TRANSPORT_DETAILS
  };
};
const resetLineItems = () => dispatch => {
  dispatch(resetVoucherLineItems());
  dispatch(recalculateVoucher(RECALCULATE));
};

// const resetAddtionalDiscount = () => {
//   return {
//     type: RESET_ADDTIONAL_DISCOUNT
//   };
// };

//end
// reset actions for line items,
const onCloseVoucherPicker = () => dispatch => {
  dispatch({ type: ON_CLOSE_VOUCHER_PICKER });
  dispatch(resetVoucherLineItems());
};
const fetchCurrentBalance = selectedBusinessAccountId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({
    type: GET_CURRENT_BALANCE_REQUESTED
  });
  api
    .currentBalance(id, selectedBusinessAccountId)
    .then(response => {
      dispatch({ type: GET_CURRENT_BALANCE_SUCCESS, response: response.currentBalance });
    })
    .catch(err => {
      dispatch({
        type: GET_CURRENT_BALANCE_FAILED
      });
      serverError(err);
    });
};

const updateBusinessContact = input => ({
  type: UPDATE_PARTY_NAME,
  input
});

//Removed this API , similar API is present in companies action

// const fetchBranches = callback => (dispatch, getState) => {
//   const {
//     currentCompany: { id }
//   } = getState();
//   dispatch({ type: FETCH_ALL_BRANCHES_REQUESTED });
//   api
//     .fetchBranch(id)
//     .then(response => {
//       dispatch({ type: FETCH_ALL_BRANCHES_SUCCESS, response });
//       if (callback) dispatch(callback);
//     })
//     .catch(() => {
//       dispatch({ type: FETCH_ALL_BRANCHES_FAILED });
//     });
// };

const updateLineItemNameCrDr = input => (dispatch, getState) => {
  const {
    vouchers: { _lineItems }
  } = getState();
  const selectedItems = input;
  if (selectedItems) {
    dispatch({ type: RESET_OLD_LINE_ITEM });
    dispatch({
      type: ADD_LINE_ITEM_CRDR,
      selectedItems
    });

    handleItemCalculations(dispatch, getState, NEW_LINE, 0, _lineItems.length);
    dispatch(setRoundOffOffset(0, false));
  }
};

const updateLineItemName = input => dispatch => {
  dispatch({
    type: SET_LINE_ITEM_SEARCH_TEXT,
    text: input
  });
};

const updateLineItem = (input, index, replacedItem) => (dispatch, getState) => {
  const {
    vendorAccountsDataList = [],
    items: { itemGroups },
    vouchers: {
      selectedPartyAccount,
      selectedPartyAccount: {
        salesDiscounts = [],
        defaultSalesDiscount = false,
        defaultSalesDiscountValue = {},
        purchaseDiscounts = [],
        defaultPurchaseDiscount = false,
        defaultPurchaseDiscountValue = {}
      },
      type,
      _lineItems = []
    },
    settings: { voucherSettings }
  } = getState();
  if (_lineItems.length >= 750) {
    dispatch(openSnackbar('You can add upto 750 item in voucher', ERROR));
    return false;
  }
  const selectedItems = input;
  let tempPurchaseDiscount = [...purchaseDiscounts];

  if (selectedItems) {
    let templSalesDiscount = [...salesDiscounts];
    if (defaultSalesDiscount && input) {
      templSalesDiscount = VoucherOptimizePureCal.defaultSalesDiscountCheck(
        templSalesDiscount,
        defaultSalesDiscountValue,
        input
      );
      dispatch({ type: SET_PARTY_SALES_DISCOUNT, payload: templSalesDiscount });
    }

    if (defaultPurchaseDiscount && input) {
      tempPurchaseDiscount = VoucherOptimizePureCal.defaultPurchaseDiscountCheck(
        tempPurchaseDiscount,
        defaultPurchaseDiscountValue,
        input
      );

      dispatch({ type: SET_PARTY_PURCHASE_DISCOUNT, payload: tempPurchaseDiscount });
    }

    const { taxPercentage, hsn, isService = false } = getTaxPercentageAndHsn(
      itemGroups,
      selectedItems.itemGroupId
    );
    selectedItems.taxPercentage = taxPercentage;
    selectedItems.hsn = hsn;
    selectedItems.isService = isService;

    if (_lineItems.length) {
      selectedItems.boxNo = _lineItems[_lineItems.length - 1].boxNo || 0;
    }
    dispatch({ type: RESET_OLD_LINE_ITEM });
    dispatch({
      type: ADD_LINE_ITEM,
      payload: selectedItems,
      index,
      replacedItem,
      loadItemQty: true,
      voucherSettings
    });
    dispatch(resetSortingColumn());
    handleItemCalculations(dispatch, getState, NEW_LINE, 0, index);
    if (
      selectedItems.partyId &&
      (type === VOUCHER_TYPE_PURCHASE ||
        type === VOUCHER_TYPE_PCHALLAN ||
        type === VOUCHER_TYPE_PESTIMATION)
    ) {
      if (Object.keys(selectedPartyAccount).length === 0) {
        const party = vendorAccountsDataList.find(
          party => party.id === selectedItems.partyId || party._id === selectedItems.partyId
        );
        dispatch(
          updateBusiness({
            party: party,
            business: party
          })
        );
        party && dispatch(fetchAccountsCurrentBalance(party.refAccountId));
        party && dispatch(setItemProperty('party', { ...party, id: party.id || party._id }));
        !party && dispatch(openSnackbar('Party not Found/ It might be deleted !!!!', 'error'));
      }
    }
  } else {
    dispatch(updateLineItemName(input.name));
  }
};

const refreshLineItem = (itemId, index) => (dispatch, getState) => {
  const {
    items: { itemGroups },
    currentCompany: { id },
    vouchers: {
      itemDetailsRequested,
      _lineItems,
      selectedPartyAccount: {
        salesDiscounts = [],
        defaultSalesDiscount = false,
        defaultSalesDiscountValue = {},
        purchaseDiscounts = [],
        defaultPurchaseDiscount = false,
        defaultPurchaseDiscountValue = {}
      }
    },
    settings: { voucherSettings }
  } = getState();
  if (itemDetailsRequested) {
    return;
  } //if api already in progress

  let tempSalesDiscount = [...salesDiscounts];
  let tempPurchaseDiscount = [...purchaseDiscounts];
  let selectedItem = _lineItems[index];

  if (defaultSalesDiscount) {
    tempSalesDiscount = VoucherOptimizePureCal.defaultSalesDiscountCheck(
      tempSalesDiscount,
      defaultSalesDiscountValue,
      selectedItem
    );
    dispatch({ type: SET_PARTY_SALES_DISCOUNT, payload: tempSalesDiscount });
  }

  if (defaultPurchaseDiscount) {
    tempPurchaseDiscount = VoucherOptimizePureCal.defaultPurchaseDiscountCheck(
      tempPurchaseDiscount,
      defaultPurchaseDiscountValue,
      selectedItem
    );

    dispatch({ type: SET_PARTY_PURCHASE_DISCOUNT, payload: tempPurchaseDiscount });
  }

  dispatch(lineItemIndex(index));
  dispatch({ type: STORE_OLD_LINE_ITEM, index: index });
  dispatch({ type: FETCH_ITEM_DETAILS_REQUESTED });
  getItemWithAttributeDetails(id, itemId)
    .then(refreshedItem => {
      dispatch({ type: FETCH_ITEM_DETAILS_SUCCESS });
      const { taxPercentage, hsn } = getTaxPercentageAndHsn(itemGroups, refreshedItem.itemGroupId);
      refreshedItem.taxPercentage = taxPercentage;
      refreshedItem.hsn = hsn;
      dispatch({
        type: REFRESH_LINE_ITEM,
        payload: refreshedItem,
        index: index,
        voucherSettings
      });
      handleItemCalculations(dispatch, getState, EDIT_LINE);
    })
    .catch(error => {
      dispatch({ type: FETCH_ITEM_DETAILS_FAILED });
      serverError(error);
    });
};

const setDiscountValue = payload => (dispatch, getState) => {
  dispatch({
    type: SET_DISCOUNT_VALUE,
    payload: payload
  });

  handleItemCalculations(dispatch, getState, SET_ADDITIONAL_DISCOUNT);
};

const setDiscountUnits = value => (dispatch, getState) => {
  console.log(value);
  dispatch({
    type: SET_DISCOUNT_UNITS,
    value
  });

  handleItemCalculations(dispatch, getState, SET_ADDITIONAL_DISCOUNT);
};

const updateLineItemField = (key, value, keyName, useDebounce = true) => dispatch => {
  // dispatch({ type: STORE_OLD_LINE_ITEM, index: key });
  dispatch({
    type: UPDATE_LINE_ITEM_FIELD,
    key,
    updatedData: {
      [keyName]: value
    }
  });
  if (
    (!isNaN(value) && keyName !== 'description' && keyName !== 'inStoreQty') ||
    keyName === 'unit' ||
    keyName === 'discountUnit'
  ) {
    dispatch(voucherCal());
  }
  // dispatch(voucherCal());
  // if (debounceHandler) {
  //   debounceHandler(() => dispatch(voucherCal()));
  // } else {
  //   keyName !== 'description' && dispatch(voucherCal());
  // }
};

const innerFunction = debounce(dispatch => dispatch(voucherCal()), 200);
const asyncActionDebounced = () => innerFunction;

const setCalcStatus = (key, status) => ({
  type: SET_CALCULATION_STATUS,
  key: key,
  status: status
});

const voucherCalcToggle = flag => (dispatch, getState) => {
  dispatch({
    type: VOUCHER_CALC_TOGGLE,
    flag
  });

  if (flag) {
    //if true then reinitialize line items and recalculate
    dispatch(recalculateVoucher(RECALCULATE));
  }
};

const voucherCal = (key = EDIT_LINE) => (dispatch, getState) => {
  handleItemCalculations(dispatch, getState, key);
};

const discountChange = payload => (dispatch, getState) => {
  dispatch({
    type: DISCOUNT_CHANGE,
    payload: payload
  });

  handleItemCalculations(dispatch, getState, EDIT_LINE);
};

/**
 * This is basically for all combined Vouchers.
 * Child voucher = Sestimation, Pestimation.
 * Calculating the difference amount from the original child value when any child voucher's other charges and otherCharges after tax is updated.
 */

const updateChargesDiff = (key, amount, refAccountId) => (dispatch, getState) => {
  const {
    vouchers: { childOtherChargesDiff, _selectedVocuher }
  } = getState();

  if (
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_SESTIMATION
  ) {
    const currentChargeIndex = findIndex(childOtherChargesDiff[key], {
      refAccountId
    });
    const prevChargeIndex = findIndex(_selectedVocuher[key], { refAccountId });
    let tempCharges = cloneDeep(childOtherChargesDiff[key]);

    if (prevChargeIndex > -1) {
      let finalAmount =
        parseInt(amount, 10) - parseInt(_selectedVocuher[key][prevChargeIndex].amount, 10);
      if (currentChargeIndex > -1) {
        tempCharges[currentChargeIndex]['amount'] = finalAmount;
      } else {
        const currentCharge = cloneDeep(_selectedVocuher[key])[prevChargeIndex];
        currentCharge.amount = finalAmount;
        tempCharges.push(currentCharge);
      }
    } else {
      if (tempCharges[currentChargeIndex]?.amount) {
        tempCharges[currentChargeIndex].amount = parseInt(amount, 10);
      }
    }
    const payload = {
      ...childOtherChargesDiff,
      [key]: tempCharges
    };
    dispatch(setChildOtherChargesDiff(payload));
  }
};

const updateCharges = (amount, value, unit, index, refAccountId) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CHARGES,
    payload: {
      amount: amount,
      index: index,
      value: value,
      unit: unit
    }
  });
  dispatch(updateChargesDiff('otherChargesAfterTax', amount, refAccountId));

  !isNaN(amount) && handleItemCalculations(dispatch, getState, EDIT_OTHER_CHARGES_AFTER_TAX);
};

const calculateOtherChargeAfterTaxAmount = (value, unit, index, refAccountId) => (
  dispatch,
  getState
) => {
  const {
    vouchers,
    vouchers: { _otherChargesAfterTax },
    currentCompany: { currencyDecimals }
  } = getState();

  let method = _otherChargesAfterTax[index].method;

  const amount = VoucherOptimizePureCal.calculateOtherChargeAfterTaxAmount(
    value,
    unit,
    method,
    vouchers,
    currencyDecimals
  );

  if (_otherChargesAfterTax[index].amount !== amount) {
    dispatch(updateCharges(amount, value, unit, index, refAccountId));
  }
};

const getAmountWithoutTax = () => (dispatch, getState) => {
  const {
    vouchers,
    vouchers: { _otherChargesAfterTax },
    currentCompany: { currencyDecimals }
  } = getState();

  return VoucherOptimizePureCal.getAmountWithoutTax(vouchers, currencyDecimals);
};

// function will trigger when values are changed above other charges after tax
const reCalculateOtherChargeAfterTaxAmount = () => (dispatch, getState) => {
  const {
    vouchers: { _otherChargesAfterTax }
  } = getState();

  _otherChargesAfterTax.forEach((charge, index) =>
    dispatch(
      calculateOtherChargeAfterTaxAmount(charge.value, charge.unit, index, charge.refAccountId)
    )
  );
};

export const reCalculateLineItemDiscountBreakup = () => (dispatch, getState) => {
  const {
    vouchers: {
      _selectedVoucherId,
      selectedPartyAccount: { salesDiscounts = [] },
      couponDiscount,
      additionalDiscount,
      _lineItems,
      lineAmountSum,
      discountBreakup,
      hasExclusiveDiscount
    },
    currentCompany: { currencyDecimals }
  } = getState();

  return VoucherOptimizePureCal.calculateLineItemsDiscountBreakupAndEffectivePrice(
    _lineItems,
    lineAmountSum,
    additionalDiscount,
    hasExclusiveDiscount,
    salesDiscounts,
    couponDiscount,
    discountBreakup,
    currencyDecimals
  );
};

const updateAccountField = (input, index, mode) => (dispatch, getState) => {
  const {
    vouchers: { childOtherChargesDiff, _selectedVocuher, _otherChargesAfterTax },
    vouchers,
    currentCompany: { currencyDecimals }
  } = getState();
  let data = {};

  let amount = VoucherOptimizePureCal.calculateOtherChargeAfterTaxAmount(
    input.defaultValue,
    input.defaultValueUnit,
    input.defaultValueMethod,
    vouchers,
    currencyDecimals
  );

  if (input === undefined) {
    data = {
      refAccountId: '',
      name: '',
      accountGroupName: '',
      description: '',
      refPath: '',
      creditAmount: 0,
      debitAmount: 0,
      amount: amount,
      value: 0,
      unit: RUPEES,
      method: ADD_METHOD
    };
  } else {
    data = {
      refAccountId: input.id,
      name: input.name,
      accountGroupName: input.accountGroupName,
      description: input.description,
      refPath: input.path,
      creditAmount: 0,
      debitAmount: 0,
      amount: 0,
      value: input.defaultValue || 0,
      unit: input.defaultValueUnit || RUPEES,
      method: input.defaultValueMethod
    };
  }
  const refAccountId = data.refAccountId || _otherChargesAfterTax[index].refAccountId;
  data &&
    dispatch({
      type: UPDATE_FILTERED_NAMES,
      data: { ...data },
      index,
      mode
    });

  dispatch(updateCharges(amount, data.value, data.unit, index, refAccountId));

  /**
   * This is basically for all combined Vouchers.
   * Child voucher = Sestimation, Pestimation.
   * We need to add the new account of otherChargesAfterTax to child voucher state
   */

  if (
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_SESTIMATION
  ) {
    if (!find(childOtherChargesDiff.otherChargesAfterTax, { refAccountId: input.id })) {
      const tempOther = cloneDeep(childOtherChargesDiff.otherChargesAfterTax);
      tempOther.push(data);
      const payload = {
        ...childOtherChargesDiff,
        otherChargesAfterTax: tempOther
      };
      dispatch(setChildOtherChargesDiff(payload));
    }
  }
};

const onSuccessEditSPLinkedChallan = voucherType => dispatch => {
  dispatch(setChallanListForSPVoucher([]));
  dispatch({ type: SELECT_RESET, key: `${voucherType}-list-table` });
  dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
  dispatch(onEditVoucherSuccess());
};

const onSuccessCreateSPLinkedChallan = voucherType => dispatch => {
  dispatch(openSnackbar('Voucher Created'));
  dispatch({ type: SAVED_VOUCHER_SUCCESS });
  dispatch({ type: ON_CLOSE_VOUCHER_PICKER });
  dispatch(setChallanListForSPVoucher([]));
  dispatch({ type: SELECT_RESET, key: `${voucherType}-list-table` });
  dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
  dispatch(setActionSaveButtonDisable(false));
};

const createSalesLinkedSchallan = (payload, saveAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  payload.payloadId = payloadId;
  return api
    .createSalesLinkedSchallan(selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessCreateSPLinkedChallan(VOUCHER_TYPE_SALES));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_SALES));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const getMergeEstimation = (voucherIds, state = ADD, partyId = '', branchId, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId, currencyDecimals, itemQtyDecimals },
    vouchers: {
      _branches,
      type,
      childOtherChargesDiff,
      parentVoucherInfo,
      _selectedVoucherId,
      _voucherModuleResults
    },
    vendorAccountsDataList
  } = getState();
  const selectedBranch = getBranchById(_branches, branchId);
  dispatch(setSelectedBranch(selectedBranch));
  return api
    .fetchMergeEstimation(branchId, companyId, voucherIds)
    .then(res => {
      const party = vendorAccountsDataList.filter(acc => acc.id === partyId || acc._id === partyId);
      party[0] &&
        dispatch({
          type: UPDATE_BUSINESS_CONTACT,
          payload: {
            business: {
              aliasName: party[0] ? party[0].name : '',
              ...party[0]
            },
            party: party[0]
          }
        });
      const partyGstBusinessTypeRef = getPartyGstBusinessTypeRef(
        type,
        party[0],
        _branches,
        selectedBranch
      );

      let tempOtherChargesData = res.data.otherCharges;
      let tempOtherChargesAfterTaxData = res.data.otherChargesAfterTax;

      /**
       * This is basically for all combined Vouchers.
       * Parent voucher  = Sales, Purchase.
       * Child voucher = Sestimation, Pestimation.
       * When we edit a child voucher we use saved parent voucher other charegs & other charges after tax to concat with the difference in the child othercharges and othercharges after tax.
       */

      // if (
      //   (parentVoucherInfo.otherCharges.length > 0 ||
      //     parentVoucherInfo.otherChargesAfterTax.length > 0) &&
      //   state === UPDATE
      // ) {
      //   tempOtherChargesData = parentVoucherInfo.otherCharges.concat(
      //     childOtherChargesDiff.otherCharges
      //   );
      //   tempOtherChargesAfterTaxData = parentVoucherInfo.otherChargesAfterTax.concat(
      //     childOtherChargesDiff.otherChargesAfterTax
      //   );
      // }

      if (
        childOtherChargesDiff.otherCharges.length > 0 ||
        childOtherChargesDiff.otherChargesAfterTax.length > 0 ||
        (_voucherModuleResults.billFinalAmount &&
          _voucherModuleResults.billFinalAmount !== parentVoucherInfo.billFinalAmount)
      ) {
        dispatch(toggleChildErrorFlag(true));
      }

      let otherCharges = getUniqArray(tempOtherChargesData, 'refAccountId', 'amount');

      otherCharges = otherCharges.map(otherCharge => {
        /*
           when convert estimate to sales or purchase % value should convert in rupee and value should be positive
           Add or Less will be decided by nature of the account
        */
        if (otherCharge.unit === PERCENT) {
          otherCharge.unit = RUPEES;
        }
        otherCharge.value = Math.abs(otherCharge.amount);

        return otherCharge;
      });

      let otherChargesAfterTax = getUniqArray(
        tempOtherChargesAfterTaxData,
        'refAccountId',
        'amount'
      );

      otherChargesAfterTax = otherChargesAfterTax.map(otherCharge => {
        otherCharge.value = otherCharge.amount;
        if (otherCharge.unit === PERCENT) {
          otherCharge.unit = RUPEES;
        }

        otherCharge.value = Math.abs(otherCharge.amount);
        return otherCharge;
      });

      const totalOtherChargesAfterTax = otherChargesAfterTax.reduce(
        (total, otherCharge) => total + otherCharge.amount,
        0
      );

      const payload = {
        ...res.data,
        otherCharges,
        otherChargesAfterTax
      };
      const initParams = {
        voucherId: _selectedVoucherId || NEW,
        itemList: [],
        additionalDiscount: res.data.additionalDiscount,
        hasExclusiveDiscount: res.data.hasExclusiveDiscount,
        salesDiscount: res.data.salesDiscounts,
        couponDiscount: res.data.couponDiscount,
        otherCharges: otherCharges,
        partyId: partyId,
        partyGstBusinessTypeRef: partyGstBusinessTypeRef,
        otherChargesAfterTax: otherChargesAfterTax,
        adjustments: 0,
        partyState: party[0] ? party[0].state : '',
        branchState: selectedBranch.state,
        currencyDecimals,
        itemQtyDecimals
      };
      initVoucherCalc(initParams);

      dispatch({
        type: SET_ESTIMATION_LINKED_ITEM_LIST,
        payload
      });
      dispatch(lineItemIndex(0));
      handleItemCalculations(dispatch, getState, MERGE_ESTIMATION);
      dispatch(updateLinkedVoucherStatus(false, SUCCESS));
      dispatch({ type: GET_OTHER_CHARGES_AFTER_TAX_TOTAL, payload: totalOtherChargesAfterTax });
      callback && callback(res.data);
    })
    .catch(error => {
      console.log(error);
      dispatch(updateLinkedVoucherStatus(false, ERROR));
      serverError(error);
    });
};

const setSelectedBranch = branch => dispatch => {
  dispatch({ type: SET_SELECTED_BRANCH, branch });
};

const createSalesLinkedSestimationVoucher = (payload, saveAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  payload.payloadId = payloadId;
  dispatch(setActionSaveButtonDisable(true));
  return api
    .createSalesLinkedSestimationVoucher(selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessCreateSPLinkedChallan(VOUCHER_TYPE_SALES));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_SALES));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const createPurchaseLinkedPestimationVoucher = (payload, saveAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  payload.payloadId = payloadId;
  dispatch(setActionSaveButtonDisable(true));
  return api
    .createPurchaseLinkedPestimationVoucher(selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessCreateSPLinkedChallan(VOUCHER_TYPE_PURCHASE));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PURCHASE));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const createPurchaseLinkedPchallan = (payload, saveAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  payload.payloadId = payloadId;
  dispatch(setActionSaveButtonDisable(true));
  return api
    .createPurchaseLinkedPchallan(selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessCreateSPLinkedChallan(VOUCHER_TYPE_PURCHASE));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PURCHASE));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const onEditSalesLinkedSestimation = (payload, updateAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      _branches,
      _selectedBranch,
      _vouchersById: { sales }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);

  if (!(payload.voucherIds.length > 0)) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Update has been failed click on retry button', ERROR));
  }

  if (payload.refAccountName !== SALES) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Invalid Account Name', ERROR));
  }

  dispatch(setActionSaveButtonDisable(true));
  return api
    .editSalesLinkedSestimation(sales.id, selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(resetParentChildVoucherData());
      dispatch(toggleChildErrorFlag(false));
      dispatch(onSuccessEditSPLinkedChallan(VOUCHER_TYPE_SALES));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_SALES));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const onEditPurchaseLinkedPestimation = (payload, updateAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      _branches,
      _selectedBranch,
      _vouchersById: { purchase }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);

  if (!(payload.voucherIds.length > 0)) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Update has been failed click on retry button', ERROR));
  }

  if (payload.refAccountName !== PURCHASE) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Invalid Account Name', ERROR));
  }

  dispatch(setActionSaveButtonDisable(true));
  return api
    .editPurchaseLinkedPestimation(purchase.id, selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(resetParentChildVoucherData());
      dispatch(toggleChildErrorFlag(false));
      dispatch(onSuccessEditSPLinkedChallan(VOUCHER_TYPE_PURCHASE));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PURCHASE));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const onEditSalesLinkedSchallan = (payload, updateAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      _branches,
      _selectedBranch,
      _vouchersById: { sales }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);

  if (!(payload.voucherIds.length > 0)) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Update has been failed click on retry button', ERROR));
  }

  if (payload.refAccountName !== SALES) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Invalid Account Name', ERROR));
  }

  dispatch(setActionSaveButtonDisable(true));

  return api
    .editSalesLinkedSchallan(sales.id, selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessEditSPLinkedChallan(VOUCHER_TYPE_SALES));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_SALES));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

const onEditPurchaseLinkedPchallan = (payload, updateAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      _branches,
      _selectedBranch,
      _vouchersById: { purchase }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);

  if (!(payload.voucherIds.length > 0)) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Update has been failed click on retry button', ERROR));
  }

  if (payload.refAccountName !== PURCHASE) {
    dispatch(setActionSaveButtonDisable(false));
    return dispatch(openSnackbar('Invalid Account Name', ERROR));
  }

  dispatch(setActionSaveButtonDisable(true));
  return api
    .editPurchaseLinkedPchallan(purchase.id, selectedBranchId, companyId, payload)
    .then(result => {
      dispatch(onSuccessEditSPLinkedChallan(VOUCHER_TYPE_PURCHASE));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PURCHASE));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
    })
    .catch(error => {
      serverError(error);
      dispatch(setActionSaveButtonDisable(false));
    });
};

// This function will be used for Validating vouchers data before saving.
const onVoucherValidation = (callback, type) => (dispatch, getState) => {
  const {
    vouchers: {
      supplierInvoiceDate,
      _issueDateObj,
      selectedPartyAccount,
      _selectedBranch,
      _selectedDestinationBranch,
      dueDate,
      _shippingAddress
    },
    items: { bulkItemsList },
    currentCompany: { selectedFinancialYear: financialYear }
  } = getState();
  const supplierDateDiffWithIssueDate = dateDiffInDays(_issueDateObj, supplierInvoiceDate);
  const issueDateDiffWithFinStartDate = dateDiffInDays(_issueDateObj, financialYear.startDate);
  const dueDateDiffWithIssueDate = dateDiffInDays(dueDate, _issueDateObj);
  const issueDateDiffWithFinEndtDate = dateDiffInDays(financialYear.endDate, _issueDateObj);

  dispatch(setActionSaveButtonDisable(true));

  if (!_shippingAddress?.country) {
    dispatch(openSnackbar('Please Select Country', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (!selectedPartyAccount.id && (purchaseTypeFlag(type) || salesTypeFlag(type))) {
    dispatch(openSnackbar('Please Select Party', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (type === VOUCHER_TYPE_STOCK_TRANSFER) {
    if (isEmpty(_selectedDestinationBranch)) {
      dispatch(openSnackbar('Please Select Destination Branch', ERROR));
      dispatch(setActionSaveButtonDisable(false));
      return;
    }
    if (_selectedDestinationBranch.id === _selectedBranch.id) {
      dispatch(openSnackbar('Destination and Source branch cannot be same', ERROR));
      dispatch(setActionSaveButtonDisable(false));
      return;
    }
  }

  if (supplierDateDiffWithIssueDate < 0) {
    dispatch(openSnackbar('Supplier Date cannot exceed Issue date', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (issueDateDiffWithFinStartDate < 0) {
    dispatch(openSnackbar('Issue Date cannot exceed financial start date', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (issueDateDiffWithFinEndtDate < 0) {
    dispatch(openSnackbar('Issue Date cannot exceed financial end date', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }
  if (dueDateDiffWithIssueDate < 0 && !EXCLUDE_DUE_DATE.includes(type)) {
    dispatch(openSnackbar('Due Date cannot before Issue date', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (bulkItemsList.length > 0) {
    dispatch(
      openSnackbar(`You have ${bulkItemsList.length} items left in Create Items Bulk`, ERROR)
    );
    dispatch(setActionSaveButtonDisable(false));
    return;
  }
  callback && callback();
};

const validatePartyObj = party => {
  // if (!party.address && !party.pincode) {
  //   if (party.gstPartyType !== UNREGISTERED_BUSINESS) return false;
  // }
  if (
    party === null &&
    party === undefined &&
    !party.address &&
    !party.pincode &&
    party.gstPartyType !== UNREGISTERED_BUSINESS
  ) {
    return false;
  }
  return true;
};

/**
 * this action is genric for salse and purchase, but has diffrenent api for purchase and salse
 * @param {payload} payload to be sent to server
 */
const onSaveSPCDVoucher = (payload, vType, saveAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId, name },
    vouchers: {
      _lineItems,
      type,
      _branches,
      _selectedBranch,
      payloadId,
      _voucherModuleResults: { otherChargesAfterTaxTotal = 0, otherChargesTotal = 0 }
    },
    router: {
      location: { pathname }
    }
  } = getState();
  const voucherType = vType || type; //"vType" is passed in case of adjustment voucher, since it is combined with transfer voucher and "type" will be transfer
  mixpanel.track('save-voucher-click', {
    voucherType: voucherType,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  let asyncCall;
  if (voucherType === VOUCHER_TYPE_CREDIT_NOTE || voucherType === VOUCHER_TYPE_DEBIT_NOTE) {
    //for crdr  allow voucher with no items if other charges are added
    if (
      _lineItems &&
      _lineItems.length === 0 &&
      otherChargesAfterTaxTotal === 0 &&
      otherChargesTotal === 0
    ) {
      dispatch(openSnackbar('No Items or charges added in the voucher', ERROR));
      dispatch(setActionSaveButtonDisable(false));
      return;
    }
  } else if (_lineItems && _lineItems.length === 0) {
    dispatch(openSnackbar('No Items added in the voucher', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (!validatePartyObj(payload.party)) {
    dispatch(openSnackbar('For Registered GST Business, Invoices require Billing Address', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }
  payload.payloadId = payloadId;

  // TODO: need to work on voucher type and name constants
  switch (voucherType) {
    case VOUCHER_TYPE_SCHALLAN:
    case VOUCHER_TYPE_CHALLAN:
      asyncCall = createDispatchForApi(api.createChallan, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_SALES:
      asyncCall = createDispatchForApi(api.createSales, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_PURCHASE:
      asyncCall = createDispatchForApi(api.createPurchase, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_PESTIMATION:
      asyncCall = createDispatchForApi(api.createPestimation, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_SESTIMATION:
      asyncCall = createDispatchForApi(api.createSestimation, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_PCHALLAN:
      asyncCall = createDispatchForApi(api.createPchallan, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_CREDIT_NOTE:
      asyncCall = createDispatchForApi(api.createCredit, selectedBranchId, companyId, payload);
      break;
    case VOUCHER_TYPE_STOCK_TRANSFER:
      asyncCall = createDispatchForApi(
        api.createStockTransfer,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_STOCK_ADJUSTMENT:
      asyncCall = createDispatchForApi(
        api.createStockAdjustment,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    default:
      asyncCall = createDispatchForApi(api.createDebit, selectedBranchId, companyId, payload);
  }

  // this action disable the save button to avoid creation of same voucher on double click
  dispatch(setActionSaveButtonDisable(true));

  dispatch(asyncCall)
    .then(result => {
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(closeAndResetVoucherFormState(voucherType));
      dispatch({ type: ON_CLOSE_VOUCHER_PICKER });
      dispatch(preserveVoucherDate(payload));
      if (saveAndPrint) {
        dispatch(setVoucherForPrint(result.id, result.type, result.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Created & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Created'));
      }
      // local component calls fetchVouchers currently
      // but will be needed in future when api fetch is
      // moved out of component and uses redux

      // dispatch(fetchVouchers(id, startDate, moment()._d));
    })
    .catch(error => {
      if (error.response && error.response.status !== 409) {
        serverError(error);
      }
      dispatch(setActionSaveButtonDisable(false));
    });
};

const createDispatchForApi = (apiFn, arg1, ar2, arg3, arg4) => () => {
  return apiFn(arg1, ar2, arg3, arg4);
};

const onEditSPCDVoucher = (payload, voucherType = '', updateAndPrint) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _lineItems, type, _branches, _selectedBranch, _selectedVoucherId, editVoucherId },
    contacts: { partyProfile }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  if (_lineItems && _lineItems.length === 0) {
    dispatch(openSnackbar('No Items added in the voucher', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return;
  }

  if (editVoucherId !== _selectedVoucherId) {
    dispatch(openSnackbar('Voucher data is not correct', ERROR));
    dispatch(setActionSaveButtonDisable(false));
    return false;
  }

  let asyncCall = '';
  switch (voucherType || type) {
    case VOUCHER_TYPE_CHALLAN:
    case VOUCHER_TYPE_SCHALLAN:
      asyncCall = createDispatchForApi(
        api.editChallan,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_SALES:
      asyncCall = createDispatchForApi(
        api.editSales,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_PURCHASE:
      asyncCall = createDispatchForApi(
        api.editPurchase,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_PESTIMATION:
      asyncCall = createDispatchForApi(
        api.editPestimation,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_SESTIMATION:
      asyncCall = createDispatchForApi(
        api.editSestimation,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_PCHALLAN:
      asyncCall = createDispatchForApi(
        api.editPchallan,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_CREDIT_NOTE:
      asyncCall = createDispatchForApi(
        api.editCreditNote,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_STOCK_TRANSFER:
      asyncCall = createDispatchForApi(
        api.editStockTransfer,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    case VOUCHER_TYPE_STOCK_ADJUSTMENT:
      asyncCall = createDispatchForApi(
        api.editStockAdjustment,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
      break;
    default:
      asyncCall = createDispatchForApi(
        api.editDebitNote,
        _selectedVoucherId,
        selectedBranchId,
        companyId,
        payload
      );
  }

  dispatch(asyncCall)
    .then(() => {
      dispatch(onEditVoucherSuccess());
      partyProfile &&
        partyProfile.id &&
        dispatch(fetchAccountsCurrentBalance(partyProfile.refAccountId));
      dispatch({ type: ON_CLOSE_VOUCHER_PICKER });

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(_selectedVoucherId, type, voucherType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditCreditNote = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditDebitNote = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditPurchaseVoucher = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditPchallanVoucher = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditSalesVoucher = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditStockTransferVoucher = payload => dispatch => {
  dispatch(onEditSPCDVoucher(payload, VOUCHER_TYPE_STOCK_TRANSFER));
};
const onEditStockAdjustmentVoucher = payload => dispatch => {
  dispatch(onEditSPCDVoucher(payload, VOUCHER_TYPE_STOCK_ADJUSTMENT));
};
const onEditChallan = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditSestimation = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditPestimation = (payload, updateAndPrint) => dispatch => {
  dispatch(onEditSPCDVoucher(payload, undefined, updateAndPrint));
};

const onEditVoucherSuccess = () => (dispatch, getState) => {
  const {
    vouchers: { _selectedVoucherId, type }
  } = getState();
  dispatch({ type: SAVED_VOUCHER_SUCCESS });
  dispatch(closeAndResetVoucherFormState(type));
  dispatch(openSnackbar('Voucher Updated'));
  dispatch(getVoucherData(_selectedVoucherId));
  dispatch(setActionSaveButtonDisable(false));
  // dispatch(getShareVoucherData(partyId, _selectedVoucherId));
  dispatch(fetchItemByVoucherId(_selectedVoucherId));
};

const updateVoucherList = type => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate, endDate }
    }
  } = getState();
  dispatch(fetchVouchers(companyId, startDate, endDate, type));
};

const preserveVoucherDate = payload => (dispatch, getState) => {
  let { dueDate, issueDate, supplierInvoiceDate } = payload;
  let data = {};
  if (dueDate) {
    data.dueDate = dueDate;
  }
  if (issueDate) {
    data.issueDate = issueDate;
  }
  if (supplierInvoiceDate) {
    data.supplierInvoiceDate = getFormattedDate3(supplierInvoiceDate);
  }
  dispatch({ type: PRESERVE_VOUCHER_DATES, payload: data });
};

const closeAndResetVoucherFormState = type => (dispatch, getState) => {
  const {
    contacts: {
      voucherViewMode: { flag, modelKeyObj }
    },
    currentCompany: { id: companyId },
    vouchers: {
      selectedPartyAccount: { id: partyId },
      hiddenVoucherList
    },
    reports: {
      inventory: {
        [INVENTORY_ITEM_STATEMENT_PATH]: { pageNo }
      },
      monthlyDetails
    }
  } = getState();

  if (flag) {
    //refresh account or party data if editing voucher from account or party page

    switch (modelKeyObj.key) {
      case 'ITEM_STATEMENT':
        modelKeyObj.itemId && dispatch(handleItemStatement(pageNo, modelKeyObj.itemId));
        break;
      case 'PARTY':
        refreshPartyOrAccountData(dispatch, getState, companyId, partyId);
        break;
      case 'REPORT':
        modelKeyObj.selectedId &&
          dispatch(
            showAccountStatementViewAction(modelKeyObj.selectedId, monthlyDetails.page || 1)
          );
        break;
      case 'HIDDEN_VOUCHER':
        if (modelKeyObj.filters) {
          const {
            filters: { selectedParty, selectedBranch, startDate, endDate }
          } = modelKeyObj;
          dispatch(
            getHideVoucher(
              hiddenVoucherList.page || 1,
              selectedParty,
              selectedBranch,
              startDate,
              endDate
            )
          );
        }
        break;
      default:
        break;
    }
  }
  dispatch(updateVoucherList(type));
  dispatch(toggleAddEditDrawer(type, false));
  dispatch(resetVoucher());

  return {
    type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
  };
};

// let VoucherCalcInstance = VoucherCalModule.optimizePureCal;
// export let VCinstance = new VoucherCalcInstance();
// const initVoucherCalc = (
//   itemList = [],
//   additionalDiscount = 0,
//   hasExclusiveDiscount = false,
//   salesDiscount = [],
//   couponDiscount = 0,
//   otherCharges = [],
//   partyId = '',
//   partyGstBusinessTypeRef = '',
//   otherChargesAfterTax = [],
//   adjustments = 0
// ) => dispatch => {
//   console.log('init voucher calc', salesDiscount);
//   // calculationRef = new calculationRefClass();
//   VCinstance.initVoucherCalculation(
//     itemList,
//     additionalDiscount,
//     hasExclusiveDiscount,
//     salesDiscount,
//     couponDiscount,
//     otherCharges,
//     partyId,
//     partyGstBusinessTypeRef,
//     otherChargesAfterTax,
//     adjustments
//   );
// };

// const resetVoucherCalc = () => dispatch => {
//   VoucherOptimizePureCal.resetVoucherCalculation();
// };

const openPrevVoucher = type => (dispatch, getState) => {
  const {
    vouchers: { _vouchersById }
  } = getState();
  dispatch(getVoucherData(_vouchersById[type].id));
  dispatch(setSPCDDetailsOnCreationDrawer(_vouchersById[type], _vouchersById[type].id));
  // dispatch(toggleAddEditDrawer(res.type, false));
  dispatch(toggleAddEditDrawer(type, true, EDIT));
  return {
    type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
  };
};

const setRoundOffValue = value => (dispatch, getState) => {
  dispatch({
    type: SET_ROUND_OFF_VALUE,
    value
  });
  handleItemCalculations(dispatch, getState, SET_ROUNDOFF, value);
};

const setRoundOffOffset = (value, triggerCalculationFlag = true) => (dispatch, getState) => {
  dispatch({ type: SET_ROUND_OFF_OFFSET, value });

  // VoucherOptimizePureCal.updateAdjustments(value || 0);
  // console.log(triggerCalculationFlag);
  triggerCalculationFlag && handleItemCalculations(dispatch, getState, SET_ROUNDOFF, value);
};

// const onSaveCreditNote = payload => (dispatch, getState) => {
//   const {
//     currentCompany: { id: companyId },
//     vouchers: { _branches, _selectedBranch }
//   } = getState();
//   const selectedBranchId = getBranchId(_branches, _selectedBranch);
//   api
//     .createCredit(selectedBranchId, companyId, payload)
//     .then(res => {
//       // dispatch(togglePurchaseSalesCreationDrawer());
//       dispatch({ type: SAVED_VOUCHER_SUCCESS });
//       dispatch(resetVoucher());
//     })
//     .catch(error => serverError(error));
// };

const createRecordPayment = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  return api.createRecordPayment(selectedBranchId, companyId, payload);
};

// const onSaveDebitNote = payload => (dispatch, getState) => {
//   const {
//     currentCompany: { id: companyId },
//     vouchers: { _branches, _selectedBranch }
//   } = getState();
//   const selectedBranchId = getBranchId(_branches, _selectedBranch);
//   api
//     .createDebit(selectedBranchId, companyId, payload)
//     .then(res => {
//       dispatch(togglePurchaseSalesCreationDrawer());
//       dispatch({ type: SAVED_VOUCHER_SUCCESS });
//     })
//     .catch(error => serverError(error));
// };

const lineItemIndex = index => (dispatch, getState) => {
  //logic goes here for line itemIndex to populate with current index
  const {
    vouchers: {
      app: {
        lineItem: { currentItemIndex }
      }
    }
  } = getState();
  //prevent calling if index is not changed
  dispatch({ type: STORE_OLD_LINE_ITEM, index: index });
  currentItemIndex !== index && dispatch({ type: LINE_ITEM_INDEX, index });
};
const setCreatedItemInLineItems = item => (dispatch, getState) => {
  const {
    vouchers: { _lineItems },
    settings: { voucherSettings }
  } = getState();
  dispatch({ type: RESET_OLD_LINE_ITEM });
  dispatch({
    type: ADD_LINE_ITEM,
    payload: item,
    index: _lineItems.length,
    loadItemQty: true,
    voucherSettings
  });

  handleItemCalculations(dispatch, getState, NEW_LINE, 0, _lineItems.length);
  dispatch(setRoundOffOffset(0, false));
};

const toggleViewTaxAnalysis = flag => (dispatch, getState) => {
  const {
    vouchers: {
      type,
      _lineItems,
      _otherCharges,
      _branches,
      _selectedBranch,
      _selectedVoucherId = NEW,
      selectedPartyAccount
    }
  } = getState();

  dispatch({ type: VIEW_TAX_ANALYSIS }, flag);

  if (!flag) {
    return;
  }
  let partyGstBusinessTypeRef = getPartyGstBusinessTypeRef(
    type,
    selectedPartyAccount,
    _branches,
    _selectedBranch
  );

  const taxAnalysis = VoucherOptimizePureCal.getTaxAnalysis(
    _selectedVoucherId,
    _lineItems,
    _otherCharges,
    partyGstBusinessTypeRef
  );
  dispatch({ type: GET_TAX_ANALYSIS, payload: taxAnalysis });
};

const fetchVouchers = (companyId, startDate, endDate, type) => (dispatch, getState) => {
  const {
    vouchers,
    vouchers: { voucherList, _selectedVocuher }
  } = getState();

  let query = `startDate=${startDate}&endDate=${endDate}`;

  let voucherType = [];

  if (type) {
    voucherType.push(type);
  }

  if (type && vouchers.type !== type) {
    voucherType.push(vouchers.type);
  }

  if (_selectedVocuher.subType) {
    switch (_selectedVocuher.subType) {
      case VOUCHER_TYPE_LINKED_SESTIMATION:
        voucherType.push(VOUCHER_TYPE_SESTIMATION);
        break;
      case VOUCHER_TYPE_LINKED_PESTIMATION:
        voucherType.push(VOUCHER_TYPE_PESTIMATION);
        break;
      case VOUCHER_TYPE_LINKED_PCHALLAN:
        voucherType.push(VOUCHER_TYPE_PCHALLAN);
        break;
      case VOUCHER_TYPE_LINKED_SCHALLAN:
        voucherType.push(VOUCHER_TYPE_SCHALLAN);
        break;
      default:
        break;
    }
  }

  dispatch({ type: VOUCHER_LIST_COUNT_REQUESTED, voucherType });

  if (voucherType && voucherType.length > 0) {
    query += `&type=${voucherType.join(',')}`;
  }

  return api
    .fetchVouchers(companyId, query)
    .then(response => {
      let payload = voucherList.map(item => {
        const result = response.find(i => i._id === item._id);
        if (result) {
          return { ...item, ...result };
        }
        return item;
      });

      let sortedData = sortBy(payload, item => item.orderBy);
      dispatch({ type: VOUCHER_LIST_COUNT_SUCCESS, payload: sortedData, voucherType });
    })
    .catch(error => {
      dispatch({ type: VOUCHER_LIST_COUNT_FAILED, voucherType });
      // TODO: We can create navigation dispatch action handler like done in
      // react native. For now using directly here for demo purpose
      if (error.response && error.response.status === 401) {
        if (companyId === null || companyId === undefined) {
          navigate(dispatch, `/companies/list`);
        } else {
          navigate(dispatch, `/companies/company/${companyId}`);
        }
      } else {
        serverError(error);
      }
    });
};

const fetchVoucherAccessList = companyId => (dispatch, getState) => {
  const {
    vouchers: {
      getDate: { startDate, endDate }
    }
  } = getState();

  dispatch({ type: VOUCHERS_LIST_REQUESTED, payload: {} });

  return api
    .fetchVoucherAccessList(companyId)
    .then(response => {
      let sortedData = sortBy(response, item => item.orderBy);
      dispatch({ type: VOUCHERS_LIST_SUCCESS, payload: sortedData });
      dispatch(fetchVouchers(companyId, startDate, endDate));
    })
    .catch(error => {
      dispatch({ type: VOUCHERS_LIST_FAILED, payload: error });
    });
};

const getVoucherTypesForCurrentFinancialYear = callback => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  api
    .fetchVoucherAccessList(companyId)
    .then(response => {
      let sortedData = sortBy(response, item => item.orderBy);
      callback && callback(sortedData);
    })
    .catch(error => {
      serverError(error);
    });
};

//Lock is the boolean value telling when to unlock and lock the voucher
const setReadLockOnVoucher = (voucherIds, lock, updateViewVoucherFlag = false) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate },
      type
    }
  } = getState();
  let endDate = formatDate(moment().endOf('day')._d);
  const payload = {
    voucherIds,
    readLockEnabled: lock
  };
  return api
    .setReadLock(companyId, payload)
    .then(() => {
      dispatch(onSuccessLock(companyId, startDate, endDate, null, lock, type));
      dispatch({ type: UPDATED_VOUCHER_SUCCESS });
      updateViewVoucherFlag && dispatch(getSingleVoucher(voucherIds[0], type));
    })
    .catch(error => {
      serverError(error);
    });
};

const setupdateLockOnVoucher = (voucherIds, lock, updateViewVoucherFlag = false) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate },
      type
    }
  } = getState();
  let endDate = formatDate(moment().endOf('day')._d);
  const payload = {
    voucherIds,
    updateLockEnabled: lock
  };
  return api
    .setUpdateLock(companyId, payload)
    .then(() => {
      dispatch(onSuccessLock(companyId, startDate, endDate, null, lock));
      dispatch({ type: UPDATED_VOUCHER_SUCCESS });
      updateViewVoucherFlag && dispatch(getSingleVoucher(voucherIds[0], type));
    })
    .catch(error => {
      serverError(error);
    });
};

const onSuccessLock = (companyId, startDate, endDate, unhide = false, lock) => (
  dispatch,
  getState
) => {
  const {
    vouchers: { type }
  } = getState();
  dispatch({ type: UPDATED_VOUCHER_SUCCESS });
  dispatch({ type: SELECT_RESET, key: unhide ? 'hidden-type-voucher' : `${type}-list-table` });
  dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
  dispatch(openSnackbar(`Vouchers ${lock ? 'Locked' : 'Unlocked'}`));
  dispatch(fetchVouchers(companyId, startDate, endDate, type));
};

const getVoucher = voucherId => dispatch => {
  dispatch({ type: GET_VOUCHER_REQUESTED, payload: {} });
  api
    .getVoucher(voucherId)
    .then(response => {
      dispatch({ type: GET_VOUCHER_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: GET_VOUCHER_FAILED, payload: error }));
};

const getShareVoucher = (shareId, callback) => dispatch => {
  dispatch({ type: GET_VOUCHER_REQUESTED, payload: {} });
  api
    .getShareVoucher(shareId)
    .then(response => {
      dispatch({ type: GET_VOUCHER_SUCCESS, payload: response });
      callback && callback(response);
    })
    .catch(error => alert('Access Denied', { type: GET_VOUCHER_FAILED, payload: error }));
};

const getShareVoucherPdf = (shareId, callback) => dispatch => {
  dispatch({ type: GET_VOUCHER_REQUESTED, payload: {} });
  api
    .getShareVoucherPdf(shareId)
    .then(res => {
      window.open(res.response, '_blank').focus();
      callback && callback(res);
      return res;
    })
    .catch(error => alert('Access Denied', { type: GET_VOUCHER_FAILED, payload: error }));
};

const increamentVoucherView = (voucherId, shareId) => dispatch => {
  dispatch({ type: INCREMENT_VOUCHER_REQUESTED, payload: {} });
  // const companyId = getState().currentCompany.id;
  api
    .increamentVoucherView(voucherId, shareId)
    .then(response => {
      dispatch({ type: INCREMENT_VOUCHER_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: INCREMENT_VOUCHER_FAILED, payload: error }));
};

const generateVoucherPdf = voucherId => dispatch => {
  dispatch({ type: GENERATE_VOUCHER_PDF_REQUESTED, payload: {} });
  api
    .generateVoucherPdf(voucherId)
    .then(response => {
      dispatch({ type: GENERATE_VOUCHER_PDF_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: GENERATE_VOUCHER_PDF_FAILED, payload: error }));
};

const createPurchase = (selectedBranchId, companyId, payload) => () => {
  return api.createPurchase(selectedBranchId, companyId, payload);
};

const onPreviewVoucher = (voucherID, templateId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  mixpanel.track('voucher-print');
  let query = '';

  if (templateId) {
    query = `templateId=${templateId}`;
  }

  return api
    .handlePreviewVoucherClick(companyId, voucherID, query)
    .then(res => {
      callback ? callback(res.data.response) : window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const onPrintTransportLabel = voucherID => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  mixpanel.track('voucher-transport-label-print');
  return api
    .handlePrintTransportLabel(companyId, voucherID)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

const onSKUPreviewVoucher = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  mixpanel.track('sku-barcode-print');
  return api
    .handleSKUPreviewVoucherClick(companyId, payload)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      dispatch({ type: SELECT_RESET, key: SKU_PRINT_VIEW_TABLE });
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const skuBarcodeNewWayPrinting = payload => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  let defaultTemplate = false;

  query += `isDefaultTemplateCode=${defaultTemplate}`;
  mixpanel.track('sku-barcode-print-invock');

  return api
    .handleInvockSKUPdfPrint(id, payload, query)
    .then(response => {
      window.open(response.data.url, '_blank').focus();
      dispatch({ type: SELECT_RESET, key: SKU_PRINT_VIEW_TABLE });
      return response;
    })
    .catch(error => {
      console.log(error);
      serverError(error);
    });
};

const onSKUV3PreviewVoucher = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .handleSKUV3PreviewVoucherClick(companyId, payload)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      dispatch({ type: SELECT_RESET, key: SKU_PRINT_VIEW_TABLE });
      dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const getUpdateLinkedVoucher = voucherType => (dispatch, getState) => {
  const {
    vouchers: { _vouchersById }
  } = getState();
  dispatch(updateLinkedVoucherStatus(true, INPROGRESS));
  dispatch(updateVoucherList());
  dispatch(getVoucherData(_vouchersById[voucherType].id));
};

const onEditLinkedSchallan = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId, _vouchersById }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editLinkedChallan(_selectedVoucherId, selectedBranchId, companyId, payload)
    .then(res => {
      // refVoucherId
      dispatch(getUpdateLinkedVoucher(VOUCHER_TYPE_SALES));

      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(openSnackbar('Voucher Updated'));

      dispatch(toggleAddEditDrawer(res.type, false));
      dispatch(setActionSaveButtonDisable(false));
      dispatch(toggleAddEditDrawer(VOUCHER_TYPE_SALES, true, EDIT));
      return {
        type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
      };
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditLinkedPchallan = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId, _vouchersById }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editLinkedPchallan(_selectedVoucherId, selectedBranchId, companyId, payload)
    .then(res => {
      // refVoucherId
      dispatch(getUpdateLinkedVoucher(VOUCHER_TYPE_PURCHASE));
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      // dispatch(resetVoucher());
      dispatch(openSnackbar('Voucher Updated'));
      dispatch(toggleAddEditDrawer(res.type, false));
      dispatch(setActionSaveButtonDisable(false));
      dispatch(toggleAddEditDrawer(VOUCHER_TYPE_PURCHASE, true, EDIT));
      return {
        type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
      };
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const updateLinkedVoucherStatus = (loading, status) => ({
  type: UPDATE_LINKED_ESTIMATION_STATUS,
  loading,
  status
});

export const updateEstimationVoucher = voucherType => (dispatch, getState) => {
  const {
    vouchers: { _vouchersById }
  } = getState();

  dispatch(updateLinkedVoucherStatus(true, INPROGRESS));
  dispatch(updateVoucherList());
  dispatch(
    getVoucherData(_vouchersById[voucherType].id, data => {
      dispatch(getMergeEstimation(data.voucherIds, UPDATE, '', data.iBranchId));
    })
  );
};

const onEditLinkedSestimation = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId, _vouchersById }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editLinkedSestimation(_selectedVoucherId, selectedBranchId, companyId, payload)
    .then(res => {
      // refVoucherId
      dispatch(updateEstimationVoucher(VOUCHER_TYPE_SALES));
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(openSnackbar('Voucher Updated'));

      dispatch(toggleAddEditDrawer(res.type, false));
      dispatch(setActionSaveButtonDisable(false));
      dispatch(toggleAddEditDrawer(VOUCHER_TYPE_SALES, true, EDIT));
      return {
        type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
      };
    })
    .catch(error => {
      dispatch(updateLinkedVoucherStatus(false, ERROR));
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditExpenseVoucher = (payload, updateAndPrint, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _selectedBranch, _selectedVoucherId }
  } = getState();
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editExpense(_selectedVoucherId, _selectedBranch.id, companyId, payload)
    .then(response => {
      dispatch(onEditVoucherSuccess());

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(response.id, response.type, response.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      }
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditLinkedPestimation = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId, _vouchersById }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editLinkedPestimation(_selectedVoucherId, selectedBranchId, companyId, payload)
    .then(res => {
      // refVoucherId
      dispatch(updateEstimationVoucher(VOUCHER_TYPE_PURCHASE));
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(openSnackbar('Voucher Updated'));
      dispatch(toggleAddEditDrawer(res.type, false));
      dispatch(setActionSaveButtonDisable(false));
      dispatch(toggleAddEditDrawer(VOUCHER_TYPE_PURCHASE, true, EDIT));
      return {
        type: CLOSE_AND_RESET_VOUCHER_FORM_DONE
      };
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};
const createSales = (selectedBranchId, companyId, payload) => () => {
  return api.createSales(selectedBranchId, companyId, payload);
};

const createChallan = (selectedBranchId, companyId, payload) => () => {
  return api.createChallan(selectedBranchId, companyId, payload);
};

const getVoucherData = (voucherId, callback) => (dispatch, getState) => {
  dispatch({ type: FETCH_VOUCHERS_DATA_REQUEST });
  const {
    currentCompany: { id: companyId },
    user: { id: userId },
    currentCompany: {
      branches: { id: branchId }
    }
  } = getState();
  return api
    .getVoucherData(branchId, companyId, userId, voucherId)
    .then(data => {
      dispatch({ type: FETCH_VOUCHERS_DATA_SUCCESS });
      const voucherList = data.voucherIdsWithData || [];
      dispatch({
        type: SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER,
        payload: voucherList
      });
      data.party && dispatch(getShareVoucherData(data.party.refAccountId, voucherId));
      data.party && dispatch(fetchPartyContacts(data.party.refId));
      dispatch(fetchItemByVoucherId(voucherId));
      dispatch({ type: FETCH_VOUCHERS_DATA, selectedVoucher: data });
      dispatch({ type: FETCH_VOUCHER_SUCCESSED, response: data, voucherType: data.type });
      dispatch({ type: FETCHED_VOUCHER_TYPE, voucherType: data.type }); //added to keep track of current voucher in stock transfer/adjustment  since both are on same page
      dispatch({ type: SET_SELECTED_VOUCHER_ID, id: voucherId });
      dispatch({ type: SET_SELECTED_VOUCHER_NO, voucherNo: data.voucherNo });
      dispatch(updateVouchersDetailsAccount(data.refAccountName, data.type));
      /**
       * This is basically for all combined Vouchers.
       * Parent voucher  = Sales, Purchase.
       * Child voucher = Sestimation, Pestimation.
       * this was done to prevent child additional discount overwriting parent Additional discount
       */
      data.pickUpLocation && dispatch(updatePickUpLocationDetails(data.pickUpLocation));
      data.pickUpLocation && dispatch(togglePickUpLocationLink(false));
      dispatch(populateAdditionalDiscount(data.additionalDiscount));
      dispatch(populateAdditionalDiscountAmount(data.discountBreakup.additionalDiscountSumAmount));
      dispatch(setExclusiveDiscountData(data));
      dispatch(setVoucherDocuments(data.documents));
      dispatch(updateLinkedVoucherStatus(false, SUCCESS));
      callback && callback(data);
    })
    .catch(error => {
      serverError(error);
      dispatch(updateLinkedVoucherStatus(false, ERROR));
      dispatch({ type: FETCH_VOUCHERS_DATA_FAILED });
    });
};

const setExclusiveDiscountData = data => dispatch => {
  let discountTotal = data.discountBreakup ? data.discountBreakup.exclusiveDiscountSumAmount : 0;
  // data.exclusiveDiscountSummary && data.exclusiveDiscountSummary.length > 0
  //   ? data.exclusiveDiscountSummary[0].totalDiscountAmount
  //   : 0;
  dispatch({
    type: UPDATE_EXCLUSIVE_DISCOUNT_DATA,
    payload: {
      exclusiveDiscountList: data.exclusiveDiscountSummary ? data.exclusiveDiscountSummary : [],
      exclusiveDiscountTotal: discountTotal,
      exclusiveDiscountFlag: data.hasExclusiveDiscount ? data.hasExclusiveDiscount : false
    }
  });
};

const handleVoucherDetailBoxRenderOption = (branchNamesArray, voucherType) => (
  dispatch,
  getState
) => {
  const {
    vouchers: { voucherAccounts, _branches, type, _selectedBranch, _selectedVoucherAccount }
  } = getState();
  const accountsList = voucherAccounts[voucherType];
  if (
    type !== VOUCHER_TYPE_JOURNAL &&
    accountsList &&
    accountsList.length > 0 &&
    !_selectedVoucherAccount.id
  ) {
    accountsList[0] && dispatch(updateVouchersDetailsAccount(accountsList[0].name, voucherType));
  }
  if (branchNamesArray.length === 1) {
    dispatch(onUpdatebranchSearch(''));
    // dispatch(handleVoucherDetaislDone(true));
  } else if (_branches.length > 0 && !_selectedBranch.id) {
    dispatch(showVoucherDetailsLink(false));
    dispatch(onUpdatebranchSearch(_branches[0].name));
  }
};

const getVouchersList = (type, page, callback) => (dispatch, getState) => {
  dispatch({
    type: TABLE_LOADING,
    payload: true
  });
  const {
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate, endDate },
      filters: {
        selectedBranch: branch,
        selectedPreparedBy: preparedBy,
        selectedParty: parties,
        selectedAccount,
        selectedStatus: status,
        includeDueAmount,
        readLock,
        updateLock,
        subType = '',
        narration
      }
    },
    table: { sorting: sort, tableListType }
  } = getState();
  return api
    .fetchVouchersList(
      companyId,
      getStartTimeOfDay(startDate),
      getEndTimeOfDay(endDate),
      type,
      getValuesByKey(parties, 'id'),
      selectedAccount,
      page,
      getValuesByKey(preparedBy),
      getValuesByKey(branch),
      status,
      readLock,
      updateLock,
      sort,
      subType,
      includeDueAmount,
      narration,
      tableListType
    )
    .then(data => {
      dispatch({ type: CURRENT_VOUCHER_LIST_SUCCESS, response: data, voucherType: type });
      dispatch({
        type: TABLE_LOADING,
        payload: false
      });
      callback && callback(data);
    })
    .catch(error => {
      dispatch({
        type: TABLE_LOADING,
        payload: false
      });
      serverError(error);
    });
};

const handleNoteAddClick = (voucherId, notesPayload) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api.handleNoteAddClick(companyId, voucherId, notesPayload);
};

const handleRecordPaymentSubmitClick = (voucherId, recordPaymentPayload) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api.handleRecordPaymentSubmitClick(companyId, voucherId, recordPaymentPayload);
};

const handleAccessToggleClick = (contact, voucherId) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  api.handleAccessToggleClick(companyId, contact).then(() => {
    dispatch(getShareVoucherData('', voucherId));
    dispatch(fetchShareVoucherPublicStatus(voucherId));
  });
};
const handleVoucherDetaislDone = (flag, triggerCalculationFlag = true) => (dispatch, getState) => {
  const {
    vouchers: { _lineItems }
  } = getState();
  if (_lineItems && _lineItems.length > 0 && triggerCalculationFlag) {
    handleItemCalculations(dispatch, getState, SET_BRANCH);
  }
  dispatch({
    type: HANDLE_VOUCHER_DETAILS_DONE,
    flag
  });
};

const onUpdatebranchSearch = input => {
  return {
    type: BRANCH_SEARCH_TEXT,
    input
  };
};

const onUpdateVoucherBranch = (input, callback) => dispatch => {
  dispatch(onUpdatebranchSearch(input));
  input && callback();
};

const onUpdateInwardOutwardSubtype = (subtype, callback) => dispatch => {
  dispatch({ type: SET_INWARD_OUTWARD_SUBTYPE, subtype });
  callback && callback();
};

const onUpdateDestinationBranch = (branch, callback) => dispatch => {
  dispatch({ type: SET_SELECTED_DESTINATION_BRANCH, branch });
  callback && callback();
};

const updateShippingAddress = payload => {
  return {
    type: UPDATE_SHIPPING_ADDRESS,
    payload: payload
  };
};

const updateShippingAddressName = payload => {
  return {
    type: UPDATE_SHIPPING_ADDRESS_NAME,
    payload: payload
  };
};

const createCreditNote = (selectedBranchId, companyId, payload) => () => {
  return api.createCredit(selectedBranchId, companyId, payload);
};

const createDebitNote = (selectedBranchId, companyId, payload) => () => {
  return api.createDebit(selectedBranchId, companyId, payload);
};

const shareVoucherPost = (accId, payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: {
      _selectedVocuher: { id: selectedVoucherId }
    }
  } = getState();
  dispatch({ type: SHARE_VOUCHER_REQUESTED });
  api
    .shareVoucherPost(companyId, accId, payload)
    .then(res => {
      dispatch({ type: SHARE_VOUCHER_SUCCESS });
      dispatch(openSnackbar('Voucher Shared'));
      dispatch(getShareVoucherData(accId, selectedVoucherId));
      callback && callback(res);
    })
    .catch(error => {
      dispatch({ type: SHARE_VOUCHER_FAILED });
      serverError(error);
    });
};

const generateShareLink = (accId, payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  mixpanel.track('share-voucher');
  api
    .shareVoucherPost(companyId, accId, payload)
    .then(res => {
      callback && callback(res[0]);
    })
    .catch(error => {
      serverError(error);
    });
};

const internalNotesPost = (payload, voucherID, voucherData, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .internalNotesPost(companyId, voucherID, payload)
    .then(() => {
      dispatch(getSingleVoucher(voucherID, voucherData.type));
      dispatch(openSnackbar('Internal Notes Added'));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

const resetJCVoucher = type => (dispatch, getState) => {
  const {
    voucherTypesUpdateState,
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate, endDate }
    }
  } = getState();
  dispatch(updateVoucherList(type));
  dispatch(fetchVouchers(companyId, startDate, endDate));
  dispatch(toggleAddEditDrawer(type, false));
  dispatch(resetVoucherDetails());
  dispatch(updateVoucherTypes(!voucherTypesUpdateState));
  dispatch(setActionSaveButtonDisable(false));
};

const createJournalVoucher = (payload, saveAndPrint, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);

  // this action disable the save button to avoid creation of same voucher on double click
  dispatch(setActionSaveButtonDisable(true));
  payload.payloadId = payloadId;
  return api
    .createJournal(selectedBranchId, companyId, payload)
    .then(res => {
      // dispatch(togglePurchaseSalesCreationDrawer());
      dispatch(setVoucherDocuments([]));
      dispatch(resetJCVoucher(VOUCHER_TYPE_JOURNAL));
      dispatch(preserveVoucherDate(payload));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(res.id, res.type, res.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Created & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Created'));
      }
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const updateJournalVoucher = (voucherId, payload, updateAndPrint, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editJournal(voucherId, selectedBranchId, companyId, payload)
    .then(res => {
      // dispatch(togglePurchaseSalesCreationDrawer());
      dispatch(getVoucherData(voucherId));
      dispatch(resetJCVoucher(VOUCHER_TYPE_JOURNAL));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(res.id, res.type, res.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditJournalVoucher = () => (dispatch, getState) => {
  const {
    vouchers: {
      _vouchersById: {
        journal,
        journal: { iBranchId, issueDate, supplierInvoiceDate, dueDate, supplierInvoiceNo }
      },
      _branches,
      app: { showVoucherDetailsCard }
    }
  } = getState();
  const selectedBranch = _branches.find(branch => iBranchId === branch.id);
  // dispatch(togglePurchaseSalesCreationDrawer());
  dispatch(setJournalPayload(journal));
  // dispatch(updateJournalPayload(journal.accountList, 'accountList'));
  // dispatch(updateJournalPayload(journal.creditTotalAmount, 'creditTotalAmount'));
  // dispatch(updateJournalPayload(journal.debitTotalAmount, 'debitTotalAmount'));
  dispatch(setSupplierInvoiceDate(supplierInvoiceDate));
  dispatch(setDueDate(getFormattedDateWithTime(dueDate)));
  dispatch(setSupplierInvoiceNo(supplierInvoiceNo));
  dispatch(toggleAddEditDrawer(VOUCHER_TYPE_JOURNAL, true, EDIT));
  if (!showVoucherDetailsCard) {
    dispatch(handleVoucherDetaislDone(true));
  }
  dispatch({ type: FETCH_VOUCHERS_DATA, selectedVoucher: journal });
  dispatch(updateDate('', getFormattedDateWithTime(issueDate)));
  dispatch(onUpdatebranchSearch(selectedBranch.name));
};

const onEditContraVoucher = () => (dispatch, getState) => {
  const {
    vouchers: {
      _vouchersById: {
        contra,
        contra: {
          refAccountName,
          issueDate,
          supplierInvoiceDate,
          dueDate,
          supplierInvoiceNo,
          iBranchId
        }
      },
      app: { showVoucherDetailsCard },
      _branches
    }
  } = getState();
  // dispatch(togglePurchaseSalesCreationDrawer());
  dispatch(setSupplierInvoiceDate(getFormattedDate3(supplierInvoiceDate)));
  dispatch(setDueDate(getFormattedDateWithTime(dueDate)));
  dispatch(setSupplierInvoiceNo(supplierInvoiceNo));
  dispatch(toggleAddEditDrawer(VOUCHER_TYPE_CONTRA, true, EDIT));
  if (!showVoucherDetailsCard) {
    dispatch(handleVoucherDetaislDone(true));
  }
  dispatch({ type: FETCH_VOUCHERS_DATA, selectedVoucher: contra });
  dispatch(updateDate('', getFormattedDateWithTime(issueDate)));
  dispatch(updateVouchersDetailsAccount(refAccountName, VOUCHER_TYPE_CONTRA));
  const selectedBranch = _branches.find(branch => iBranchId === branch.id);
  dispatch(onUpdatebranchSearch(selectedBranch.name));
};

const createContraVoucher = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  payload.payloadId = payloadId;
  return api
    .createContra(selectedBranchId, companyId, payload)
    .then(() => {
      // dispatch(togglePurchaseSalesCreationDrawer());
      dispatch(setVoucherDocuments([]));
      dispatch(resetJCVoucher(VOUCHER_TYPE_CONTRA));
      dispatch(openSnackbar('Voucher Created'));
      dispatch(preserveVoucherDate(payload));
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const updateContraVoucher = (voucherId, payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editContra(voucherId, selectedBranchId, companyId, payload)
    .then(() => {
      dispatch(onEditVoucherSuccess());
      dispatch(getVoucherData(voucherId));
      dispatch(resetJCVoucher(VOUCHER_TYPE_CONTRA));
      dispatch(openSnackbar('Voucher Updated'));
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const createExpenseVoucher = (payload, saveAndPrint, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    vouchers: { _branches, _selectedBranch, payloadId }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  payload.payloadId = payloadId;
  // this action disable the save button to avoid creation of same voucher on double click
  dispatch(setActionSaveButtonDisable(true));

  return api
    .createExpense(selectedBranchId, companyId, payload)
    .then(response => {
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_EXPENSE));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(response.id, response.type, response.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Created & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Created'));
      }

      dispatch(preserveVoucherDate(payload));
      callback();
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const createPayment = (payload, saveAndPrint, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId, name },
    vouchers: { _branches, _selectedBranch, payloadId },
    router: {
      location: { pathname }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  mixpanel.track('save-voucher-click', {
    voucherType: VOUCHER_TYPE_PAYMENT,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch(setActionSaveButtonDisable(true));
  payload.payloadId = payloadId;
  return api
    .createPayment(selectedBranchId, companyId, payload)
    .then(res => {
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(preserveVoucherDate(payload));
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PAYMENT));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(res.id, res.type, res.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Created & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Created'));
      }

      callback && callback(res);
      return res;
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const createReceiptVoucher = (payload, saveAndPrint, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId, name },
    vouchers: { _branches, _selectedBranch, payloadId },
    router: {
      location: { pathname }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  payload.payloadId = payloadId;

  dispatch(setActionSaveButtonDisable(true));
  mixpanel.track('save-voucher-click', {
    voucherType: VOUCHER_TYPE_RECEIPT,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  return api
    .createReceipt(selectedBranchId, companyId, payload)
    .then(res => {
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_RECEIPT));
      dispatch(preserveVoucherDate(payload));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(res.id, res.type, res.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Created & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Created'));
      }

      callback && callback(res);
      return res;
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditPaymentVoucher = (payload, updateAndPrint, callback) => (dispatch, getState) => {
  const {
    voucherTypesUpdateState,
    currentCompany: { id: companyId, name },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId },
    router: {
      location: { pathname }
    }
  } = getState();
  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  mixpanel.track('edit-voucher-click', {
    voucherType: VOUCHER_TYPE_PAYMENT,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editPayment(selectedBranchId, companyId, _selectedVoucherId, payload)
    .then(response => {
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_PAYMENT));
      dispatch(updateVoucherTypes(!voucherTypesUpdateState));

      if (updateAndPrint) {
        dispatch(setVoucherForPrint(response.id, response.type, response.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }

      callback && callback(response);
      return response;
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

const onEditReceiptVoucher = (payload, saveAndPrint, callback) => (dispatch, getState) => {
  const {
    voucherTypesUpdateState,
    currentCompany: { id: companyId, name },
    vouchers: { _branches, _selectedBranch, _selectedVoucherId },
    router: {
      location: { pathname }
    }
  } = getState();

  const selectedBranchId = getBranchId(_branches, _selectedBranch);
  mixpanel.track('edit-voucher-click', {
    voucherType: VOUCHER_TYPE_RECEIPT,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch(setActionSaveButtonDisable(true));
  return api
    .editReceipt(selectedBranchId, companyId, _selectedVoucherId, payload)
    .then(response => {
      dispatch(closeAndResetVoucherFormState(VOUCHER_TYPE_RECEIPT));
      dispatch(updateVoucherTypes(!voucherTypesUpdateState));

      if (saveAndPrint) {
        dispatch(setVoucherForPrint(response.id, response.type, response.subType));
        dispatch(setPrintingDialogStatus(true));
        dispatch(openSnackbar('Voucher Updated & Printing in progress'));
      } else {
        dispatch(openSnackbar('Voucher Updated'));
      }

      callback && callback(response);
      return response;
    })
    .catch(error => {
      dispatch(setActionSaveButtonDisable(false));
      serverError(error);
    });
};

// other charges components value
const toggleOtherCharges = () => {
  return { type: ON_HIDE_OTHER_CHARGES };
};

const toggleExclusiveDiscount = flag => (dispatch, getState) => {
  const {
    vouchers: {
      selectedPartyAccount: {
        salesDiscounts,
        defaultSalesDiscount,
        defaultSalesDiscountValue,
        purchaseDiscounts,
        defaultPurchaseDiscountValue,
        defaultPurchaseDiscount
      },
      _lineItems
    }
  } = getState();
  // console.log(_lineItems, additionalDiscount, salesDiscounts, flag);
  // let exclusiveDiscountList = VoucherPureCal.getDiscountSummary(
  //   _lineItems,
  //   additionalDiscount,
  //   salesDiscounts,
  //   flag
  // );
  // console.log(exclusiveDiscountList);
  defaultSalesDiscount &&
    dispatch({
      type: SET_PARTY_SALES_DISCOUNT,
      payload: VoucherOptimizePureCal.addSalesDiscount(
        salesDiscounts,
        _lineItems,
        defaultSalesDiscountValue
      )
    });

  defaultPurchaseDiscount &&
    dispatch({
      type: SET_PARTY_PURCHASE_DISCOUNT,
      payload: VoucherOptimizePureCal.addPurchaseDiscount(
        purchaseDiscounts,
        _lineItems,
        defaultPurchaseDiscountValue
      )
    });

  dispatch({ type: ON_TOGGLE_EXCLUSIVE_DISCOUNT, flag: flag });
  handleItemCalculations(dispatch, getState, SET_EXCLUSIVE_DISCOUNT);
};

const addOtherCharges = () => ({
  type: ADD_OTHER_CHARGES_NEW_LINE
});

const deleteCharges = (index, refAccountId) => (dispatch, getState) => {
  const {
    vouchers: { childOtherChargesDiff, _otherCharges, _selectedVocuher }
  } = getState();
  dispatch({
    type: DELETE_CHRAGES,
    index
  });

  /**
   * This is basically for all combined Vouchers.
   * Child voucher = Sestimation, Pestimation.
   * We need to delete the account of otherCharges and update the child voucher state
   */

  if (
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_SESTIMATION
  ) {
    const currentChargeIndex = findIndex(childOtherChargesDiff.otherCharges, { refAccountId });
    const tempOthercharges = cloneDeep(childOtherChargesDiff.otherCharges);
    if (currentChargeIndex > -1) {
      tempOthercharges[currentChargeIndex].amount -= _otherCharges[index].amount;
    } else {
      const currentCharge = _otherCharges[index];
      const i = currentCharge.amount;
      currentCharge.amount = i - i * 2; // making whole amount negative
      tempOthercharges.push(currentCharge);
    }

    const payload = {
      ...childOtherChargesDiff,
      otherCharges: tempOthercharges
    };
    dispatch(setChildOtherChargesDiff(payload));
  }

  handleItemCalculations(dispatch, getState, EDIT_OTHER_CHARGES);
};

const toggleOtherChargesAfterTax = () => {
  return { type: ON_HIDE_OTHER_CHARGES_AFTER_TAX };
};

const addOtherChargesAfterTax = () => ({
  type: ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE
});

const deleteOtherChargesAfterTax = (index, refAccountId) => (dispatch, getState) => {
  const {
    vouchers: { childOtherChargesDiff, _otherChargesAfterTax, _selectedVocuher }
  } = getState();
  dispatch({
    type: DELETE_OTHER_CHRAGES_AFTER_TAX,
    index
  });

  /**
   * This is basically for all combined Vouchers.
   * Child voucher = Sestimation, Pestimation.
   * We need to delete the account of otherChargesAftertax and update the child voucher state
   */

  if (
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_SESTIMATION
  ) {
    const currentChargeIndex = findIndex(childOtherChargesDiff.otherChargesAfterTax, {
      refAccountId
    });

    const tempOtherChargesAfterTax = cloneDeep(childOtherChargesDiff.otherChargesAfterTax);
    if (currentChargeIndex > -1) {
      tempOtherChargesAfterTax[currentChargeIndex].amount -= _otherChargesAfterTax[index].amount;
    } else {
      const currentCharge = _otherChargesAfterTax[index];
      const i = currentCharge.amount;
      currentCharge.amount = i - i * 2;
      tempOtherChargesAfterTax.push(currentCharge);
    }

    const payload = {
      ...childOtherChargesDiff,
      otherChargesAfterTax: tempOtherChargesAfterTax
    };
    dispatch(setChildOtherChargesDiff(payload));
  }
  handleItemCalculations(dispatch, getState, EDIT_OTHER_CHARGES_AFTER_TAX);
};

const updateAccount = (input, index, mode) => (dispatch, getState) => {
  const {
    vouchers: {
      childOtherChargesDiff,
      _selectedVocuher,
      _otherCharges,
      _voucherModuleResults: { lineAmountSum }
    },
    vouchers,
    currentCompany: { currencyDecimals }
  } = getState();
  // const {
  //   currentCompany: { expenseIncomeAccounts }
  // } = getState();
  let data = {};
  let amount = 0;

  if (input === undefined) {
    data = {
      refAccountId: '',
      name: '',
      accountGroupName: '',
      description: '',
      refPath: '',
      creditAmount: 0,
      debitAmount: 0,
      amount: amount,
      value: 0,
      unit: RUPEES,
      method: ADD_METHOD
    };
  } else {
    amount = VoucherOptimizePureCal.calculateOtherChargeAmount(
      input.defaultValue,
      input.defaultValueUnit,
      input.defaultValueMethod,
      vouchers,
      currencyDecimals
    );

    data = {
      refAccountId: input.id,
      name: input.name,
      accountGroupName: input.accountGroupName,
      description: input.description,
      refPath: input.path,
      creditAmount: 0,
      debitAmount: 0,
      amount: amount,
      value: input.defaultValue || 0,
      unit: input.defaultValueUnit || RUPEES,
      method: input.defaultValueMethod
    };
    // }))[0];
  }

  const refAccountId = data.refAccountId || _otherCharges[index].refAccountId;

  dispatch({
    type: UPDATE_ACCOUNT,
    data: { ...data },
    index,
    mode
  });
  if (
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_PESTIMATION ||
    _selectedVocuher.type === VOUCHER_TYPE_LINKED_SESTIMATION
  ) {
    if (!find(childOtherChargesDiff.otherCharges, { refAccountId: input.id })) {
      const tempOther = cloneDeep(childOtherChargesDiff.otherCharges);
      tempOther.push(data);
      const payload = {
        ...childOtherChargesDiff,
        otherCharges: tempOther
      };
      dispatch(setChildOtherChargesDiff(payload));
    }
  }
  dispatch(updateAmount(amount, data.value, data.unit, index, refAccountId));
};

const updateAmount = (amount = 0, value, unit, index, refAccountId) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_AMOUNT,
    amount,
    value,
    unit,
    index
  });
  dispatch(updateChargesDiff('otherCharges', amount, refAccountId));
  !isNaN(amount) && handleItemCalculations(dispatch, getState, EDIT_OTHER_CHARGES);
};

const calculateOtherChargeAmount = (value, unit, index, refAccountId) => (dispatch, getState) => {
  const {
    vouchers,
    vouchers: { _otherCharges },
    currentCompany: { currencyDecimals }
  } = getState();

  let amount = VoucherOptimizePureCal.calculateOtherChargeAmount(
    value,
    unit,
    _otherCharges[index].method,
    vouchers,
    currencyDecimals
  );

  dispatch(updateAmount(amount, value, unit, index, refAccountId));
  dispatch(reCalculateOtherChargeAfterTaxAmount());
};

// function will trigger line items values are changed
const reCalculateOtherChargeAmount = () => (dispatch, getState) => {
  const {
    vouchers: { _otherCharges }
  } = getState();

  _otherCharges.forEach((charge, index) =>
    dispatch(calculateOtherChargeAmount(charge.value, charge.unit, index, charge.refAccountId))
  );
};

const currentChargeIndexFocus = index => {
  return {
    type: CURRENT_CHARGE_INDEX_FOCUS,
    index: Number(index)
  };
};

const currentOtherChargesAfterTaxIndexFocus = index => {
  return {
    type: CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS,
    index: Number(index)
  };
};

// end

const addNewLineItem = () => dispatch => {
  dispatch({
    type: SHOW_LINE_ITEM_PICKER
  });
};

const getSingleVoucher = (voucherId, type, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .getSingleVoucher(voucherId, companyId)
    .then(response => {
      if (callback) {
        callback(response);
        return;
      }
      dispatch({ type: FETCH_VOUCHER_SUCCESSED, response, voucherType: type });
      dispatch(setVoucherDocuments(response.documents));
    })
    .then(error => dispatch({ type: FETCH_VOUCHER_FAILED, error }));
};

const handleDeleteVoucherClick = (voucherID, callback) => (dispatch, getState) => {
  dispatch({
    type: DELETE_VOUCHER_REQUEST
  });
  const {
    currentCompany: { id: companyId },
    vouchers: {
      getDate: { startDate, endDate },
      selectedPartyAccount: { id: partyId },
      hiddenVoucherList,
      _selectedVocuher,
      _vouchersList
    },
    contacts: {
      voucherViewMode: { flag, modelKeyObj }
    },
    reports: {
      inventory: {
        [INVENTORY_ITEM_STATEMENT_PATH]: { pageNo }
      },
      monthlyDetails
    }
  } = getState();
  return api
    .handleDeleteVoucherClick(companyId, voucherID)
    .then(() => {
      dispatch(openSnackbar('Voucher Deleted'));
      if (flag) {
        //refresh account or party data if editing voucher from account or party page

        switch (modelKeyObj.key) {
          case 'ITEM_STATEMENT':
            modelKeyObj.itemId && dispatch(handleItemStatement(pageNo, modelKeyObj.itemId));
            break;
          case 'PARTY':
            refreshPartyOrAccountData(dispatch, getState, companyId, partyId);
            break;
          case 'REPORT':
            modelKeyObj.selectedId &&
              dispatch(
                showAccountStatementViewAction(modelKeyObj.selectedId, monthlyDetails.page || 1)
              );
            break;
          case 'HIDDEN_VOUCHER':
            if (modelKeyObj.filters) {
              const {
                filters: { selectedParty, selectedBranch, startDate, endDate }
              } = modelKeyObj;
              dispatch(
                getHideVoucher(
                  hiddenVoucherList.page || 1,
                  selectedParty,
                  selectedBranch,
                  startDate,
                  endDate
                )
              );
            }
            break;
          case 'VOUCHER':
            dispatch(
              fetchVouchers(
                companyId,
                startDate,
                endDate,
                _selectedVocuher.type,
                _selectedVocuher.subType
              )
            );
            dispatch(
              getVouchersList(
                _selectedVocuher.type,
                _vouchersList[_selectedVocuher.type]?.page || 1
              )
            );
            break;
          default:
            break;
        }
      }
      dispatch({
        type: DELETE_VOUCHER_SUCCESS
      });
      callback && callback();
    })
    .catch(error => {
      dispatch({
        type: DELETE_VOUCHER_FAILED
      });
      serverError(error);
    });
};

const handleMoveToChallan = (payload, voucherID) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId, primaryBranchId: branchId }
  } = getState();
  return api.handleMoveToChallan(companyId, branchId, voucherID, payload);
};

const getBranchDataSource = () => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .getBranchDataSource(companyId)
    .then(res => {
      dispatch({ type: GET_BRANCH_SUCCESS, payload: res });
    })
    .catch(err => {
      dispatch({ type: GET_BRANCH_FAILED, error: err });
    });
};

const showVoucherDetailsLink = showLinkValue => {
  return {
    type: TOGGLE_VOUCHER_DETAILS_LINK,
    showLinkValue: showLinkValue
  };
};

// const reconcileVoucher = (companyId, voucherId, data, storeKey) => dispatch => {
//   api
//     .reconcileVoucher(companyId, voucherId, data)
//     .then(response => {
//       dispatch({ type: RECONCILE_VOUCHER_SUCCESS, key: storeKey, payload: response });
//     })
//     .catch(error => dispatch({ type: RECONCILE_VOUCHER_FAILED, key: storeKey, payload: error }));
// };
/**
 * this function toggles the alert dialog, which is displayed on error.
 */
const toggleAlertDialog = () => {
  return {
    type: TOGGLE_ALERT_DIALOG
  };
};

/**
 * This function is toggle add transportation link.
 */
const toggleAddTransportLink = () => {
  return {
    type: TOGGLE_TRANSPORT_LINK
  };
};

/**
 * This function is toggle add sales Person link.
 */
const toggleAddSalesPersonLink = (flag = true) => {
  return {
    type: TOGGLE_SALES_PERSON_LINK,
    flag
  };
};

const togglePickUpLocationLink = (flag = true) => {
  return {
    type: TOOGLE_PICKUP_LOCATION_LINK,
    flag
  };
};

const fetchPaymentMethods = () => dispatch => {
  api
    .fetchPaymentMethods()
    .then(res => {
      dispatch({ type: FETCH_PAYMENT_METHOD_SUCCESS, payload: res });
    })
    .catch(err => {
      dispatch({ type: FETCH_PAYMENT_METHOD_FAILED, error: err });
    });
};
const fetchBankList = () => dispatch => {
  api
    .fetchBankList()
    .then(res => {
      dispatch({ type: FETCH_BANK_LIST_SUCCESS, payload: res });
    })
    .catch(err => {
      dispatch({ type: FETCH_BANK_LIST_FAILED, error: err });
    });
};

const setItemForExpenseVoucher = (itemList, index) => (dispatch, getState) => {
  dispatch({ type: ADD_EXPENSE_LINE, itemList, index });
  handleItemCalculations(dispatch, getState, EDIT_LINE);
};

const storeOldExpenseLineItem = index => dispatch => {
  dispatch({ type: STORE_OLD_LINE_ITEM, index: index });
};

const fetchVoucherTypes = () => dispatch => {
  api
    .fetchVoucherTypes()
    .then(res => {
      dispatch({ type: FETCH_VOUCHER_TYPES_SUCCESS, payload: res });
    })
    .catch(err => {
      dispatch({ type: FETCH_VOUCHER_TYPES_FAILED, error: err });
    });
};

export const fetchLineItemAvalQty = (itemId, endDate) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  let query = '';

  if (endDate) {
    query += `endDate=${getEndTimeOfDay(endDate)}`;
  }
  dispatch({ type: FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED, itemId });
  return getAvailableQty(iCompanyId, itemId, query)
    .then(response => {
      dispatch({ type: FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS, payload: response, itemId });
    })
    .catch(error => {
      dispatch({ type: FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED, itemId });
      console.log(error);
    });
};

const updateShippingGeoLoc = geoLoc => dispatch => {
  dispatch({ type: UPDATE_SHIPPING_GEO_LOC, geoLoc });
};

const fetchVoucherDueAmount = (voucherId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_VOUCHER_DUE_AMOUNT_REQUESTED });
  api
    .getVoucherDueAmouont(iCompanyId, voucherId)
    .then(res => {
      dispatch({ type: FETCH_VOUCHER_DUE_AMOUNT_SUCCESS });
      callback && callback(res);
    })
    .catch(err => {
      dispatch({ type: FETCH_VOUCHER_DUE_AMOUNT_FAILED });
    });
};

const updateUserVoucherTabsSettings = payload => (dispatch, getState) => {
  const {
    currentCompany: { id: comapanyId },
    user: {
      id: userId,
      userCompanySettings: { id: userSettingId }
    }
  } = getState();

  return updateUserCompanyVoucherSettings(comapanyId, userId, userSettingId, payload)
    .then(response => {
      dispatch(getUserCompanySettings());
    })
    .catch(error => {
      serverError(error);
    });
};

const handlePosBillingDrawer = status => dispatch => {
  dispatch({ type: SET_VOUCHER_CREATION_MODE, payload: status });
};

const searchNaration = (searchText, page = 1, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = `searchText=${searchText}`;
  return api
    .searchNaration(id, page, query)
    .then(res => {
      callback && callback(res.data);
    })
    .catch(error => {
      serverError(error);
    });
};

const getVoucherTemplates = (type, subType, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = `type=${type}`;
  if (subType) query += `&subType=${subType}`;
  return api
    .getVoucherTemplates(id, query)
    .then(res => {
      callback && callback(res.data);
    })
    .catch(error => console.log(error));
};
const createEInvoice = voucherId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .createEInvoice(id, voucherId)
    .then(res => {
      let result = res.results;
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      if (result && result.message) {
        res.results.message.EinvoicePdf &&
          window.open(res.results.message.EinvoicePdf, '_blank').focus();
        dispatch(getVoucherData(voucherId));
        if (!result.message.error) {
          let message = result.message.alert;
          message = isEmpty(message) ? 'E-Invoice created successfully' : message;
          dispatch(openSnackbar(message, SUCCESS));
        }
      } else {
        dispatch(openSnackbar(result.errorMessage, ERROR));
      }
    })
    .catch(error => {
      serverError(error);
    });
};

const createEwayBill = voucherId => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .createEwayBill(id, voucherId)
    .then(res => {
      let result = res.results;
      dispatch({ type: SAVED_VOUCHER_SUCCESS });
      if (result && result.message) {
        res.results.message.url &&
          window.open(`https://${res.results.message.url}`, '_blank').focus();
        dispatch(getVoucherData(voucherId));
        if (!result.message.error) {
          let message = result.message.alert;
          message = isEmpty(message) ? 'E-way bill created successfully' : message;
          dispatch(openSnackbar(message, SUCCESS));
        }
      } else {
        dispatch(openSnackbar(result.errorMessage, ERROR));
      }
    })
    .catch(error => {
      serverError(error);
    });
};

const cancelEInvoice = (voucherId, data, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let payload = {
    ...data
  };
  return api
    .cancelEInvoice(id, voucherId, payload)
    .then(res => {
      let result = res.results;
      if (result && result.message) {
        if (!result.message.error) {
          dispatch(openSnackbar('Your Invoice is canceled', SUCCESS));
        }
      } else {
        dispatch(openSnackbar(result.errorMessage, ERROR));
      }
      callback && callback();
    })
    .catch(error => {
      serverError(error);
      callback && callback();
    });
};

const cancelEwayBill = (voucherId, data, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let payload = {
    ...data
  };
  return api
    .cancelEwayBill(id, voucherId, payload)
    .then(res => {
      let result = res.results;
      if (result && result.message) {
        if (!result.message.error) {
          dispatch(openSnackbar('Your Invoice is canceled', SUCCESS));
        }
      } else {
        dispatch(openSnackbar(result.errorMessage, ERROR));
      }
      callback && callback();
    })
    .catch(error => {
      serverError(error);
      callback && callback();
    });
};

export const addItems = (item, index, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    items: { itemGroups }
  } = getState();

  let payload = cloneDeep(item);

  let itemAttributesDetails = payload.itemAttributesDetails;
  let boxQty = payload.boxQty;
  delete payload.itemAttributesDetails;
  delete payload.index;
  delete payload.boxQty;

  let rspRoundOff = payload?.rspRoundOff;
  let wspRoundOff = payload?.wspRoundOff;

  delete payload?.rspRoundOff;
  delete payload?.wspRoundOff;

  return addItem(id, payload)
    .then(response => {
      dispatch(openSnackbar('Item Saved Successfully', SUCCESS));
      callback &&
        callback(true, {
          ...response,
          itemAttributesDetails,
          index,
          boxQty,
          rspRoundOff,
          wspRoundOff
        });
    })
    .catch(error => {
      serverError(error);
      callback && callback(false);
    });
};

const setNarrationFilter = value => dispatch => {
  dispatch({ type: SET_NARRATION_FILTER, payload: value });
};

const gstSetupErrorMessage = () => dispatch => {
  dispatch(
    openSnackbar('You do not access of E-Invoicing and Ewaybill. Please contact Invock Team', ERROR)
  );
};

const resetSelectedVoucher = voucher => dispatch => {
  dispatch({ type: RESET_SELECTED_VOUCHER, voucherType: voucher });
};

const setPrintingDialogStatus = flag => dispatch => {
  dispatch({ type: SET_PRINTING_DIALOG_STATUS, openPrintModal: flag });
};

const setVoucherForPrint = (voucherId, type, subType) => dispatch => {
  dispatch({ type: SET_VOUCHER_FOR_PRINT, payload: { voucherId, type, subType } });
};

export {
  toggleAddSalesPersonLink,
  setLastVisitedVoucher,
  toggleViewTaxAnalysis,
  handleVoucherDetaislDone,
  increamentVoucherView,
  createReceiptVoucher,
  generateVoucherPdf,
  resetAndSetSPCDVoucherDrawer,
  setSPCDDetailsOnCreationDrawer,
  updateLineItemName,
  updateLineItem,
  resetAddDiscount,
  hideShippingDetailsLink,
  setCreatedItemInLineItems,
  updateVouchers,
  createChallan,
  resetTransportDetails,
  getShareVoucherData,
  onEditPurchaseVoucher,
  createPayment,
  createSales,
  // fetchBranches,
  setFilterDateRange,
  updateBusinessContact,
  addNewLineItem,
  deleteCharges,
  deleteOtherChargesAfterTax,
  toggleOtherCharges,
  addOtherCharges,
  toggleOtherChargesAfterTax,
  addOtherChargesAfterTax,
  getVoucher,
  getShareVoucher,
  shareVoucherPost,
  updateLineItemNameCrDr,
  internalNotesPost,
  lineItemIndex,
  setShippingAddress,
  togglePurchaseSalesCreationDrawer,
  createJournalVoucher,
  createContraVoucher,
  updateVoucherRefNumberCard,
  updateSelectedVoucher,
  updateAccount,
  updateAmount,
  fetchVouchers,
  showShippingDetails,
  deleteLineItem,
  setVoucherType,
  createPurchase,
  onSaveSPCDVoucher,
  updateAccountField,
  updateCharges,
  setDiscountUnits,
  setDiscountValue,
  updateLineItemField,
  discountChange,
  updateVouchersDetailsAccount,
  // handleItemCalculations,
  createCreditNote,
  createDebitNote,
  onUpdatebranchSearch,
  getSingleVoucher,
  resetVoucher,
  onCloseVoucherPicker,
  currentChargeIndexFocus,
  currentOtherChargesAfterTaxIndexFocus,
  resetShippingDetails,
  resetVoucherDetails,
  updateVoucherTypes,
  setVoucherDurationType,
  handleAccessToggleClick,
  handleRecordPaymentSubmitClick,
  onEditSalesVoucher,
  onEditStockTransferVoucher,
  onEditStockAdjustmentVoucher,
  onEditChallan,
  handleDeleteVoucherClick,
  handleMoveToChallan,
  getVoucherData,
  onEditCreditNote,
  onEditDebitNote,
  updateBusiness,
  setRoundOffValue,
  fetchSalesAccounts,
  fetchReceiptPaymentAccounts,
  closeBusinessContactCard,
  fetchPurchasesAccounts,
  fetchCurrentBalance,
  fetchUnpaidVouchers,
  handleNoteAddClick,
  updateDate,
  createRecordPayment,
  updateShippingAddress,
  updateShippingAddressName,
  getBranchDataSource,
  handleTransportDone,
  updateTransportDetails,
  showVoucherDetailsLink,
  toggleAlertDialog,
  updateTransportDetailsForSelectedVoucher,
  toggleAddTransportLink,
  closeAndResetVoucherFormState,
  updateJournalVoucher,
  updateContraVoucher,
  onEditContraVoucher,
  onEditJournalVoucher,
  getVouchersList,
  setChallanListForSPVoucher,
  handleDeleteChallanFromList,
  fetchBankList,
  fetchPaymentMethods,
  toggleAddEditDrawer,
  setRPVoucherDetailsOnCreationDrawer,
  onEditPaymentVoucher,
  onEditReceiptVoucher,
  getChallanLinkedToSPList,
  setActionSaveButtonDisable,
  onPreviewVoucher,
  fetchItemByVoucherId,
  createSalesLinkedSchallan,
  createSalesLinkedSestimationVoucher,
  onEditLinkedSchallan,
  onEditSalesLinkedSchallan,
  onSKUPreviewVoucher,
  setSupplierInvoiceNo,
  setSupplierInvoiceDate,
  setDueDate,
  setSelectedVoucherByNo,
  getRoundOffValue,
  onEditPchallanVoucher,
  getRateCodeValue,
  handleVoucherDetailBoxRenderOption,
  getShareVoucherPdf,
  toggleExclusiveDiscount,
  toggleExclusiveDiscountModal,
  createPurchaseLinkedPchallan,
  onEditPurchaseLinkedPchallan,
  onEditLinkedPchallan,
  setItemForExpenseVoucher,
  setRoundOffOffset,
  createExpenseVoucher,
  onEditExpenseVoucher,
  fetchVoucherTypes,
  getMergeEstimation,
  onEditSestimation,
  onEditPestimation,
  setReadLockOnVoucher,
  setupdateLockOnVoucher,
  createPurchaseLinkedPestimationVoucher,
  onEditSalesLinkedSestimation,
  onEditPurchaseLinkedPestimation,
  onEditLinkedSestimation,
  onEditLinkedPestimation,
  // fetchIndirectExpenseAccounts,
  openPrevVoucher,
  setFilterDate,
  resetVoucherItem,
  resetVoucherLineItems,
  onVoucherValidation,
  fetchPurchasesChallanAccounts,
  fetchPurchasesEstimationAccounts,
  fetchSalesChallanAccounts,
  fetchSalesEstimationAccounts,
  toggleEditExclusiveDiscountModal,
  updateShippingGeoLoc,
  setCalcStatus,
  voucherCalcToggle,
  refreshLineItem,
  setSelectedBranch,
  clearUnpaidVouchers,
  populateBillFinalAmount,
  populateBillTaxAmount,
  createRPfromSPVoucher,
  storeOldExpenseLineItem,
  replaceLineItem,
  voucherCal,
  onUpdateVoucherBranch,
  onUpdateInwardOutwardSubtype,
  onUpdateDestinationBranch,
  generateShareLink,
  fetchShareVoucherPublicStatus,
  updateSalesPersonDetails,
  getVoucherTypesForCurrentFinancialYear,
  updateGrpCodeMapAndCount,
  setRecordRPObjFromVoucherPayload,
  fetchVoucherDueAmount,
  onSKUV3PreviewVoucher,
  preserveVoucherDate,
  handlePosBillingDrawer,
  searchNaration,
  setNarrationFilter,
  updateUserVoucherTabsSettings,
  getVoucherTemplates,
  createEInvoice,
  createEwayBill,
  gstSetupErrorMessage,
  cancelEInvoice,
  cancelEwayBill,
  togglePickUpLocationLink,
  updatePickUpLocationDetails,
  calculateOtherChargeAmount,
  reCalculateOtherChargeAmount,
  calculateOtherChargeAfterTaxAmount,
  reCalculateOtherChargeAfterTaxAmount,
  resetSelectedVoucher,
  setCRDRSubType,
  resetLineItems,
  getAmountWithoutTax,
  setPrintingDialogStatus,
  setVoucherForPrint,
  fetchVoucherAccessList
};
export const recalculateVoucher = action => (dispatch, getState) => {
  const {
    vouchers,
    currentCompany: { currencyDecimals, itemQtyDecimals },
    vouchers: {
      type,
      // _lineItems,
      _otherCharges,
      _selectedVoucherId,
      _branches,
      _selectedBranch,
      _otherChargesAfterTax,
      selectedPartyAccount,
      selectedPartyAccount: { salesDiscounts = [], state: partyState },
      couponDiscount,
      _roundOffOffset,
      // oldLineItem: oldLine,
      app: {
        exclusiveDiscountFlag: hasExclusiveDiscount
        // calcEnabled = true,
        // lineItem: { currentItemIndex }
      }
    }
  } = getState();
  const initParams = {
    voucherId: _selectedVoucherId,
    itemList: [],
    additionalDiscount: vouchers.purchase.additionalDiscount,
    hasExclusiveDiscount: hasExclusiveDiscount,
    salesDiscount: salesDiscounts || [],
    couponDiscount: couponDiscount || 0,
    otherCharges: _otherCharges,
    partyId: selectedPartyAccount.id || '',
    partyGstBusinessTypeRef:
      getPartyGstBusinessTypeRef(type, selectedPartyAccount, _branches, _selectedBranch) || '',
    otherChargesAfterTax: _otherChargesAfterTax,
    adjustments: _roundOffOffset || 0,
    partyState: partyState,
    branchState: _selectedBranch.state,
    currencyDecimals,
    itemQtyDecimals
  };
  initVoucherCalc(initParams);
  handleItemCalculations(dispatch, getState, action);
};

function getBranchById(_branches = [], branchId = '') {
  return _branches.filter(branch => {
    if (branchId === branch.id) {
      return branch;
    }
    return null;
  })[0];
}

function refreshPartyOrAccountData(dispatch, getState, companyId, id) {
  const {
    contacts: { refAccountId, partyStatement, unreconciledVoucherStatement }
  } = getState();

  dispatch(
    handleStatementPaginate(
      companyId,
      refAccountId,
      null,
      null,
      PARTY_STATEMENT,
      partyStatement.page || 1
    )
  );
  dispatch(
    fetchAccountReconciledVouchersGroupByDate(
      companyId,
      refAccountId,
      'reconciledVouchersGroupByDate',
      1
    )
  );
  dispatch(
    fetchUnreconciledVouchers(
      companyId,
      refAccountId,
      UNRECONCILED_VOUCHER_STATEMENT,
      unreconciledVoucherStatement.page || 1
    )
  );
}
