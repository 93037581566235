import {
  ITEMS_DATA,
  TEMP_ITEM_DATA,
  EDIT_LINE,
  ERROR,
  ITEM_CODE_SEPERATOR,
  ITEM_NAME_SEPERATOR,
  ITEM,
  ITEM_GROUP,
  ITEM_TAGS_KEY,
  FILTERS_KEY,
  ADD_CATALOGUE_ITEM_TABLE,
  GROUPED_ITEMS_TABLE,
  ITEM_VARIANT_FILTER_KEY,
  TABLE_ASCENDING,
  ASCENDING,
  DESCENDING,
  GROUPED_ITEM_VARIANTS_TABLE,
  CATALOGUE_FILTERS_KEY,
  CATALOGUE_ITEM_TABLE,
  MR_COMPANY_ID,
  RECALCULATE,
  PERCENT,
  ATTRIBUTE_1,
  ATTRIBUTE_2,
  ATTRIBUTE_3,
  VENDOR_DESIGN_CODE,
  GROUPED_UNIT,
  RETAIL_PRICE_LABEL,
  WHOLESALE_PRICE_LABEL,
  PURCHASE_PRICE_LABEL,
  HIDDEN_ITEM_FILERS_KEY,
  DESCRIPTION,
  SHOW_ISHOP,
  LOW_STOCK_ALERT_QTY
} from '../constants';
import mixpanel from 'mixpanel-browser';
import VoucherCalModule from 'voucher-calculation';

import {
  COMPANY_ITEMS_REQUESTED,
  COMPANY_ITEMS_SUCCESS,
  SHOW_ITEM_GROUP_DIALOG,
  COMPANY_ITEMS_FAILED,
  TOGGLE_ADD_ITEMS,
  SET_ITEM_GROUP,
  UPDATE_SELECTED_ITEM_GROUP,
  RESET_SELECTED_ITEM_GROUP,
  SET_ALL_ITEM_GROUP,
  SET_ITEM_PAYLOAD,
  SHOW_ITEM_SALES_PRICE,
  CANCEL_ITEM_SALES_PRICE,
  RESET_ITEM_PAYLOAD,
  SET_ITEM_GROUP_SEARCH_TEXT,
  SHOW_SALES_PRICE_VALUE,
  SET_ITEM_ERROR,
  SET_ITEM_DATA,
  SET_ITEM_PROPERTY,
  SET_ITEMS_BY_GROUP,
  SET_TAB_VIEW_ITEM_GROUP,
  SHOW_EDIT_ITEM_GROUP_DIALOG,
  SET_UPDATED_ITEM_GROUP,
  EDIT_ITEM_MODE,
  SET_UPDATED_ITEM,
  EXPAND_ITEMS,
  ADD_VOUCHER_PARTY_TO_ITEM,
  ADD_LINE_ITEM,
  TOGGLE_ITEM_ALERT_DIALOG,
  SET_IMAGE_LIST,
  SNACKBAR_OPEN,
  // SET_ALL_ITEM_COLLECTIONS,
  // SET_ALL_ITEM_BASE_MATERIALS,
  // SET_ALL_ITEM_COLORS,
  RESET_VARIANT_ITEM,
  TOGGLE_PERSIST_MODE,
  SET_PERSIST_PROPERTY,
  UPDATE_LINE_ITEM,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_FAILURE,
  UPDATE_ROUNDOFF_RANGE_VALUE,
  ADD_RANGE_TO_ITEM_GROUP,
  DELETE_RANGE_FROM_ITEM_GROUP,
  UPDATE_RATE_CODE_VALUE,
  SET_ITEM_UNITS,
  RESET_TAB_VIEW_ITEM_GROUP,
  ACTION_SAVE_BUTTON_DISABLE,
  SET_WSP_ROUND_OFF_FLAG,
  SET_RSP_ROUND_OFF_FLAG,
  SET_SP_LOCK,
  FETCH_PARTY_DESIGN_CODES_SUCCESS,
  FETCH_PARTY_DESIGN_CODES_FAILED,
  FETCH_PARTY_DESIGN_CODES_REQUESTED,
  TOGGLE_ITEM_VARIANT,
  SET_ITEM_FETCHED,
  SAVE_ITEM_GROUP_REQUESTED,
  SAVE_ITEM_GROUP_FAILURE,
  SAVE_ITEM_GROUP_SUCCESS,
  ADD_ITEM_ATTRIBUTE_REQUESTED,
  ADD_ITEM_ATTRIBUTE_SUCCESS,
  ADD_ITEM_ATTRIBUTE_FAILED,
  FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED,
  FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS,
  FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED,
  FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  FETCH_ITEM_ATTRIBUTE_VALUE_FAILED,
  ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  ADD_ITEM_ATTRIBUTE_VALUE_FAILED,
  SET_ITEM_PARTY_ID,
  FETCH_ALL_ITEM_GROUP_REQUESTED,
  FETCH_ALL_ITEM_GROUP_SUCCESS,
  FETCH_ALL_ITEM_GROUP_FAILED,
  FETCH_ITEM_ATTRIBUTES_REQUESTED,
  FETCH_ITEM_ATTRIBUTES_SUCCESS,
  FETCH_ITEM_ATTRIBUTES_FAILED,
  SET_ATTRIBUTE_VALUE,
  SET_ITEM_ATTRIBUTE_CONFIG,
  UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG,
  SET_NAME_AND_CODE,
  FETCH_ITEM_GROUPS_BY_HSN_REQUESTED,
  FETCH_ITEM_GROUPS_BY_HSN_SUCCESS,
  FETCH_ITEM_GROUPS_BY_HSN_FAILED,
  ON_EDIT_ATTRIBUTE_VALUE,
  UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED,
  TOGGLE_ATTRIBUTE_VALUE_FORM,
  SET_ATTRIBUTE_FILTER,
  SET_ITEM_TYPE_FILTER,
  SET_ATTRIBUTE_FILTER_FROM_MENU,
  RESET_ATTRIBUTE_FILTER,
  SET_ITEM_GROUPS_FILTER,
  CLEAR_UPDATE_ATTRIBUTE_FILTER,
  UPDATE_ITEM_ATTRIBUTE_REQUESTED,
  UPDATE_ITEM_ATTRIBUTE_SUCCESS,
  UPDATE_ITEM_ATTRIBUTE_FAILED,
  DELETE_ITEM_ATTRIBUTE_REQUESTED,
  DELETE_ITEM_ATTRIBUTE_SUCCESS,
  DELETE_ITEM_ATTRIBUTE_FAILED,
  FETCH_ITEMS_MASTER_REQUESTED,
  FETCH_ITEMS_MASTER_SUCCESS,
  FETCH_ITEMS_MASTER_FAILED,
  TABLE_LOADING,
  ITEM_CREATION_REQUESTED,
  ITEM_CREATION_FAILED,
  ITEM_CREATION_SUCCESS,
  ITEM_UPDATE_REQUESTED,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAILED,
  HIDDEN_ITEM_LIST_REQUESTED,
  HIDDEN_ITEM_LIST_SUCCESS,
  HIDDEN_ITEM_LIST_FAILED,
  FETCH_ITEM_TAGS_REQUESTED,
  FETCH_ITEM_TAGS_SUCCESS,
  FETCH_ITEM_TAGS_FAILED,
  ADD_ITEM_TAG_REQUESTED,
  ADD_ITEM_TAG_SUCCESS,
  ADD_ITEM_TAG_FAILED,
  ADD_ITEM_TAG_IN_SELECTED,
  ADD_TAGS_TO_ITEMS_REQUESTED,
  ADD_TAGS_TO_ITEMS_SUCCESS,
  ADD_TAGS_TO_ITEMS_FAILED,
  SET_ITEM_TAGS_FILTER,
  UPDATE_ITEM_TAG_REQUESTED,
  UPDATE_ITEM_TAG_SUCCESS,
  UPDATE_ITEM_TAG_FAILED,
  DELETE_ITEM_TAG_REQUESTED,
  DELETE_ITEM_TAG_SUCCESS,
  DELETE_ITEM_TAG_IN_SELECTED,
  DELETE_ITEM_TAG_FAILED,
  UPDATE_ITEM_TAG_IN_SELECTED,
  REMOVE_TAGS_FROM_ITEMS_REQUESTED,
  REMOVE_TAGS_FROM_ITEMS_SUCCESS,
  REMOVE_TAGS_FROM_ITEMS_FAILED,
  SET_ITEM_TAGS,
  SET_AVAILABLE_QTY,
  GET_AVAILABLE_QTY_REQUESTED,
  GET_AVAILABLE_QTY_FAILED,
  TOGGLE_QTY_FILTER,
  SET_ITEM_ATTRIBUTE_VALUE_KEYED,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE,
  SET_SELECTED_CATALOGUE,
  FETCH_CATALOGUE_ITEMS_REQUEST,
  FETCH_CATALOGUE_ITEMS_SUCCESS,
  FETCH_CATALOGUE_ITEMS_FAILURE,
  TOGGLE_EDIT_CATALOGUE_ITEMS_MODE,
  SELECT_RESET,
  FETCH_ITEMS_GROUPED_REQUESTED,
  FETCH_ITEMS_GROUPED_SUCCESS,
  FETCH_ITEMS_GROUPED_FAILED,
  FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED,
  FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS,
  FETCH_ITEMS_GROUPED_VARIANTS_FAILED,
  ITEM_VARIANT_SELECT_ALL,
  SET_ITEM_PRICE_FILTER_BY,
  SET_ITEM_PRICE_FILTER_VALUE,
  FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS,
  SET_GROUPED_ITEM_UNITS,
  FETCH_ITEM_GROUP_REQUESTED,
  FETCH_ITEM_GROUP_SUCCESS,
  FETCH_ITEM_GROUP_FAILED,
  RESET_ITEM_AVAILABLE_QTY_LIST,
  RESET_ITEM_GROUP_PAYLOAD,
  SET_ISHOP_STATUS_FILTER,
  ITEM_SEARCH_TEXT,
  RESET_SELECTED_ROW_IDS,
  RESET_ITEM_GROUP_PAGE_NO,
  SET_ITEM_FILTER_TOGGLE_LIST,
  SET_ITEM_BULK_TOGGLE,
  SET_BULK_ITEMS,
  RESET_BULK_ITEM,
  REMOVE_ITEM,
  SET_OUT_OF_STOCK_FILTER
} from './types';

import { serverError } from '../api/server';
import * as api from '../api/companies';
import {
  getdocumentSchema,
  getTaxPercentageAndHsn,
  roundOffFlag,
  getfloatingValue,
  getItemGroupTableId,
  getPageFromPath,
  getValuesByKey,
  extractIdsFromSelection,
  getSearchParamsValue
} from '../utils';
import { handleItemCalculations } from '../utils/voucherCalculationFn';
import forEach from 'lodash/forEach';
import { updateBusiness, getRateCodeValue, getRoundOffValue, recalculateVoucher } from './vouchers';
import { fetchPartyDesignCodes } from '../api/contacts';
import { openSnackbar } from './snackbar';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import { getPartyById } from './contacts';
import { cloneDeep, isEqual, map } from 'lodash';
import {
  getStatus,
  getProgressTracker,
  getUserCompanySettings,
  updateUserItemFilterSettings,
  updateUserCompanyBulkItemSettings
} from './companies';
import { getItemGroupTrackerById } from '../reducers';
import { fetchAccountsCurrentBalance } from '.';

export const storeItems = payload => {
  return {
    type: ITEMS_DATA,
    payload: payload
  };
};

const handleRoundOffFlag = (roundOff = []) => dispatch => {
  const hasRoundOffConfiguration = roundOffFlag(roundOff);
  dispatch(setWSPRoundOffFlag(hasRoundOffConfiguration));
  dispatch(setRSPRoundOffFlag(hasRoundOffConfiguration));
};

export const getAndSetSignedUrl = (file, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .getUploadSignedUrl(id, { fileType: file.type })
    .then(urlData => {
      api.uploadFile(urlData.signedUrl, file).then(() => {
        callback(urlData.url);
      });
    })
    .catch(err => console.log(err));
};

export const setWSPRoundOffFlag = value => {
  return {
    type: SET_WSP_ROUND_OFF_FLAG,
    payload: value
  };
};

export const setRSPRoundOffFlag = value => {
  return {
    type: SET_RSP_ROUND_OFF_FLAG,
    payload: value
  };
};

export const setSPLock = value => {
  return {
    type: SET_SP_LOCK,
    payload: value
  };
};

/**
 *
 * @param {array} value
 */

export const setAttributesFilter = (attributeId, attributeValueIds, storeKey = FILTERS_KEY) => {
  return {
    type: SET_ATTRIBUTE_FILTER,
    attributeId,
    attributeValueIds,
    storeKey
  };
};

export const setItemTagsFilter = (tags, storeKey = FILTERS_KEY) => {
  return { type: SET_ITEM_TAGS_FILTER, tags, storeKey };
};

export const resetAttributesFilter = (storeKey = FILTERS_KEY) => {
  return { type: RESET_ATTRIBUTE_FILTER, storeKey };
};

export const setItemGroupsFilter = (value, storeKey = FILTERS_KEY) => dispatch => {
  dispatch({
    type: SET_ITEM_GROUPS_FILTER,
    payload: value,
    storeKey
  });
  dispatch(updateAttributesFilter(value));
};

export const updateAttributesFilter = (selectedItemGroups, storeKey = FILTERS_KEY) => dispatch => {
  dispatch({ type: CLEAR_UPDATE_ATTRIBUTE_FILTER, selectedItemGroups, storeKey });
};

export const setAttributesFilterFromMenu = (attributeId, attributeValue) => {
  return {
    type: SET_ATTRIBUTE_FILTER_FROM_MENU,
    attributeId,
    attributeValue
  };
};

/**
 *
 * @param {Boolean} value if true then basic item is filtered out
 */
export const setItemTypeFilter = (value, storeKey = FILTERS_KEY) => {
  return {
    type: SET_ITEM_TYPE_FILTER,
    payload: value,
    storeKey
  };
};

export const setItemIShopStatusFilter = (value, storeKey = FILTERS_KEY) => dispatch => {
  dispatch({
    type: SET_ISHOP_STATUS_FILTER,
    payload: value,
    storeKey
  });
};

export const setItemOutOfStockFilter = (value, storeKey = FILTERS_KEY) => dispatch => {
  dispatch({
    type: SET_OUT_OF_STOCK_FILTER,
    payload: value,
    storeKey
  });
};

export const handleWSPAutoRoundOff = (checked, callBack) => (dispatch, getState) => {
  const {
    items: {
      payload: { unitSellWholeSalePrice, wspAdjustment }
    }
  } = getState();
  dispatch(setWSPRoundOffFlag(checked));
  if (checked) {
    dispatch(handleRoundoffCalculation('unitSellWholeSalePrice', unitSellWholeSalePrice, callBack));
  } else {
    dispatch(setItemPayload('unitSellWholeSalePrice', unitSellWholeSalePrice - wspAdjustment));
    callBack && callBack(unitSellWholeSalePrice - wspAdjustment);
    dispatch(setItemPayload('wspAdjustment', 0));
  }
};

export const handleRSPAutoRoundOff = checked => (dispatch, getState) => {
  const {
    items: {
      payload: { unitSellRetailPrice, rspAdjustment }
    }
  } = getState();
  dispatch(setRSPRoundOffFlag(checked));
  if (checked) {
    dispatch(handleRoundoffCalculation('unitSellRetailPrice', unitSellRetailPrice));
  } else {
    dispatch(setItemPayload('unitSellRetailPrice', unitSellRetailPrice - rspAdjustment));
    dispatch(setItemPayload('rspAdjustment', 0));
  }
};

export const handleRoundoffCalculation = (propertyName, initValue, callBack) => (
  dispatch,
  getState
) => {
  const {
    items: {
      tabViewItemGroup: { rateCode, roundOff },
      payload: { unitSellWholeSalePrice, unitSellRetailPrice }
    }
  } = getState();
  getRoundOffValue(initValue, roundOff, res => {
    dispatch(setItemPayload(propertyName, res));

    if (propertyName === 'unitSellWholeSalePrice') {
      dispatch(setItemPayload('wspAdjustment', res - initValue));
      callBack && callBack(res);
    } else if (propertyName === 'unitSellRetailPrice') {
      dispatch(setItemPayload('rspAdjustment', res - initValue));
    }
    getRateCodeValue(unitSellWholeSalePrice, unitSellRetailPrice, rateCode, value => {
      dispatch(setItemPayload('rateCode', value));
    });
  });
};

export const storeTempItemData = payload => {
  return {
    type: TEMP_ITEM_DATA,
    payload: payload
  };
};

export const resetTabViewItemGroup = () => {
  return {
    type: RESET_TAB_VIEW_ITEM_GROUP
  };
};

export const handleDeleteImage = (imageId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .deleteImage(id, imageId)
    .then(() => {
      dispatch(openSnackbar('Image deleted Successfully'));
      callback && callback();
    })
    .catch(error => console.log(error));
};

export const fetchCompanyItems = () => (dispatch, getState) => {
  const { currentCompany } = getState();
  dispatch({ type: COMPANY_ITEMS_REQUESTED, payload: {} });

  api
    .fetchCompanyItems(currentCompany.id)
    .then(response => {
      dispatch({ type: COMPANY_ITEMS_SUCCESS, payload: response });
      dispatch({ type: ITEMS_DATA, payload: response });
    })
    .catch(error => dispatch({ type: COMPANY_ITEMS_FAILED, payload: error }));
};

export const fetchCompanyItem = (itemId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  return api
    .getItemWithAttributeDetails(id, itemId)
    .then(response => {
      dispatch({
        type: SET_ITEM_FETCHED,
        payload: response
      });
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};

export const exportAllItemsToCSV = callBack => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();

  return api
    .exportAllItems(id, {})
    .then(response => {
      getStatus(dispatch, response.id, 'items');
      callBack && callBack();
    })
    .catch(error => {
      serverError(error);
    });
};

export const exportAllItemsOpeningStockToCSV = callback => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();

  return api
    .exportAllItemsMasterOpeningStock(id, {})
    .then(response => {
      getStatus(dispatch, response.id, 'ItemsOpeningStock');
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

export const exportItemsMaster = (filterKey = FILTERS_KEY, selectedItem) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: {
        selectedAttributeValueIds,
        itemType,
        selectedItemGroups,
        selectedItemTags,
        outOfStockFilter,
        ishopFilterStatus
      },
      // _selectedItemGroup: { id: selectedItemGroupId },
      tabViewItemGroup: { id: tabSelectedItemGroupId }
    }
  } = getState();
  let query = `isBasicType=${itemType}&includeCurrentQtyToList=true`;

  if (tabSelectedItemGroupId) {
    query += `&itemGroupId=${tabSelectedItemGroupId}`;
  } else if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${getValuesByKey(selectedAttributeValueIds[key]).toString()}`;
      }
    });
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }

  if (selectedItem && selectedItem.length > 0) {
    query += `&itemIds=${getValuesByKey(selectedItem).toString()}`;
  }

  if (outOfStockFilter !== 'all' && outOfStockFilter !== undefined && outOfStockFilter !== null) {
    query += `&outOfStockFilter=${outOfStockFilter}`;
  }

  if (ishopFilterStatus !== 'all') {
    query += `&eShopVisibility=${ishopFilterStatus}`;
  }

  dispatch({ type: TABLE_LOADING, payload: true });

  return api
    .exportItems(id, query)
    .then(response => {
      dispatch({ type: TABLE_LOADING, payload: false });
      getStatus(dispatch, response.id, 'items');
    })
    .catch(error => {
      dispatch({ type: TABLE_LOADING, payload: false });
      serverError(error);
    });
};

export const exportAllItemsMaster = (filterKey = FILTERS_KEY, selectedItem) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: { selectedAttributeValueIds, itemType, selectedItemTags }
    }
  } = getState();
  let query = `isBasicType=${itemType}&includeCurrentQtyToList=true`;

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${getValuesByKey(selectedAttributeValueIds[key]).toString()}`;
      }
    });
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }

  if (selectedItem.length > 0) {
    query += `&itemIds=${getValuesByKey(selectedItem).toString()}`;
  }

  return api
    .exportAllItemMaster(id, query)
    .then(response => {
      dispatch({ type: TABLE_LOADING, payload: false });
      getStatus(dispatch, response.id, 'items');
    })
    .catch(error => {
      dispatch({ type: TABLE_LOADING, payload: false });
      serverError(error);
    });
};

export const exportAndUploadItemsMaster = (filterKey = FILTERS_KEY) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: {
        selectedAttributeValueIds,
        itemType,
        selectedItemGroups,
        selectedItemTags,
        outOfStockFilter
      },
      tabViewItemGroup: { id: tabSelectedItemGroupId }
    }
  } = getState();
  let query = `isBasicType=${itemType}&includeCurrentQtyToList=true`;

  if (tabSelectedItemGroupId) {
    query += `&itemGroupId=${tabSelectedItemGroupId}`;
  } else if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${getValuesByKey(selectedAttributeValueIds[key]).toString()}`;
      }
    });
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }
  return api
    .exportItems(id, query)
    .then(response => {
      getStatus(dispatch, response.id, 'items', r => {
        // this function only trigger for mr
        if (id === MR_COMPANY_ID) {
          dispatch(openSnackbar('File upload started'));
          dispatch(uploadMRStockAppFile(r));
        } else dispatch(openSnackbar('Upload service is not avaiable!', ERROR));
      });
    })
    .catch(error => {
      serverError(error);
    });
};

export const fetchItemsMaster = (page = 1, key, filterKey = FILTERS_KEY, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: {
        selectedAttributeValueIds,
        itemType,
        selectedItemGroups,
        selectedItemTags,
        showQty = false,
        selectedPriceFilterBy,
        selectedPriceFilterValue: { min, max },
        ishopFilterStatus,
        outOfStockFilter
      },
      itemSearch,
      // _selectedItemGroup: { id: selectedItemGroupId },
      tabViewItemGroup: { id: tabSelectedItemGroupId }
    },
    table: { sorting }
  } = getState();
  let query = `isBasicType=${itemType}`;
  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  if (itemSearch.searchText) {
    query += `&searchText=${itemSearch.searchText}`;
    query += itemSearch.barcodeSearchOn ? `&searchBy=numericSkuBarcode` : '';
  }

  if (
    !itemSearch.barcodeSearchOn ||
    (itemSearch.barcodeSearchOn && itemSearch.searchText?.length === 0)
  ) {
    if (tabSelectedItemGroupId && filterKey === FILTERS_KEY) {
      query += `&itemGroupId=${tabSelectedItemGroupId}`;
    } else if (selectedItemGroups && selectedItemGroups.length > 0) {
      query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
    }
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${getValuesByKey(selectedAttributeValueIds[key]).toString()}`;
      }
    });
  }

  if (selectedPriceFilterBy && selectedPriceFilterBy.value) {
    if (min !== '') {
      query += `&${selectedPriceFilterBy.value}Min=${min}`;
    }

    if (max !== '') {
      query += `&${selectedPriceFilterBy.value}Max=${max}`;
    }
  }

  if (sorting.length > 0 && sorting[0].columnName) {
    query += `&sortBy=${sorting[0].columnName} ${
      sorting[0].direction === TABLE_ASCENDING ? ASCENDING : DESCENDING
    }`;
  } else {
    query += `&sortBy=createdAt DESC`;
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }

  if (
    ishopFilterStatus !== 'all' &&
    ishopFilterStatus !== undefined &&
    ishopFilterStatus !== null
  ) {
    query += `&eShopVisibility=${ishopFilterStatus}`;
  }

  if (outOfStockFilter !== 'all' && outOfStockFilter !== undefined && outOfStockFilter !== null) {
    query += `&outOfStockFilter=${outOfStockFilter}`;
  }

  dispatch({ type: FETCH_ITEMS_MASTER_REQUESTED });
  dispatch({ type: TABLE_LOADING, payload: true });
  return api
    .fetchItemsMaster(id, page, query)
    .then(response => {
      dispatch({
        type: SET_ITEMS_BY_GROUP,
        payload: response,
        key
      });
      dispatch({ type: FETCH_ITEMS_MASTER_SUCCESS });
      dispatch({ type: TABLE_LOADING, payload: false });
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: TABLE_LOADING, payload: false });
      dispatch({ type: FETCH_ITEMS_MASTER_FAILED });
    });
};

export const searchItemText = (key, value) => dispatch => {
  dispatch({ type: ITEM_SEARCH_TEXT, key, value });
};

// request is used by item master which follow the ACL
export const searchItem = (
  page,
  groupId,
  barcodeSearchOn,
  searchText,
  filterKey = FILTERS_KEY,
  onlyShowHiddenItems,
  callback
) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    items: {
      [filterKey]: { showQty = false }
    }
  } = getState();

  let query = '';

  if (groupId && !barcodeSearchOn) {
    query += `&itemGroupId=${groupId}`;
  }

  if (searchText) {
    query += `&searchText=${searchText}`;
  }

  if (barcodeSearchOn) {
    query += `&searchBy=numericSkuBarcode`;
  }

  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  if (onlyShowHiddenItems) {
    query += `&isHidden=${onlyShowHiddenItems}`;
  }
  return api
    .fetchItemsMaster(id, page, query)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};

export const itemSearch = (
  query,
  activateLineItemAttributeFilterConfig,
  useSearchByBarcode,
  selectedAttributeValueIds,
  activateLineItemPartyConfig,
  selectedItemGroups,
  selectedParty,
  basicFilterOn,
  hitsPerPage,
  callback
) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let itemAttributes = [];
  let attributeConfig = [];
  let filter = '';

  if (query !== '') {
    filter += useSearchByBarcode
      ? `searchBy=numericSkuBarcode&searchText=${query}`
      : `searchBy=name&searchText=${query}`;
  }

  if (activateLineItemAttributeFilterConfig) {
    if (Object.keys(selectedAttributeValueIds).length > 0) {
      Object.keys(selectedAttributeValueIds).forEach(function(key) {
        if (selectedAttributeValueIds[key]) {
          filter += `&${key}=${selectedAttributeValueIds[key].value}`;
        }
      });
    }
    if (selectedItemGroups) {
      filter = filter + `&itemGroupId=${selectedItemGroups.value}`;
    }
    if (activateLineItemPartyConfig && selectedParty && !basicFilterOn) {
      filter =
        filter + `&partyId=${selectedParty}&isBasicType=true&activateLineItemPartyConfig=true`;
    }
  }
  if (basicFilterOn) {
    filter += `&isBasicType=true`;
  }
  if (itemAttributes.length > 0 && attributeConfig.length > 0) {
    filter += `&itemAttributes=${itemAttributes.toString()}&attributeConfig=${attributeConfig.toString()}`;
  }

  filter += `&hitsPerPage=${hitsPerPage}&page=${1}&sortBy=createdAt DESC`;
  api
    .itemSearch(id, filter)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};

export const fetchGroupedItems = (
  page = 1,
  key = GROUPED_ITEMS_TABLE,
  filterKey = FILTERS_KEY,
  callback
) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: {
        selectedAttributeValueIds,
        itemType,
        selectedItemGroups,
        selectedItemTags,
        showQty = false
      },
      // _selectedItemGroup: { id: selectedItemGroupId },
      tabViewItemGroup: { id: tabSelectedItemGroupId }
    }
  } = getState();
  let query = `isBasicType=${itemType}`;
  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  if (tabSelectedItemGroupId && filterKey === FILTERS_KEY) {
    query += `&itemGroupId=${tabSelectedItemGroupId}`;
  } else if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${getValuesByKey(selectedAttributeValueIds[key]).toString()}`;
      }
    });
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }
  dispatch({ type: FETCH_ITEMS_GROUPED_REQUESTED });
  dispatch({ type: TABLE_LOADING, payload: true });
  return api
    .fetchGroupedItems(id, page, query)
    .then(response => {
      dispatch({ type: FETCH_ITEMS_GROUPED_SUCCESS, key, payload: response });
      dispatch({ type: TABLE_LOADING, payload: false });
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: TABLE_LOADING, payload: false });
      dispatch({ type: FETCH_ITEMS_GROUPED_FAILED });
    });
};
export const variantTableSelectAll = (rows, tableId) => dispatch => {
  dispatch({
    type: ITEM_VARIANT_SELECT_ALL,
    rows,
    tableId,
    selection: [...Array(rows.length).keys()]
  });
};
export const fetchGroupedItemsVariants = (
  page = 1,
  itemAttributeValueId,
  key = GROUPED_ITEM_VARIANTS_TABLE,
  filterKey = FILTERS_KEY,
  callback
) => (dispatch, getState) => {
  if (!itemAttributeValueId) return;
  const {
    currentCompany: { id },
    vouchers: {
      [filterKey]: { selectedParty }
    },
    items: {
      [filterKey]: { selectedAttributeValueIds, selectedItemGroups },
      [FILTERS_KEY]: { showQty = false }, //grouped variants qty filter is common
      // _selectedItemGroup: { id: selectedItemGroupId },
      tabViewItemGroup: { id: tabSelectedItemGroupId }
    }
  } = getState();
  // let query = `isBasicType=${itemType}`;
  let query = ``;
  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  if (
    tabSelectedItemGroupId &&
    (filterKey === FILTERS_KEY || filterKey === ITEM_VARIANT_FILTER_KEY)
  ) {
    query += `&itemGroupId=${tabSelectedItemGroupId}`;
  } else if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
  }

  // if (selectedItemTags && selectedItemTags.length > 0) {
  //   query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  // }
  if (Object.keys(selectedAttributeValueIds).length > 0) {
    Object.keys(selectedAttributeValueIds).forEach(function(key) {
      if (selectedAttributeValueIds[key]) {
        query += `&${key}=${selectedAttributeValueIds[key].id}`;
      }
    });
  }

  if (selectedParty.length > 0) {
    query += `&partyId=${getValuesByKey(selectedParty).toString()}`;
  }
  dispatch({ type: FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED, key, itemAttributeValueId, page });
  return api
    .fetchGroupedItemsVariants(id, page, itemAttributeValueId, query)
    .then(response => {
      dispatch({
        type: FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS,
        key,
        itemAttributeValueId,
        payload: response
      });

      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: FETCH_ITEMS_GROUPED_VARIANTS_FAILED, key, itemAttributeValueId });
    });
};

export const setItemGroupItems = (payload, key) => ({
  type: SET_ITEMS_BY_GROUP,
  payload: payload,
  key
});

const generatedItemNameAndCode = getState => {
  const {
    itemAttributes: { attributeValuesKeyed },
    items: {
      payload: { itemGroupId, attributeConfig, itemAttributes },
      itemGroups
    }
  } = getState();
  const filteredItemGroup = itemGroups.filter(item => item.id === itemGroupId);
  const currentItemGroup =
    filteredItemGroup.length > 0
      ? filteredItemGroup[0]
      : {
          name: '',
          code: ''
        };
  const { name: itemGroupName, code: itemGroupCode, isBasicGroup } = currentItemGroup;
  console.log(isBasicGroup);
  let nameArray = [];
  let codeArray = [];
  itemGroupCode && codeArray.push(itemGroupCode);
  itemGroupName && !itemGroupName.includes('Group') && nameArray.push(itemGroupName);
  reduce(
    attributeConfig,
    (prev, currentAtt, index) => {
      const currAttValueId = itemAttributes[index];
      const currValueKeyedList = attributeValuesKeyed[currentAtt] || {};

      const { name: currName, code: currCode } = currValueKeyedList[currAttValueId] || {};
      // find(currValueList, curr => curr.id === currAttValueId) || {};
      currName && prev.nameArray.push(currName);
      currCode && prev.codeArray.push(currCode);
      return prev;
    },
    { nameArray, codeArray }
  );
  // itemGroupName && isBasicGroup && nameArray.push(`(${itemGroupName})`);
  const name = nameArray.join(ITEM_NAME_SEPERATOR);
  const itemCode = codeArray.join(ITEM_CODE_SEPERATOR);

  return { name, itemCode };
};

// const OLD_generatedItemNameAndCode = (property, data, getState) => {
//   const {
//     items: {
//       // _selectedItemGroup: { name: itemGroupName, code: itemGroupCode },
//       collectionType: { name: collectionTypeName, code: collectionTypeCode },
//       baseMaterial: { name: baseMaterialName, code: baseMaterialCode },
//       color: { name: colorName, code: colorCode },
//       payload: { name: payloadName, itemCode: payloadCode, itemGroupId },
//       itemGroups
//     }
//   } = getState();
//   const filteredItemGroup = itemGroups.filter(item => item.id === itemGroupId);
//   const currentItemGroup =
//     filteredItemGroup.length > 0
//       ? filteredItemGroup[0]
//       : {
//           name: '',
//           code: ''
//         };
//   const { name: itemGroupName, code: itemGroupCode } = currentItemGroup;
//   const { name: dataName, code: dataCode } = data;
//   let name = '',
//     itemCode = '';
//   switch (property) {
//     case 'itemGroup':
//       name = `${dataName}${collectionTypeName ? ` ${collectionTypeName}` : ''}${
//         baseMaterialName ? ` ${baseMaterialName}` : ''
//       }${colorName ? ` ${colorName}` : ''}`;
//       itemCode = `${dataCode}${collectionTypeCode ? `-${collectionTypeCode}` : ''}${
//         baseMaterialCode ? `-${baseMaterialCode}` : ''
//       }${colorCode ? `-${colorCode}` : ''}`;
//       break;
//     case 'collectionType':
//       name = `${itemGroupName}${dataName ? ` ${dataName}` : ''}${
//         baseMaterialName ? ` ${baseMaterialName}` : ''
//       }${colorName ? ` ${colorName}` : ''}`;
//       itemCode = `${itemGroupCode}${dataCode ? `-${dataCode}` : ''}${
//         baseMaterialCode ? `-${baseMaterialCode}` : ''
//       }${colorCode ? `-${colorCode}` : ''}`;
//       break;
//     case 'baseMaterial':
//       name = `${itemGroupName}${collectionTypeName ? ` ${collectionTypeName}` : ''}${
//         dataName ? ` ${dataName}` : ''
//       }${colorName ? ` ${colorName}` : ''}`;
//       itemCode = `${itemGroupCode}${collectionTypeCode ? `-${collectionTypeCode}` : ''}${
//         dataCode ? `-${dataCode}` : ''
//       }${colorCode ? `-${colorCode}` : ''}`;
//       break;
//     case 'color':
//       name = `${itemGroupName}${collectionTypeName ? ` ${collectionTypeName}` : ''}${
//         baseMaterialName ? ` ${baseMaterialName}` : ''
//       }${dataName ? ` ${dataName}` : ''}`;
//       itemCode = `${itemGroupCode}${collectionTypeCode ? `-${collectionTypeCode}` : ''}${
//         baseMaterialCode ? `-${baseMaterialCode}` : ''
//       }${dataCode ? `-${dataCode}` : ''}`;
//       break;
//     default:
//       name = payloadName;
//       itemCode = payloadCode;
//       break;
//   }
//   return { name, itemCode };
// };

export const deleteItemGroup = (groupId, callback, errorCallback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  return api
    .deleteItemGroup(iCompanyId, groupId)
    .then(() => {
      dispatch({
        type: 'CLOSE_ITEM_GROUP_DRAWER'
      });
      dispatch(openSnackbar('Item Group deleted Successfully'));
      dispatch(getItemGroup(true));
      dispatch({ type: RESET_ITEM_GROUP_PAYLOAD });
      callback && callback();
      return;
    })
    .catch(error => {
      errorCallback && errorCallback();
      serverError(error);
    });
};

export const createItemGroup = (payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { _selectedItemGroup },
    router: {
      location: { pathname }
    }
  } = getState();
  dispatch({ type: SAVE_ITEM_GROUP_REQUESTED });
  let tempPayload = { ..._selectedItemGroup };
  delete tempPayload.id;
  if (_selectedItemGroup.isBasicGroup) {
    delete tempPayload.name;
    delete tempPayload.itemAttributeConfig;
    delete tempPayload.code;
  }
  mixpanel.track('save-item-group-click', {
    key: 'itemGroup',
    companyName: tempPayload.name,
    page: getPageFromPath(pathname)
  });
  return api
    .createItemGroup(iCompanyId, tempPayload)
    .then(response => {
      dispatch({ type: SAVE_ITEM_GROUP_SUCCESS });
      dispatch({
        type: SHOW_ITEM_GROUP_DIALOG,
        flag: false
      });
      // if (response.roundOff) {
      // isBasicType
      //   ? dispatch(handleRoundOffFlag([]))
      //   :
      dispatch(handleRoundOffFlag(response.roundOff));
      // }
      dispatch({
        type: SET_ITEM_GROUP,
        payload: {
          newItemGroup: response
        }
      });
      dispatch({
        type: SET_ITEM_PAYLOAD,
        details: {
          itemGroupId: response.id
        }
      });
      dispatch({ type: SET_NAME_AND_CODE, nameAndCode: generatedItemNameAndCode(getState) });
      dispatch(setItemConfig(response));

      dispatch({
        type: SET_ITEM_GROUP_SEARCH_TEXT,
        searchText: `${response.name} - ${response.code}(tax ${response.taxPercentage})`
      });
      dispatch(getItemGroup(true));
      !response.isBasicGroup && dispatch(fetchItemAttributes());
      callback && callback(response);
      return;
    })
    .catch(error => {
      dispatch({ type: SAVE_ITEM_GROUP_FAILURE });
      serverError(error);
    });
};

const getTracker = (dispatch, groupId, itemsByGroupPageNo) => {
  dispatch(
    getProgressTracker(response => {
      const groupTracker = getItemGroupTrackerById(groupId, response);
      if (groupTracker && groupTracker.isCompleted) {
        dispatch(fetchItemsMaster(itemsByGroupPageNo));
      } else {
        setTimeout(() => {
          getTracker(dispatch, groupId, itemsByGroupPageNo);
        }, 2000);
      }
    })
  );
};

export const updateItemGroup = () => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { _selectedItemGroup, itemsByGroupPageNo }
  } = getState();
  dispatch({ type: SAVE_ITEM_GROUP_REQUESTED });
  const payload = removeItemGroupUnwantedFields(_selectedItemGroup);
  return api
    .updateItemGroup(iCompanyId, _selectedItemGroup.id, payload)
    .then(response => {
      if (
        response.progressTracker &&
        response.progressTracker.id &&
        !response.progressTracker.isCompleted
      ) {
        dispatch(getProgressTracker());
        getTracker(dispatch, _selectedItemGroup.id, itemsByGroupPageNo);
      }
      // if (response.roundOff) {
      dispatch({ type: SAVE_ITEM_GROUP_SUCCESS });
      dispatch(handleRoundOffFlag(response.roundOff));
      // }
      dispatch({
        type: SET_UPDATED_ITEM_GROUP,
        payload: response
      });
      dispatch(getItemGroup(true));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      !response.isBasicGroup && dispatch(fetchItemAttributes());
      return response;
    })
    .catch(error => {
      dispatch({ type: SAVE_ITEM_GROUP_FAILURE });
      serverError(error);
    });
};

function removeItemGroupUnwantedFields(itemGroup) {
  return {
    ...itemGroup,
    itemAttributeConfig: { attributeOrder: [...itemGroup.itemAttributeConfig.attributeOrder] }
  };
}

export const getItemGroup = (includeItemCount, groupId) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemGroupsRequested, itemGroups }
  } = getState();
  dispatch({ type: FETCH_ALL_ITEM_GROUP_REQUESTED, groupId });

  let query = `includeItemCount=${includeItemCount || false}`;

  if (groupId) {
    query += `&itemGroupId=${groupId}`;
  }

  !itemGroupsRequested &&
    api
      .getItemGroup(iCompanyId, query)
      .then(response => {
        let payload = response;

        if (groupId) {
          payload = itemGroups.map(group => {
            const grp = response.find(g => g.id === group.id);
            if (grp) {
              return { ...group, ...grp };
            }
            return group;
          });
        }
        dispatch({ type: FETCH_ALL_ITEM_GROUP_SUCCESS, groupId });
        dispatch({
          type: SET_ALL_ITEM_GROUP,
          payload
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_ALL_ITEM_GROUP_FAILED, groupId });
        serverError(error);
      });
};
/**
 * return a payload to create item, with all values which are required for item creation.
 * @param {*} item
 */
const generateItemPayload = (item, decimalPlaces, linkVendorDetailsFlag) => {
  const { payload, _selectedItemGroup, party, tags } = item;
  const isVendorAddedFlag =
    linkVendorDetailsFlag || payload.id ? (payload.partyId || party.id ? true : false) : false; //if we are in edit always take party Id
  return {
    // id: payload.id,
    isBasicType: !isVendorAddedFlag,
    name: payload.name,
    itemCode: payload.itemCode,
    itemGroupId: _selectedItemGroup.id,
    // itemCollectionId: collectionType.id,
    // itemBaseMaterialId: baseMaterial.id,
    // itemColorId: color.id,
    unit: payload.unit,
    partyId: isVendorAddedFlag ? party.id || payload.partyId : '',
    tags,
    unitPurchasePrice: parseFloat(payload.unitPurchasePrice, 10),
    unitSellWholeSalePrice: parseFloat(payload.unitSellWholeSalePrice, 10),
    unitSellRetailPrice: parseFloat(payload.unitSellRetailPrice, 10),
    exclusiveOfTax: payload.exclusiveOfTax,
    wholesaleMarkupBase: payload.includeMarkUp ? payload.wholesaleMarkupBase : '',
    wholesaleMarkup: payload.includeMarkUp ? parseFloat(payload.wholesaleMarkup, 10) : '',
    wholesaleDiscountValue: payload.wholesaleDiscountValue,
    wholesaleDiscountUnit: payload.wholesaleDiscountUnit,
    wholesaleDiscountAmount: payload.wholesaleDiscountAmount,

    retailMarkupBase: payload.includeMarkUp ? payload.retailMarkupBase : '',
    retailMarkup: payload.includeMarkUp ? parseFloat(payload.retailMarkup, 10) : '',
    retailDiscountValue: payload.retailDiscountValue,
    retailDiscountUnit: payload.retailDiscountUnit,
    retailDiscountAmount: payload.retailDiscountAmount,
    description: payload.description,
    partyDesignCode: linkVendorDetailsFlag ? payload.partyDesignCode : '',
    openingStock: payload.openingStock,
    images: getdocumentSchema(payload.images),
    rateCode: payload.rateCode,
    wspAdjustment: getfloatingValue(payload.wspAdjustment, decimalPlaces) || 0,
    rspAdjustment: getfloatingValue(payload.rspAdjustment, decimalPlaces) || 0,
    isMarkupSameAsItemGroup:
      _selectedItemGroup.wholesaleMarkup === payload.wholesaleMarkup ||
      _selectedItemGroup.retailMarkup === payload.retailMarkup
        ? true
        : false,
    itemAttributes: payload.itemAttributes,
    attributeConfig: payload.attributeConfig,
    includeMarkUp: payload.includeMarkUp,
    eShopVisibility: payload.eShopVisibility,
    minOrderQty: parseInt(payload.minOrderQty, 10),
    maxOrderQty: parseInt(payload.maxOrderQty, 10),
    lowStockAlertQty: payload.lowStockAlertQty,
    unitOfOrderQty: payload.unitOfOrderQty,
    groupedUnit: payload.groupedUnit,
    qtyPerGroupedUnit: parseFloat(payload.qtyPerGroupedUnit, 10)
  };
};

export const getAndSetAllImageUrls = (items, callback) => dispatch => {
  const images = [];
  items.forEach(item => {
    images.push({
      ...item,
      url: item.url,
      name: item.name,
      fileType: item.type,
      inProgress: false
    });
  });
  dispatch({
    type: SET_IMAGE_LIST,
    item: images
  });
  callback && callback();
};

export const bulkEditItems = (payload, showGrouping = false, callback, errorCallback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemsByGroupPageNo }
  } = getState();
  api
    .bulkUpdateItems(iCompanyId, payload)
    .then(response => {
      dispatch(openSnackbar('Items Updated'));
      getStatus(dispatch, response.id, 'bulkEdit', () => {
        const itemAttributeValueId = getSearchParamsValue('itemAttributeValueId');
        dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
        dispatch(
          showGrouping
            ? fetchGroupedItemsVariants(
                1,
                itemAttributeValueId,
                GROUPED_ITEM_VARIANTS_TABLE,
                ITEM_VARIANT_FILTER_KEY
              )
            : fetchItemsMaster(itemsByGroupPageNo)
        );
      });
      callback && callback(response);
    })
    .catch(err => {
      errorCallback && errorCallback();
      serverError(err);
    });
};

export const createItem = (component, match) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId, currencyDecimals },
    items: { itemGroups },
    items,
    items: { itemsByGroupPageNo, party },
    vouchers: { selectedPartyAccount, _lineItems },
    vendorAccountsDataList,
    settings: { voucherSettings }
  } = getState();
  dispatch({
    type: ITEM_CREATION_REQUESTED
  });
  dispatch({
    type: ACTION_SAVE_BUTTON_DISABLE,
    payload: true
  });
  const payload = generateItemPayload(
    items,
    currencyDecimals,
    component.state.showLinkVendorDetailsFlag
  );
  api.createItem(iCompanyId, payload).then(response => {
    if (response.error) {
      //Due to different backend response this condition is introduced
      dispatch({
        type: ITEM_CREATION_FAILED
      });
      dispatch({
        type: ACTION_SAVE_BUTTON_DISABLE,
        payload: false
      });
      if (response.error.details) {
        let error = [];
        forEach(response.error.details.messages, key => {
          forEach(key, item => {
            error.push(item);
          });
        });
        dispatch({
          type: TOGGLE_ITEM_ALERT_DIALOG,
          error: error
        });
        return response.error;
      } else {
        dispatch({
          type: TOGGLE_ITEM_ALERT_DIALOG,
          error: response.error.message
        });
        return response.error;
      }
    } else {
      dispatch({
        type: ITEM_CREATION_SUCCESS
      });
      dispatch(actionAfterCreateUpdate(component, 'Item Created'));
      if (match) {
        if (match.params.fk) {
          if (match.params.fk === response.itemGroupId)
            dispatch({
              type: SET_ITEM_DATA,
              item: response
            });
          dispatch(getItemGroup(true, response.itemGroupId));
          dispatch(fetchItemsMaster(itemsByGroupPageNo));
          // component.setState({
          //   toggleVariant: false
          // });
        } else if (match.path === '/:id/home/:page/all') {
          dispatch({
            type: RESET_ITEM_PAYLOAD
          });
          dispatch(fetchItemsMaster(itemsByGroupPageNo));
        }
      }
      // TODO: to handle if item is created other then voucher and item in future.
      if (!match) {
        const { taxPercentage, hsn } = getTaxPercentageAndHsn(itemGroups, response.itemGroupId);
        response.taxPercentage = taxPercentage;
        response.hsn = hsn;
        dispatch({
          type: ADD_LINE_ITEM,
          payload: response,
          index: _lineItems.length,
          voucherSettings
        });
        handleItemCalculations(dispatch, getState, EDIT_LINE, 0, _lineItems.length);
        if (!selectedPartyAccount.hasOwnProperty('id')) {
          let currentParty = vendorAccountsDataList.filter(party => {
            if (party.id === response.partyId || party._id === response.partyId) {
              return {
                ...party,
                id: party.id || party._id
              };
            }
          })[0];
          if (!currentParty) {
            //if party data not avaliable due to pagination
            currentParty = party;
          }
          dispatch(
            updateBusiness({
              party: currentParty,
              business: currentParty
            })
          );
          dispatch(fetchAccountsCurrentBalance(currentParty.refAccountId));
        }
        response.includeMarkUp && dispatch(setPersistProperty(response));
        component.setState({
          // toggleVariant: false,
          resetState: true
        });
      }
      dispatch({
        type: ACTION_SAVE_BUTTON_DISABLE,
        payload: false
      });
      return response;
    }
  });
};

export const UpdateLineItem = item => (dispatch, getState) => {
  dispatch({
    type: UPDATE_LINE_ITEM,
    payload: item
  });
  handleItemCalculations(dispatch, getState, EDIT_LINE);
  item.includeMarkUp && dispatch(setPersistProperty(item));
};

export const reCalculateLineAmount = () => (dispatch, getState) => {
  dispatch(recalculateVoucher(RECALCULATE));
};

export const updateItem = (component, match, voucherPageFlag = false) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId, currencyDecimals },
    items,
    items: { itemsByGroupPageNo },
    vouchers: {
      _lineItems,
      app: {
        lineItem: { currentItemIndex }
      }
    }
  } = getState();
  const currentView = getSearchParamsValue('listViewType');
  dispatch({
    type: ITEM_UPDATE_REQUESTED
  });
  dispatch({
    type: ACTION_SAVE_BUTTON_DISABLE,
    payload: true
  });
  let payload = generateItemPayload(
    items,
    currencyDecimals,
    component.state.showLinkVendorDetailsFlag
  );
  payload.id = items.payload.id;
  return api
    .updateItem(iCompanyId, items.payload.id, payload)
    .then(response => {
      dispatch({
        type: SET_UPDATED_ITEM,
        item: response
      });
      if (!match) {
        const { taxPercentage, hsn } = getTaxPercentageAndHsn(
          items.itemGroups,
          response.itemGroupId
        );
        response.taxPercentage = taxPercentage;
        response.hsn = hsn;
        response.qty = _lineItems[currentItemIndex].qty;
        dispatch(UpdateLineItem(response));
      }
      dispatch({
        type: ACTION_SAVE_BUTTON_DISABLE,
        payload: false
      });
      if (!voucherPageFlag) {
        if (currentView === 'grouped') {
        } else {
          dispatch(fetchItemsMaster(itemsByGroupPageNo));
          dispatch(getHiddenItems(1, response.itemGroupId));
        }
      }
      dispatch({
        type: ITEM_UPDATE_SUCCESS
      });
      dispatch(actionAfterCreateUpdate(component, 'Item Updated'));
      return response;
    })
    .catch(error => {
      dispatch({
        type: ITEM_UPDATE_FAILED
      });
      serverError(error);
      dispatch({
        type: ACTION_SAVE_BUTTON_DISABLE,
        payload: false
      });
    });
};

export const actionAfterCreateUpdate = (component, message) => dispatch => {
  dispatch({
    type: TOGGLE_ADD_ITEMS
  });
  dispatch({
    type: RESET_ITEM_PAYLOAD
  });
  dispatch({
    type: CANCEL_ITEM_SALES_PRICE
  });
  dispatch({
    type: SNACKBAR_OPEN,
    payload: { message }
  });
  // dispatch(getItemGroup()); // No need to get fresh Data
  dispatch(resetVariantItemPayload());
  component.setState({
    resetState: true
  });
};
/**
 * on error set the return error in redux state.
 * @param {*} field
 * @param {*} value
 */
export const setItemErrorAction = (field, value) => {
  return {
    type: SET_ITEM_ERROR,
    details: {
      [field]: value
    }
  };
};
// const fetchColors = () => (dispatch, getState) => {
//   const { currentCompany } = getState();

//   api
//     .fetchColors(currentCompany.id)
//     .then(response => {
//       dispatch({ type: FETCH_COLORS_SUCCESS, payload: response });
//     })
//     .catch(error => dispatch({ type: FETCH_COLORS_FAILED, payload: error }));
// };
/**
 * sets item group selected and generates name and code for an item is to be created.
 */
export const setItemGroup = payload => (dispatch, getState) => {
  dispatch(handleRoundOffFlag(payload._selectedItemGroup.roundOff));
  // }
  dispatch({
    type: SET_ITEM_GROUP,
    payload: {
      _selectedItemGroup: payload._selectedItemGroup
    }
  });
  dispatch({ type: SET_NAME_AND_CODE, nameAndCode: generatedItemNameAndCode(getState) });
  dispatch(setItemConfig(payload._selectedItemGroup));
};

/**
 * toggles the create item group dialog.
 */
export const showItemGroupDialog = (flag = false, name) => (dispatch, getState) => {
  const {
    currentCompany: { name: companyName },
    router: {
      location: { pathname }
    },
    items: {
      app: { onShowItemGroup }
    }
  } = getState();
  // dispatch(updateSelectedItemGroup('name', name));
  mixpanel.track(`save-edit-drawer-toggle-itemGroupNew`, {
    key: 'itemGroupNew',
    flag: !onShowItemGroup,
    companyName: companyName,
    page: getPageFromPath(pathname)
  });
  dispatch({
    type: SHOW_ITEM_GROUP_DIALOG,
    name,
    flag
  });
};

/**
 * sets and toggle edit item group for edit.
 */
export const showEditItemGroupDialog = () => (dispatch, getState) => {
  const {
    currentCompany: { name },
    router: {
      location: { pathname }
    },
    items: {
      app: { onShowItemGroup }
    }
  } = getState();
  mixpanel.track('save-edit-drawer-toggle-itemGroupEdit', {
    key: 'itemGroupEdit',
    flag: !onShowItemGroup,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch({ type: SHOW_EDIT_ITEM_GROUP_DIALOG });
};

export const toggleAddItems = () => (dispatch, getState) => {
  const {
    currentCompany: { name },
    router: {
      location: { pathname }
    },
    items: {
      app: { onShowAddItems }
    }
  } = getState();
  mixpanel.track('save-edit-drawer-toggle-item', {
    key: 'item',
    flag: !onShowAddItems,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch({ type: TOGGLE_ADD_ITEMS });
};

/**
 * sets party from voucher for an item is to be created.
 */
export const addVoucherPartyToItemForm = () => (dispatch, getState) => {
  const {
    vouchers: { selectedPartyAccount }
  } = getState();
  dispatch({
    type: ADD_VOUCHER_PARTY_TO_ITEM,
    party: selectedPartyAccount
  });
};

export const itemEditMode = item => dispatch => {
  dispatch({ type: EDIT_ITEM_MODE, item: item });
  map(item.itemAttributesDetails, attribute => {
    if (!attribute) return;
    const tempAttributeValue = {
      ...attribute.value,
      id: attribute.value.itemAttributeValueId
    };
    dispatch(setKeyedAttributeValue(attribute.itemAttributeId, tempAttributeValue));
  });
};

export const itemVariantMode = item => dispatch => {
  map(item.itemAttributesDetails, attribute => {
    if (!attribute) return;
    const tempAttributeValue = {
      ...attribute.value,
      id: attribute.value.itemAttributeValueId
    };
    dispatch(setKeyedAttributeValue(attribute.itemAttributeId, tempAttributeValue));
  });
  dispatch({
    type: EDIT_ITEM_MODE,
    variant: true,
    item: item
  });
  item.partyId &&
    dispatch(
      getPartyById(item.partyId, res => {
        dispatch(setItemProperty('party', res.data));
      })
    );
};

export const updateSelectedItemGroup = (field, value) => {
  return {
    type: UPDATE_SELECTED_ITEM_GROUP,
    details: {
      [field]: value
    }
  };
};

export const updateSelectedItemGroupAttributeConfig = configIds => {
  return {
    type: UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG,
    configIds
  };
};

export const updateRateCodeValueForSelectedItemGroup = (field, value) => {
  return {
    type: UPDATE_RATE_CODE_VALUE,
    details: {
      [field]: value
    }
  };
};

export const updateRoundOffValueForSelectedItemGroup = payload => {
  return {
    type: UPDATE_ROUNDOFF_RANGE_VALUE,
    payload
  };
};

export const addRangeToItemGroup = () => {
  return {
    type: ADD_RANGE_TO_ITEM_GROUP
  };
};

export const deleteRangeFromItemGroup = () => {
  return {
    type: DELETE_RANGE_FROM_ITEM_GROUP
  };
};

export const resetSelectedItemGroup = () => {
  return {
    type: RESET_SELECTED_ITEM_GROUP
  };
};

export const togglePersistMode = value => {
  return {
    type: TOGGLE_PERSIST_MODE,
    payload: value
  };
};

export const setToggleVariantFlag = value => {
  return {
    type: TOGGLE_ITEM_VARIANT,
    payload: value
  };
};

export const setPersistProperty = item => (dispatch, getState) => {
  const {
    currentCompany: { currencyDecimals }
  } = getState();
  dispatch({
    type: SET_PERSIST_PROPERTY,
    details: {
      wholesaleMarkupBase: item.wholesaleMarkupBase,
      wholesaleMarkup: getfloatingValue(item.wholesaleMarkup, currencyDecimals),
      retailMarkupBase: item.retailMarkupBase,
      retailMarkup: getfloatingValue(item.retailMarkup, currencyDecimals)
    }
  });
};

export const setItemPayload = (field, value) => dispatch => {
  // if (field === IS_BASIC_TYPE) {
  //
  //   // localStorage.setItem(IS_BASIC_TYPE, JSON.stringify(value));
  //   if (value) {
  //     dispatch(handleRoundOffFlag([]));
  //     // dispatch({type: RESET_PERSIST_PROPERTY })
  //   }
  // }
  dispatch({
    type: SET_ITEM_PAYLOAD,
    details: {
      [field]: value
    }
  });
};

export const setItemProperty = (property, data) => dispatch => {
  dispatch({
    type: SET_ITEM_PROPERTY,
    details: {
      propertyData: {
        [property]: data
      }
    }
  });
};

export const setItemTags = (key = ITEM_TAGS_KEY, tags) => dispatch => {
  dispatch({ type: SET_ITEM_TAGS, key, tags });
};

export const setItemPartyId = partyId => dispatch => {
  dispatch({ type: SET_ITEM_PARTY_ID, partyId });
};

export const setItemGroupSearchText = input => {
  return {
    type: SET_ITEM_GROUP_SEARCH_TEXT,
    searchText: input
  };
};

export const showItemSalesPrice = () => {
  return {
    type: SHOW_ITEM_SALES_PRICE
  };
};

export const cancelItemSalesPrice = () => {
  return {
    type: CANCEL_ITEM_SALES_PRICE
  };
};

export const resetItemPayload = () => {
  return {
    type: RESET_ITEM_PAYLOAD
  };
};

export const resetVariantItemPayload = match => {
  return {
    type: RESET_VARIANT_ITEM,
    match
  };
};

export const setShowSalesPriceValue = () => {
  return {
    type: SHOW_SALES_PRICE_VALUE
  };
};

export const toggleItemAlertDialog = () => {
  return {
    type: TOGGLE_ITEM_ALERT_DIALOG
  };
};

export const fetchItemGroupById = itemGroupId => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: SET_TAB_VIEW_ITEM_GROUP, payload: { id: itemGroupId } }); //set avaliable data and fetch remaining
  return api
    .fetchItemGroupById(iCompanyId, itemGroupId)
    .then(response => {
      dispatch({
        type: SET_TAB_VIEW_ITEM_GROUP,
        payload: response
      });
      return response;
    })
    .catch(error => {
      serverError(error);
    });
};

export const getItemGroupById = itemGroupId => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_GROUP_REQUESTED, itemGroupId }); //set avaliable data and fetch remaining
  return api
    .fetchItemGroupById(iCompanyId, itemGroupId)
    .then(response => {
      dispatch({
        type: FETCH_ITEM_GROUP_SUCCESS,
        itemGroup: response,
        itemGroupId
      });
      return response;
    })
    .catch(error => {
      dispatch({ type: FETCH_ITEM_GROUP_FAILED, itemGroupId });
      serverError(error);
    });
};

/**
 * this function expand the items by sku code group for which the user has clicked.
 * @param {index to be expanded} index
 */
export const expandItems = index => {
  return {
    type: EXPAND_ITEMS,
    index: index
  };
};

export const fetchTaxesMaster = () => dispatch => {
  api
    .getTaxesMaster()
    .then(response => {
      dispatch({ type: FETCH_TAXES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_TAXES_FAILURE, payload: error }));
};

export const fetchItemUnits = () => dispatch => {
  api
    .fetchItemUnits()
    .then(response => {
      dispatch({ type: SET_ITEM_UNITS, payload: response });
    })
    .catch(error => serverError(error));
};

export const fetchGroupedUnits = () => dispatch => {
  api
    .fetchGroupedItemUnits()
    .then(response => {
      dispatch({ type: SET_GROUPED_ITEM_UNITS, payload: response });
    })
    .catch(error => serverError(error));
};
export const fetchVendorDesignCodes = (vendorId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({ type: FETCH_PARTY_DESIGN_CODES_REQUESTED });
  fetchPartyDesignCodes(id, vendorId)
    .then(response => {
      dispatch({ type: FETCH_PARTY_DESIGN_CODES_SUCCESS, payload: response });
      callback && callback();
    })
    .catch(error => dispatch({ type: FETCH_PARTY_DESIGN_CODES_FAILED, payload: error }));
};

export const deleteItemsFromMaster = (groupId, items) => (dispatch, getState) => {
  const {
    items: { itemsByGroupPageNo }
  } = getState();
  dispatch(
    handleDeleteItems(groupId, items, () => {
      dispatch(getItemGroup(true, groupId));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
    })
  );
};

export const deleteItemsFromGrouped = (groupId, items) => dispatch => {
  // const {
  //   items: { itemsByGroupPageNo },
  // } = getState();
  dispatch(
    handleDeleteItems(groupId, items, () => {
      const itemAttributeValueId = getSearchParamsValue('itemAttributeValueId');
      itemAttributeValueId &&
        dispatch(
          fetchGroupedItemsVariants(
            1,
            itemAttributeValueId,
            GROUPED_ITEM_VARIANTS_TABLE,
            ITEM_VARIANT_FILTER_KEY
          )
        );
      dispatch(fetchGroupedItems(1));
    })
  );
};

export const handleDeleteItems = (groupId, items, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id },
    table: { selectedIds }
  } = getState();
  console.log(items, callback);
  const tableId = getItemGroupTableId(groupId);
  const ids = selectedIds[tableId];
  const itemsToDelete = items
    ? getValuesByKey(items)
    : extractIdsFromSelection(selectedIds, tableId).toString();
  api
    .deleteItems(id, itemsToDelete)
    .then(res => {
      const { status, message } = parseDeleteResp(res.data, ids);
      if (!status) {
        alert(message);
      } else {
        dispatch(openSnackbar('Item deleted Successfully'));
        dispatch({ type: RESET_SELECTED_ROW_IDS });
      }
      if (res.data.totalFailedItems < itemsToDelete.length) {
        //trigger if atleast 1 item was succefully deleted
        console.log(callback);
        callback && callback();
      }
    })
    .catch(error => {
      serverError(error);
    });
};

function parseDeleteResp(resp, items) {
  let status = true;
  let message;

  if (resp.failedItems && resp.failedItems.length > 0) {
    status = false;
    message = `${resp.totalFailedItems} items(s) could not be deleted \n`;
    message += reduce(
      resp.failedItems,
      (list, item) => {
        const itemsToDelete = reduce(
          items,
          (list, currentPage) => {
            const currItemDetail = find(currentPage, curItem => curItem.id === item.itemId);
            return currItemDetail ? currItemDetail.skuBarcode : list;
          },
          ''
        );
        list.push(
          `\nItem(${itemsToDelete}) is already available in voucher(s) ${item.vouchers.toString()}`
        );
        return list;
      },
      []
    );
  }
  return { status, message };
}

export const createItemAttribute = (payload, callBack) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: {
      _selectedItemGroup: {
        itemAttributeConfig: { attributeOrder }
      }
    }
  } = getState();
  dispatch({ type: ADD_ITEM_ATTRIBUTE_REQUESTED });
  api
    .addItemAttribute(iCompanyId, payload)
    .then(response => {
      dispatch({
        type: ADD_ITEM_ATTRIBUTE_SUCCESS,
        payload: response
      });
      const newConfig = [...attributeOrder, response.id];
      dispatch(updateSelectedItemGroupAttributeConfig(newConfig));
      callBack && callBack(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: ADD_ITEM_ATTRIBUTE_FAILED });
    });
};

export const deleteItemAttribute = (attributeId, callBack) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: DELETE_ITEM_ATTRIBUTE_REQUESTED });
  api
    .deleteItemAttribute(iCompanyId, attributeId)
    .then(response => {
      dispatch({
        type: DELETE_ITEM_ATTRIBUTE_SUCCESS,
        payload: response
      });
      dispatch(fetchItemAttributes());
      callBack && callBack(response);
    })
    .catch(error => {
      dispatch({ type: DELETE_ITEM_ATTRIBUTE_FAILED });
      serverError(error);
    });
};

export const editItemAttribute = (payload, callBack) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: UPDATE_ITEM_ATTRIBUTE_REQUESTED });
  api
    .updatedItemAttribute(iCompanyId, payload)
    .then(response => {
      dispatch({
        type: UPDATE_ITEM_ATTRIBUTE_SUCCESS,
        payload: response
      });
      dispatch(fetchItemAttributes());
      callBack && callBack(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: UPDATE_ITEM_ATTRIBUTE_FAILED });
    });
};

export const fetchItemAttributes = () => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_ATTRIBUTES_REQUESTED });
  api
    .getItemAttributes(iCompanyId)
    .then(response => {
      dispatch({
        type: FETCH_ITEM_ATTRIBUTES_SUCCESS,
        payload: response
      });
    })
    .catch(() => {
      dispatch({ type: FETCH_ITEM_ATTRIBUTES_FAILED });
    });
};

// export const updateItemConfig = payload => (dispatch, getState) => {
//   const {
//     currentCompany: { id: iCompanyId }
//   } = getState();
//   dispatch({ type: UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED });
//   api
//     .updateItemConfig(iCompanyId, payload)
//     .then(response => {
//       dispatch({
//         type: UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS,
//         payload: response
//       });
//     })
//     .catch(error => {
//       dispatch({ type: UPDATE_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED });
//     });
// };

export const fetchItemAttributeConfig = (fetchMode, key) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_REQUESTED });
  let query = 'fetchMode=default';
  if (fetchMode) {
    query = `fetchMode=${fetchMode}`;
  }

  api
    .getItemConfig(iCompanyId, query)
    .then(response => {
      key === ITEM_GROUP
        ? dispatch({
            type: FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS,
            payload: response
          })
        : dispatch({ type: SET_ITEM_ATTRIBUTE_CONFIG, config: response.attributeOrder });
    })
    .catch(() => {
      dispatch({ type: FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_FAILED });
    });
};

export const fetchItemAttributeValues = (itemAttributeId, page = 1, searchText, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED, key: itemAttributeId, page });
  const query = searchText ? `searchText=${searchText}` : '';
  api
    .getItemAttributeValues(iCompanyId, itemAttributeId, page, query)
    .then(response => {
      dispatch({
        type: FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS,
        payload: response,
        key: itemAttributeId
      });
      searchText &&
        searchText !== '' &&
        dispatch(setKeyedAttributeValue(itemAttributeId, response));
      callback && callback(searchText);
    })
    .catch(() => {
      dispatch({ type: FETCH_ITEM_ATTRIBUTE_VALUE_FAILED, key: itemAttributeId });
    });
};

export const fetchAllItemAttributeValues = (itemAttributeId, searchText, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  const query = searchText ? `searchText=${searchText}` : '';

  api
    .getAllItemAttributeValues(iCompanyId, itemAttributeId, null, query)
    .then(response => {
      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};

export const addItemAttributeValue = (itemAttributeId, payload, index, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED });

  api
    .createItemAttributeValue(iCompanyId, itemAttributeId, payload)
    .then(response => {
      dispatch(setKeyedAttributeValue(itemAttributeId, response));
      dispatch({
        type: ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS,
        payload: response,
        index
        // itemCode: generateCode(response)
      });
      dispatch({ type: SET_NAME_AND_CODE, nameAndCode: generatedItemNameAndCode(getState) });

      callback && callback(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: ADD_ITEM_ATTRIBUTE_VALUE_FAILED });
      callback && callback();
    });
};

export const setKeyedAttributeValue = (itemAttributeId, attribute) => dispatch => {
  dispatch({ type: SET_ITEM_ATTRIBUTE_VALUE_KEYED, attribute, key: itemAttributeId });
};

export const setAttributeValue = (index, data) => (dispatch, getState) => {
  dispatch({
    type: SET_ATTRIBUTE_VALUE,
    index,
    payload: data
  });
  dispatch({ type: SET_NAME_AND_CODE, nameAndCode: generatedItemNameAndCode(getState) });
};

export const setItemConfig = itemGroup => dispatch => {
  itemGroup.itemAttributeConfig && itemGroup.itemAttributeConfig.id
    ? dispatch({
        type: SET_ITEM_ATTRIBUTE_CONFIG,
        config: itemGroup.itemAttributeConfig.attributeOrder
      })
    : dispatch(fetchItemAttributeConfig(itemGroup.id, ITEM));
};
export const fetchItemGoupByHsn = (hsn = '') => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  if (!hsn) {
    //api giving error if hsn is empty
    return [];
  }
  dispatch({ type: FETCH_ITEM_GROUPS_BY_HSN_REQUESTED });
  let query = `hsn=${hsn}`;

  return api
    .getItemGoupByHsn(id, query)
    .then(response => {
      dispatch({ type: FETCH_ITEM_GROUPS_BY_HSN_SUCCESS, payload: response });
      return response;
    })
    .catch(error => {
      dispatch({ type: FETCH_ITEM_GROUPS_BY_HSN_FAILED });
      serverError(error);
    });
};

export const onEditAttributeValue = (attributeId, attributeValue) => dispatch => {
  dispatch({ type: ON_EDIT_ATTRIBUTE_VALUE, attributeId, attributeValue });
};

export const toggleAttributeValueForm = flag => dispatch => {
  dispatch({ type: TOGGLE_ATTRIBUTE_VALUE_FORM, flag });
};

export const updateItemAttributeValue = (itemAttributeId, payload, index, callBack) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    items: { itemsByGroupPageNo }
  } = getState();
  dispatch({ type: UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED });
  api
    .updateItemAttributeValue(id, itemAttributeId, payload)
    .then(response => {
      dispatch({ type: UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS, response, key: itemAttributeId });
      dispatch(setKeyedAttributeValue(itemAttributeId, response));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callBack && callBack(response);
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED });
    });
};

export const unHideItems = (groupId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    table: { selectedIds },
    items: { itemsByGroupPageNo }
  } = getState();
  const itemsToUnHide = extractIdsFromSelection(selectedIds, 'hidden-type-items');
  api
    .handleUnhideItem(iCompanyId, itemsToUnHide)
    .then(() => {
      dispatch(openSnackbar('Item unHidden Successfully'));
      dispatch(getHiddenItems(1, groupId));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      dispatch(getItemGroup(true, groupId));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

export const fetchItemTags = () => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_TAGS_REQUESTED });
  api
    .getItemTags(iCompanyId)
    .then(response => {
      dispatch({
        type: FETCH_ITEM_TAGS_SUCCESS,
        payload: response
      });
    })
    .catch(() => {
      dispatch({ type: FETCH_ITEM_TAGS_FAILED });
    });
};

export const createItemTag = (payload, storeKey = ITEM_TAGS_KEY) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: ADD_ITEM_TAG_REQUESTED });
  api
    .postItemTag(iCompanyId, payload)
    .then(response => {
      dispatch({ type: ADD_ITEM_TAG_SUCCESS, payload: response });
      dispatch({ type: ADD_ITEM_TAG_IN_SELECTED, payload: response, storeKey });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: ADD_ITEM_TAG_FAILED });
    });
};

export const updateItemTag = (payload, storeKey = ITEM_TAGS_KEY, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemsByGroupPageNo }
  } = getState();
  dispatch({ type: UPDATE_ITEM_TAG_REQUESTED });
  api
    .updateItemTag(iCompanyId, payload.id, payload)
    .then(response => {
      dispatch({ type: UPDATE_ITEM_TAG_SUCCESS, payload: response });
      dispatch({ type: UPDATE_ITEM_TAG_IN_SELECTED, payload: response, storeKey });
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callback && callback();
    })
    .catch(error => {
      dispatch({ type: UPDATE_ITEM_TAG_FAILED });
      serverError(error);
    });
};

export const hideItem = (groupId, items, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    table: { selectedIdsList },
    items: { itemsByGroupPageNo }
  } = getState();
  const itemsToHide = items
    ? getValuesByKey(items)
    : getValuesByKey(selectedIdsList[getItemGroupTableId(groupId)]);
  api
    .handleHideItem(iCompanyId, itemsToHide)
    .then(() => {
      dispatch(openSnackbar('Item Hidden Successfully'));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      dispatch({ type: RESET_SELECTED_ROW_IDS });
      dispatch(getItemGroup(true, groupId));
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: UPDATE_ITEM_TAG_FAILED });
    });
};

export const toggleItemsIShopVisibility = (groupId, flag, items, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    table: { selectedIdsList },
    items: { itemsByGroupPageNo }
  } = getState();
  const itemIds = items
    ? getValuesByKey(items)
    : getValuesByKey(selectedIdsList[getItemGroupTableId(groupId)]);
  api
    .handleItemIShopVisibilityToggle(iCompanyId, flag, { itemIds })
    .then(() => {
      dispatch(openSnackbar('Items added to iShop Successfully'));
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callback && callback();
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteItemTag = (tagId, storeKey = ITEM_TAGS_KEY, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemsByGroupPageNo }
  } = getState();
  dispatch({ type: DELETE_ITEM_TAG_REQUESTED });
  api
    .deleteItemTag(iCompanyId, tagId)
    .then(() => {
      dispatch({ type: DELETE_ITEM_TAG_SUCCESS, tagId });
      dispatch({ type: DELETE_ITEM_TAG_IN_SELECTED, tagId, storeKey });
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
    });
};

export const getHiddenItems = (page, groupId) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: {
      [HIDDEN_ITEM_FILERS_KEY]: { showQty },
      itemSearch
    }
  } = getState();

  let query = 'isHidden=true&isBasicType=all';

  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  if (itemSearch?.hiddenItemSearchText) {
    query += `&searchText=${itemSearch.hiddenItemSearchText}`;
    query += itemSearch.barcodeSearchOn ? `&searchBy=numericSkuBarcode` : '';
  }

  if (groupId) {
    query += `&itemGroupId=${groupId}`;
  }

  dispatch({ type: HIDDEN_ITEM_LIST_REQUESTED });
  api
    .fetchItemsMaster(iCompanyId, page, query)
    .then(response => {
      dispatch({
        type: HIDDEN_ITEM_LIST_SUCCESS,
        payload: response
      });
    })
    .catch(error => {
      dispatch({ type: HIDDEN_ITEM_LIST_FAILED });
      serverError(error);
      console.log(error);
      dispatch({ type: DELETE_ITEM_TAG_FAILED });
    });
};

export const editTagsToItems = (itemIds, removeList, addList, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: {
      itemsByGroupPageNo,
      payload: { id }
    }
  } = getState();
  if (removeList.length > 0 && addList.length > 0) {
    dispatch({ type: ADD_TAGS_TO_ITEMS_REQUESTED });
    api
      .addTagsToItems(iCompanyId, itemIds, addList)
      .then(response => {
        dispatch({ type: ADD_TAGS_TO_ITEMS_SUCCESS, payload: response });
        dispatch(removeTagsFromItems(id, removeList, callback));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: ADD_TAGS_TO_ITEMS_FAILED });
      });
  } else {
    if (removeList.length > 0) {
      dispatch(removeTagsFromItems(id, removeList, callback));
    }

    if (addList.length > 0) {
      dispatch({ type: ADD_TAGS_TO_ITEMS_REQUESTED });
      api
        .addTagsToItems(iCompanyId, itemIds, addList)
        .then(response => {
          dispatch({ type: ADD_TAGS_TO_ITEMS_SUCCESS, payload: response });
          dispatch(fetchItemsMaster(itemsByGroupPageNo));
          dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
          callback && callback(response);
        })
        .catch(error => {
          console.log(error);
          dispatch({ type: ADD_TAGS_TO_ITEMS_FAILED });
        });
    }
  }
};

export const addTagsToItems = (itemIds, storeKey = ITEM_TAGS_KEY, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemsByGroupPageNo, [storeKey]: tags }
  } = getState();
  console.log(tags);
  dispatch({ type: ADD_TAGS_TO_ITEMS_REQUESTED });
  api
    .addTagsToItems(iCompanyId, itemIds, tags)
    .then(response => {
      dispatch({ type: ADD_TAGS_TO_ITEMS_SUCCESS, payload: response });
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: ADD_TAGS_TO_ITEMS_FAILED });
    });
};

/**
 *
 * @param {string id for single item/array of item obj for multiple item} itemId
 * @param {Array} tags
 * @param {Function} callback
 */

export const removeTagsFromItems = (itemId, tags, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    items: { itemsByGroupPageNo }
  } = getState();
  dispatch({ type: REMOVE_TAGS_FROM_ITEMS_REQUESTED });
  const tagIds = getValuesByKey(tags).toString();
  const query = `?itemId=${
    Array.isArray(itemId) ? getValuesByKey(itemId).toString() : itemId
  }&tagId=${tagIds}`;
  api
    .removeTagsFromItems(iCompanyId, query)
    .then(() => {
      dispatch({ type: REMOVE_TAGS_FROM_ITEMS_SUCCESS, payload: tags });
      dispatch(fetchItemsMaster(itemsByGroupPageNo));
      callback && callback();
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: REMOVE_TAGS_FROM_ITEMS_FAILED });
    });
};

// export const getAvailableQty = (itemId, key, callback) => (dispatch, getState) => {
//   const {
//     currentCompany: { id: iCompanyId }
//   } = getState();
//   dispatch({ type: GET_AVAILABLE_QTY_REQUESTED, itemId, key });
//   api
//     .fetchAvailableQty(iCompanyId, itemId)
//     .then(response => {
//       dispatch({ type: SET_AVAILABLE_QTY, payload: response, itemId, key });
//       callback && callback(response);
//     })
//     .catch(error => {
//       dispatch({ type: GET_AVAILABLE_QTY_FAILED, itemId, key });
//       serverError(error);
//       console.log(error);
//     });
// };

export const getAvailableQty = (itemId, key, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: GET_AVAILABLE_QTY_REQUESTED, itemId, key });
  api
    .getAvailableQty(iCompanyId, itemId)
    .then(response => {
      dispatch({ type: SET_AVAILABLE_QTY, payload: response, itemId, key });
      callback && callback(response);
    })
    .catch(error => {
      dispatch({ type: GET_AVAILABLE_QTY_FAILED, itemId, key });
      serverError(error);
      console.log(error);
    });
};

export const toggleQtyFilter = (flag, key = FILTERS_KEY) => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_QTY_FILTER,
    payload: flag,
    key
  });
  dispatch(resetItemAvailableQtyList());
};

export const fetchItemCatalogPublicShareStatus = (page = 1, searchText) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST });
  let query = '';
  if (searchText) {
    query = `searchBy=name&searchString=${searchText}`;
  }
  api
    .getItemCataloguePublicShareStatus(iCompanyId, page, query)
    .then(response => {
      const indexOffset = response.hitsPerPage * (response.page - 1);
      const data = map(response.data, (catalogue, index) => {
        return {
          ...catalogue,
          displayIndex: response.totalDataCount - (indexOffset + index)
        };
      });
      const lastUsedSettings =
        response.data[0] && response.data[0].settings ? response.data[0].settings : {};
      dispatch({
        type: FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS,
        data,
        response
      });
      dispatch({ type: FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS, lastUsedSettings });
    })
    .catch(error => dispatch({ type: FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE, payload: error }));
};

export const setSelectedCatalogue = catalogue => dispatch => {
  dispatch({ type: SET_SELECTED_CATALOGUE, catalogue });
};

export const getCatalogueItems = (page = 1, searchText, callback) => (dispatch, getState) => {
  dispatch({ type: FETCH_CATALOGUE_ITEMS_REQUEST });
  const {
    currentCompany: { id: iCompanyId },
    itemCatalogues: {
      selectedCatalogue: { sharedUrlKey }
    },
    items: {
      [CATALOGUE_FILTERS_KEY]: { selectedItemTags, selectedItemGroups, showQty },
      itemSearch: { barcodeSearchOn }
    }
  } = getState();
  let query = '';
  if (searchText) {
    query += `searchText=${searchText}`;
  }
  if (selectedItemGroups && selectedItemGroups.length > 0) {
    query += `&itemGroupId=${getValuesByKey(selectedItemGroups).toString()}`;
  }
  if (selectedItemTags && selectedItemTags.length > 0) {
    query += `&tags=${getValuesByKey(selectedItemTags).toString()}`;
  }

  if (barcodeSearchOn && searchText) {
    query += `&searchBy=numericSkuBarcode`;
  }

  if (showQty) {
    query += `&includeCurrentQtyToList=true`;
  }

  api
    .fetchCatalogueItems(iCompanyId, sharedUrlKey, page, query)
    .then(response => {
      dispatch({ type: FETCH_CATALOGUE_ITEMS_SUCCESS, response, sharedUrlKey });
      callback && callback(response);
    })
    .catch(err => {
      dispatch({ type: FETCH_CATALOGUE_ITEMS_FAILURE, payload: err });
      callback && callback();
    });
};

export const toggleAddItemsToCatalogueMode = flag => dispatch => {
  dispatch({ type: TOGGLE_EDIT_CATALOGUE_ITEMS_MODE, flag });
  dispatch({ type: SELECT_RESET, key: ADD_CATALOGUE_ITEM_TABLE });
  dispatch({ type: SELECT_RESET, key: CATALOGUE_ITEM_TABLE });
};

export const setItemPriceFilterBy = (priceFilterBy, storeKey) => dispatch => {
  dispatch({ type: SET_ITEM_PRICE_FILTER_BY, priceFilterBy, storeKey });
};

export const setItemPriceFilterValue = (key, priceFilterValue, storeKey) => dispatch => {
  dispatch({ type: SET_ITEM_PRICE_FILTER_VALUE, key, priceFilterValue, storeKey });
};

export const resetItemAvailableQtyList = () => dispatch => {
  dispatch({ type: RESET_ITEM_AVAILABLE_QTY_LIST });
};
// This api call is used to upload file on MR stock app server
export const uploadMRStockAppFile = response => dispatch => {
  if (response.isCompleted) {
    let iCompanyId = response.iCompanyId;
    let fileUri = response.result && response.result.info ? response.result.info.Location : null; // aws s3 file url
    if (fileUri) {
      return api
        .uploadMRStockAppFile(iCompanyId, fileUri)
        .then(res => {
          dispatch(openSnackbar('File Uploaded Successfully'));
        })
        .catch(error => {
          serverError(error);
        });
    }
  }
};

export const resetItemGroupPageNum = () => dispatch => {
  dispatch({ type: RESET_ITEM_GROUP_PAGE_NO });
};

export const setItemFilterSetting = itemSettings => dispatch => {
  dispatch({ type: SET_ITEM_FILTER_TOGGLE_LIST, itemSettings });
};

export const setBulkItemSetting = (key, settingKey, value) => dispatch => {
  dispatch({ type: SET_ITEM_BULK_TOGGLE, key, settingKey, value });
  dispatch(updateUserCompanyBulkItemSettings());
};

export const setBulkItem = (index, payload) => (dispatch, getState) => {
  const {
    items: { bulkItemsList = [], itemGroups },
    vouchers: { _lineItems = [] }
  } = getState();

  let data = [];

  let name = [];
  let code = [];

  if (_lineItems.length + bulkItemsList.length >= 750) {
    dispatch(openSnackbar('You can add upto 750 item in voucher', ERROR));
    return;
  }

  data = [...bulkItemsList];

  let selectedRow = itemGroups.find(group => group.id === payload.itemGroupId);

  if (selectedRow) {
    const rateCodeValue = getRateCodeValue(
      payload.unitSellWholeSalePrice,
      payload.unitSellRetailPrice,
      selectedRow.rateCode
    );

    name.push(selectedRow.name);
    code.push(selectedRow.code);

    payload.itemAttributes = payload.attributeConfig.map(attr => {
      let it = payload.itemAttributesDetails.find(itemAttr => itemAttr?.itemAttributeId === attr);
      if (it) {
        name.push(it.value.name);
        code.push(it.value.code);
      }
      return it?.value.itemAttributeValueId;
    });

    payload.rateCode = rateCodeValue;

    payload.name = name.join(ITEM_NAME_SEPERATOR);
    payload.itemCode = code.join(ITEM_CODE_SEPERATOR);
  }

  let newItem = dispatch(generateBulkItemPayload({}, []));
  delete newItem.index;

  let blankItemRow = data.find((item, i) => {
    let tempItem = cloneDeep(item);
    delete tempItem.index;
    return isEqual(tempItem, newItem);
  });

  let blankRowIndex = blankItemRow?.index || 0;
  delete blankItemRow?.index;

  if (isEqual(newItem, blankItemRow)) {
    payload.index = blankRowIndex;
    data[blankRowIndex] = payload;
  } else if (!isNaN(index) && index !== null) {
    data[index] = payload;
  } else {
    data.push(payload);
  }

  dispatch({ type: SET_BULK_ITEMS, data });
};

export const generateBulkItemPayload = (item = {}, itemAttributesDetails = []) => (
  dispatch,
  getState
) => {
  const {
    items: { bulkItemsList, bulkItemSetting },
    currentCompany: { currencyDecimals }
  } = getState();

  let payload = {
    index: bulkItemsList.length,
    attributeConfig: item?.attributeConfig || [],
    itemGroupId: item?.itemGroupId,
    itemAttributes: [],
    unit: item?.unit || 'pcs',
    unitPurchasePrice: parseFloat(0).toFixed(2),
    unitSellWholeSalePrice: parseFloat(0).toFixed(2),
    unitSellRetailPrice: parseFloat(0).toFixed(2),
    exclusiveOfTax: true,
    includeMarkUp: true,
    wspAdjustment: 0,
    rspAdjustment: 0,
    wholesaleMarkupBase: 'unitPurchasePrice',
    wholesaleMarkup: parseFloat(0).toFixed(2),
    wholesaleDiscountValue: 0,
    wholesaleDiscountUnit: PERCENT,
    wholesaleDiscountAmount: 0,
    retailMarkupBase: 'unitSellWholeSalePrice',
    retailMarkup: parseFloat(0).toFixed(2),
    retailDiscountValue: 0,
    retailDiscountUnit: PERCENT,
    retailDiscountAmount: 0,
    storageLocation: '',
    warehouseLocation: '',
    description: '',
    openingStock: [],
    openingStockTotal: 0,
    partyDesignCode: '',
    partyId: '',
    isBasicType: true,
    images: [],
    rateCode: '',
    eShopVisibility: false,
    minOrderQty: '',
    maxOrderQty: '',
    lowStockAlertQty: '',
    unitOfOrderQty: '',
    groupedUnit: '',
    qtyPerGroupedUnit: '',
    itemAttributesDetails: itemAttributesDetails || [],
    boxQty: 1,
    purchaseQty: 1
  };

  if (Object.keys(item).length > 0 && itemAttributesDetails.length > 0) {
    if (
      bulkItemSetting[ATTRIBUTE_1].autoRepeat &&
      itemAttributesDetails[0] &&
      itemAttributesDetails[0].itemAttributeId === item.attributeConfig[0] &&
      item.itemAttributes?.length > 0
    ) {
      payload.itemAttributesDetails.push(itemAttributesDetails[0]);
      payload.itemAttributes = item.itemAttributes[0];
    }

    if (
      bulkItemSetting[ATTRIBUTE_2].autoRepeat &&
      itemAttributesDetails[1] &&
      itemAttributesDetails[1].itemAttributeId === item.attributeConfig[1]
    ) {
      payload.itemAttributesDetails.push(itemAttributesDetails[1]);
      payload.itemAttributes = item.itemAttributes[1];
    }

    if (
      bulkItemSetting[ATTRIBUTE_3].autoRepeat &&
      itemAttributesDetails[2] &&
      itemAttributesDetails[2].itemAttributeId === item.attributeConfig[2]
    ) {
      payload.itemAttributesDetails.push(itemAttributesDetails[2]);
      payload.itemAttributes = item.itemAttributes[2];
    }

    if (bulkItemSetting[VENDOR_DESIGN_CODE].autoRepeat) {
      payload.partyDesignCode = item.partyDesignCode;
    }

    if (bulkItemSetting[GROUPED_UNIT].autoRepeat) {
      payload.groupedUnit = item.groupedUnit;
      payload.qtyPerGroupedUnit = item.qtyPerGroupedUnit;
      payload.purchaseQty = item.qtyPerGroupedUnit ? item.qtyPerGroupedUnit : 1;
    }

    if (bulkItemSetting[PURCHASE_PRICE_LABEL].autoRepeat) {
      payload.unitPurchasePrice = item.unitPurchasePrice;
    }

    if (bulkItemSetting[RETAIL_PRICE_LABEL].autoRepeat) {
      payload.unitSellRetailPrice = item.unitSellRetailPrice;
      payload.retailMarkup = item.retailMarkup;
      payload.retailMarkupBase = item.retailMarkupBase;
      payload.rspAdjustment = item.rspAdjustment;
    }

    if (bulkItemSetting[WHOLESALE_PRICE_LABEL].autoRepeat) {
      payload.unitSellWholeSalePrice = item.unitSellWholeSalePrice;
      payload.wholesaleMarkup = VoucherCalModule.itemUnitWspRsp.calculateReverseMarkup(
        item.unitPurchasePrice,
        item.unitSellWholeSalePrice,
        currencyDecimals
      );
      payload.wholesaleMarkupBase = item.wholesaleMarkupBase;
      payload.wspAdjustment = item.wspAdjustment;
    }

    if (bulkItemSetting[DESCRIPTION].autoRepeat) {
      payload.description = item.description;
    }

    if (bulkItemSetting[SHOW_ISHOP].autoRepeat) {
      payload.eShopVisibility = item.eShopVisibility;
    }

    if (bulkItemSetting[LOW_STOCK_ALERT_QTY]) {
      payload.lowStockAlertQty = item.lowStockAlertQty;
    }
  }

  if (item.wspAdjustment) {
    payload.wspRoundOff = true;
  }

  if (item.rspAdjustment) {
    payload.rspRoundOff = true;
  }

  return payload;
};

export const removeItemFromBulk = index => dispatch => {
  dispatch({ type: REMOVE_ITEM, index });
};
export const resetBulkItem = (data = []) => dispatch => {
  dispatch({ type: RESET_BULK_ITEM, data });
};

export const updateItemFilterToggleList = (key, flag, label) => (dispatch, getState) => {
  const {
    items: { itemFilterList }
  } = getState();
  let filterSetting = {
    ...itemFilterList,
    [key]: {
      key: key,
      checked: flag,
      label: label
    }
  };
  let itemSettings = Object.keys(filterSetting).map(key => {
    return {
      key: key,
      label: filterSetting[key].label,
      checked: filterSetting[key].checked
    };
  });

  let payload = {
    itemSettings
  };
  dispatch(
    updateUserItemFilterSettings(payload, () => {
      dispatch(getUserCompanySettings());
    })
  );
};
