import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { PersistGate } from 'redux-persist/integration/react';
import Clarity from '@microsoft/clarity';

import './index.css';

import App from './App';
import { store, persistor } from './store';
import { unregister } from './registerServiceWorker';
import { socketConnect } from './socket';

const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

unregister();
socketConnect();
Clarity.init(projectId);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider>
        <App />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
